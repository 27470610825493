// https://www.gdoctohtml.com/

const Cookies = () => <>
  <p>Cookie Policy for Aura</p>
  <p>Effective Date: June 10, 2023</p>
  <p>This Cookie Policy explains how Aura ("we," "us," or "our") uses cookies and similar technologies on Aura("Website") and the choices you have regarding these technologies.</p>
  <p>1. What are cookies?</p>
  <p>Cookies are small text files that are stored on your device (computer, smartphone, tablet) when you visit a website. They help us recognize your device and provide a personalized experience by remembering your preferences and settings.</p>
  <p>2. Types of cookies used:</p>
  <p>a. Essential Cookies: These cookies are necessary for the proper functioning of the Website. They enable basic features such as secure log-in, cart functionality, and session management.</p>
  <p>b. Analytical Cookies: These cookies allow us to collect information about how visitors use our Website. We use this data to analyze and improve the performance and functionality of our Website. These cookies do not identify you personally.</p>
  <p>c. Marketing Cookies: Marketing cookies are used to track visitors across websites. They enable us to show you relevant advertisements and measure the effectiveness of our marketing campaigns.</p>
  <p>d. Third-Party Cookies: We may allow third-party service providers, such as analytics or advertising partners, to place cookies on our Website. These cookies are subject to the respective third parties' privacy policies and may be used to collect information about your online activities.</p>
  <p>3. Your Consent:</p>
  <p>By continuing to use our Website, you consent to the placement of cookies on your device as described in this Cookie Policy. You can manage and control cookies through your browser settings. Please note that disabling certain cookies may impact the functionality and usability of the Website.</p>
  <p>4. How to manage cookies:</p>
  <p>Most web browsers allow you to manage your cookie preferences. You can typically find these settings in the "Options," "Preferences," or "Settings" menu of your browser. Each browser may have slightly different settings, so we recommend consulting the Help section of your browser for instructions.</p>
  <p>5. Your Privacy Rights:</p>
  <p>For information on how we collect, use, and protect your personal data, please refer to our Privacy Policy.</p>
  <p>6. Updates to this Cookie Policy:</p>
  <p>We may update this Cookie Policy from time to time to reflect changes in technology, applicable laws, or our business operations. We encourage you to review this policy periodically to stay informed about how we use cookies.</p>
  <p>If you have any questions or concerns about this Cookie Policy, please contact us at <a href="mailto:cookies@auradrops.com" target="_blank" rel="noopener">cookies@auradrops.co</a>m.</p>
</>

export default Cookies;