import { Card } from "antd";
import SkeletonComponent from "components/Skeleton/index";

const LoaderGroup = ({count}) => {
  const numCards = count || 9;
  const cards = Array.from({length: numCards})

  return <>
    {cards.map((el, idx) => <LoaderCard key={idx} />)}
  </>
}

const LoaderCard = () => <div className="col-12 col-md-6 col-xl-4 mb-3">
  <div className="graphic_card">
    <Card
      style={{
        width: "100%",
        cursor: "pointer",
      }}
      cover={
          <SkeletonComponent
            height={200}
            width="100%"
            borderRadius="12px 12px 0 0"
            style={{ lineHeight: "2" }}
          />
        }
    >
      <div className="card_content">
        <div className="text-left">
          <div className="font10_2">Date released</div>
          <div className="font10"></div>
        </div>
        <div className="d-flex">
            <SkeletonComponent height={29} width={56} />
            <SkeletonComponent
              height={29}
              width={86}
              style={{ marginRight: "10px" }}
            />
        </div>
      </div>
      <div
        className="space_between"
        style={{
          padding: "6px 0",
          margin: "0 12px",
        }}
      >
        <label className="font12">Price </label>
        <div>
          <div className="d-flex align-items-center" style={{ gap: 10 }}>
              <SkeletonComponent
                width={60}
                style={{ marginRight: "10px" }}
              />
              <SkeletonComponent width={30} height={30} circle />
          </div>
        </div>
      </div>
    </Card>
  </div>
</div>

export default LoaderGroup
