export const THEME = 'THEME';

function theme(value) {
    return {
        type: THEME,
        json: value
    }
}

export function toggleTheme(value) {
    return dispatch => {
        dispatch(theme(value))
    }
}

export function setHeaderStatus(value) {
    return dispatch => {
        dispatch({
            type: 'HEADER_SHOWING',
            value: value
        })
    }
}