import aura_animtaion from 'components/assets/aura.gif';
import './index.css';

const Empty = () => {

    return (
        <div className='empty_container'>
            <img className="aura_animation" src={aura_animtaion} />
            <label className="mt-2">Your stats will be appearing shortly - hang tight!</label>
        </div>
    )
}

export default Empty;