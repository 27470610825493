import Modal from 'react-bootstrap/Modal';
import React, { useState, useEffect } from 'react';
import './checkout.css';
import success2 from 'components/assets/success2.png';
import CloseButton from 'components/common/CloseButton';

function Success(props) {
    
    return (
        <Modal
        {...props}
            // show={showCheckout}
            size="sm"
            centered
            // onHide={handleClose}
            >
            <Modal.Body>
                <CloseButton className='close_login' onClick={() =>   props.onHide(false)} />
                <div>
                    <img className='d-flex' style={{margin:'auto'}} src={success2} alt='' />
                    <div className='font20 p-4 text-center'><label>Payment Received</label></div>
                    <div className='success_footer text-center font16' onClick={() =>  props.onHide(false)} style={{ cursor: "pointer" }}>OK</div>
                </div>
            </Modal.Body>
        </Modal>
    );
}


export default Success;