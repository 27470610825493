import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import ProductCard from "components/Product/GraphicCard";
import ProductsLoader from "components/Product/Loader";
import FloatingNav from "components/common/FloatingNav";
import { getFavorites } from "actions/firebase";
import FavoriteEmpty from "./Empty";
import "./favorite.css";
import Channels from "components/Author/Channels";

const raffle = "images/raffle.png";
const tech = "images/tech.png";
const tech2 = "images/tech2.png";

function Favorites() {
  const [productsList, setProductsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const theme = useSelector(state => state.theme);

  function updateProductList(){
    const now = new Date();
    const seconds = Math.floor(now.getTime() / 1000);
    const nanoseconds = (now.getTime() % 1000) * 1e6;
    getFavorites({
      isSkip: false, startAfterDate: { 
        _seconds: seconds,
        _nanoseconds: nanoseconds 
      }
    }).then(res => {
      setProductsList(res.products)
      setIsLoading(false);
      console.log('Get Favorites: ', res)
    }).catch(error => {
      console.log('Get Favorite Error: ', error);
    })
  }

  useEffect(() => {
    updateProductList();
    setIsLoading(true)
    
  }, []);

  const { pathname } = useLocation();
  const isProfileSubPage = pathname?.includes('profile');

  console.log({ productsList })

  return (
    <>
      {!isProfileSubPage && <Channels />}
      {(!isLoading && productsList && !productsList.length) ?
        <FavoriteEmpty className={`${!isProfileSubPage ? 'favorites_own' : ''}`} /> :
        <div className={`container-fluid favorites ${!isProfileSubPage ? 'favorites_own' : ''}`}>
          {!isProfileSubPage && <div className="d-flex pb-2">
            <div className="font12_mono" style={{ color: 'var(--font_color)', marginTop: '16px' }}>{'FAVS'}</div>
          </div>}
          <div className="row" style={{ paddingBottom: '50px' }} >
            {isLoading ? <ProductsLoader /> : productsList.map((item) => (
              <div className="col-12 col-md-6 col-xl-4 col-xxl-3 mb-3 p-1" key={item.id}>
                <ProductCard
                  data={item}
                  id={item.idProduct}
                  cardimg={item.coverImage?.src}
                  dropType={item.dropType}
                  category_name={item.category}
                  category_icon={theme ? tech : tech2}
                  creator_name={item?.username}
                  creator_icon={item?.userimage}
                  date={item.createdDts}
                  status_name={item.category || "Raffle"}
                  status_icon={raffle}
                  border_color="#3784AB"
                  background="#40C397"
                  isFavorited={true}
                  updateProductList = {updateProductList}
                />
              </div>
            ))}
          </div>
        </div>
      }
      <FloatingNav />
    </>
  );
}

export default Favorites;
