import "components/Legal/css/legal.css";
import { useParams, Link, useNavigate } from "react-router-dom";
import Privacy from "components/Legal/Privacy";
import Terms from "components/Legal/Terms";
import Cookies from "components/Legal/Cookies";

const Legal = () => {
  const { section } = useParams();
  const navigate = useNavigate();
  if (!(section == 'privacy' || section == 'terms' || section == 'cookies'))
    return navigate('/404/page404');

  return <div className="legal-content">
    <div class="legal-nav">
      <NavigationLink name="privacy" />
      <NavigationLink name="terms" />
      <NavigationLink name="cookies" />
    </div>

    {section === 'privacy' && <Privacy />}
    {section === 'terms' && <Terms />}
    {section === 'cookies' && <Cookies />}
  </div>
}

const NavigationLink = ({ name }) => {
  const { section } = useParams();
  const isSelected = section === name;
  return <div className="legal-nav-link">
    {isSelected ? <span>{name}</span> : <Link to={`/legal/${name}`}>{name}</Link>}
  </div>

}

export default Legal;