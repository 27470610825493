import { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";

import ProductCard from "components/Product/GraphicCard";
import Map from "../map/Map";
import Marker from "../map/Marker";
import { getUserOrderConfirmation, useUserId } from "actions/firebase";
import SkeletonComponent from "components/Skeleton/index";
import OrderCard from "./OrderCard";
import DeliverStatusBar from "./DeliverStatusBar";
import ProductModal from "components/Product/ProductModal";
import spotify from "components/assets/spotify.png";
import youImage from "components/assets/map_you.svg";
import OrderEmpty from "./OrderEmpty";
import "./orders.css";
import { getUserOrders } from "actions/firebase";

const apiKey = "AIzaSyAT9SH59Z75iR8OUEcfv-mTklD6xMDHs80";

const mapStateToProps = (state) => ({
  productsListLoading: state.products.productsListLoading,
  theme: state.theme,
});

function PersonalInfo() {
  const theme = useSelector((state) => state.theme);
  const [orderList, setOrderList] = useState(null);
  const [posts, setPosts] = useState({
    id: "",
    cardimg: "",
    order_number: "",
    tracking_number: "",
    buyer_name: "",
    status: "",
    order_date: "",
    position: {
      lat: 35,
      lng: -100,
    },
    zoom: 4,
  });
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [location, setLocation] = useState(null);

  const loaded = !!selectedOrder;
  const userId = useUserId();

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    if (!selectedOrder) return;
    // setPosts({
    //   id: selectedOrder.buyerShippingAddress?.object_id,
    //   productId: selectedOrder.productsInfo[0].id,
    //   cardimg: selectedOrder.productsInfo[0].imageUrl,
    //   order_number: selectedOrder.orderNumber,
    //   tracking_number: selectedOrder.trackingNumber,
    //   buyer_name: selectedOrder.buyerShippingAddress?.name,
    //   status: selectedOrder.status == 'succeeded',
    //   order_date: selectedOrder.buyerShippingAddress?.object_created || new Date(),
    //   seller_email: selectedOrder.buyerShippingAddress?.object_owner,
    //   position: {
    //     lat: 35,
    //     lng: -100,
    //   },
    //   zoom: 4
    // })
    console.log("selectedOrder", selectedOrder);
    setPosts({
      id: selectedOrder.idOrder,
      cardimg: selectedOrder.cart[0].items[0]?.selectedVariantData?.imageUrl | '',
      order_number: selectedOrder.idOrder,
      tracking_number: selectedOrder.shipping?.tracking_number,
      buyer_name: selectedOrder.shipping.name,
      status: selectedOrder.status,
      order_date: selectedOrder.created,
      position: {
        lat: 35,
        lng: -100,
      },
      zoom: 4,
    })
  }, [selectedOrder]);



  useEffect(() => {
    getUserOrders()
      .then((res) => {
        console.log("received user orders", res.orders);
        setOrderList(res.orders);
        res.orders?.length && setSelectedOrder(res.orders[0]);
      })
      .catch((error) => {
        console.error(error);
      });

    // getUserOrderConfirmation({ userId: userId, isTestMode: true })
    //   .then(res => {
    //     const filteredResults = res.results.filter(result => result.buyerShippingAddress && result.buyerShippingAddress.object_id);
    //     console.log('filtered Results: ', filteredResults)
    //     setOrderList(filteredResults);
    //     res.results?.length && setSelectedOrder(res.results[0]);
    //   })
    //   .catch(console);
  }, []);



  const EmailLink = ({ name, sellerEmail, orderNumber, purchaseDate }) => {
    const email = sellerEmail;
    const dateObj = new Date(purchaseDate);
    const formattedDate = `${String(dateObj.getUTCMonth() + 1).padStart(
      2,
      "0"
    )}/${String(dateObj.getUTCDate()).padStart(
      2,
      "0"
    )}/${dateObj.getUTCFullYear()}`;
    const subject = `${name}, Order Number: ${orderNumber} Purchase Date: ${formattedDate}`;
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`;

    return (
      <a href={mailtoLink} style={{ textDecoration: "underline" }}>
        Contact Us
      </a>
    );
  };

  const OrderItem = ({ item }) => {
    const handlClickOrderCard = () => {
      console.log("order card clicked",item);
      setPosts({
        id: selectedOrder.idOrder,
        cardimg: selectedOrder.cart[0].items[0]?.selectedVariantData?.imageUrl | '',
        order_number: selectedOrder.idOrder,
        tracking_number: selectedOrder.shipping?.tracking_number,
        buyer_name: selectedOrder.shipping.name,
        status: selectedOrder.status,
        order_date: selectedOrder.created,
        position: {
          lat: 35,
          lng: -100,
        },
        zoom: 4,
      })
    }
    return (
      <div
        key={item.id}
        style={{
          marginBottom: 24,
          border: item.id == selectedOrder.id ? "1px solid #EB4949" : "none",
          borderRadius: 12,
          minWidth: "300px",
        }}
        onClick = {handlClickOrderCard}
      >
        <OrderCard
          data={item}
          id={item.id}
          date={item.created}
          dropType={item.status}
          cardimg={item.cart[0].items[0]?.selectedVariantData?.imageUrl | ''}
          setSelectedOrder={setSelectedOrder}
          setClickReview={(val) => {
            setShowModal(true);
          }}
         
        />
      </div>
    );
  };

  const DetailFragment = () => {
    return (
      <>
        <div className="space_between">
          {loaded ? (
            <label className="font20" style={{ textAlign: "left", flex: 1.5 }}>
              Thank you, {posts.buyer_name}!
            </label>
          ) : (
            <SkeletonComponent width={240} height={20} />
          )}
          {loaded ? (
            <div>
              <label className="font12" style={{ flex: 1 }}>
                ORDER #{posts.order_number}
              </label>
            </div>
          ) : (
            <SkeletonComponent width={140} height={16} />
          )}
        </div>
        <DeliverStatusBar status={posts.status} />
        <div>
          {loaded ? (
            <Map id="map" apiKey={apiKey} center={location} zoom={posts.zoom}>
              {/* <Marker
                position={location}
                userImageURL={'https://firebasestorage.googleapis.com/v0/b/shop-aura.appspot.com/o/Profile%2Frc-upload-1691648061842-3.jpeg?alt=media&token=15192d27-bd04-4309-a627-a8f7792c4620'}
                markerColor={'#ff0000'}
              /> */}
              <Marker
                position={location}
                userImageURL={youImage}
                isYou
                onSelect={() => {
                  console.log("Clicked marker!");
                }}
              />
            </Map>
          ) : (
            <SkeletonComponent width={"100%"} height={346} borderRadius={20} />
          )}
        </div>
        <div className="order_view">
          {/* <div style={{ display: "grid" }} className="font12">
            <label>{'Order Status'}</label>
            {loaded ? (
              <label className="font16">
                {posts.status}
              </label>
            ) : (
              <SkeletonComponent width={240} height={16} />
            )}
          </div> */}
          <div style={{ display: "grid" }} className="font12">
            <label>USPS tracking number:</label>
            {loaded ? (
              <label className="font16">{posts.tracking_number}</label>
            ) : (
              <SkeletonComponent width={240} height={16} />
            )}
          </div>
        </div>
        <div className="font12 contact-us" style={{ justifyContent: "right" }}>
          <label>{"Questions?"}</label>&nbsp;
          <EmailLink
            sellerEmail={"atyourservice@auradrops.com"}
            // sellerEmail={posts.seller_email}
            name={posts.buyer_name}
            orderNumber={posts.order_number}
            purchaseDate={posts.order_date}
          />
        </div>
      </>
    );
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  if (true) return <OrderEmpty />; // for test

  return (
    <div className="order">
      <div className="desktop_version">
        <div className="orders">
          <div className="orders1">
            <div className="height90" style={{}}>
              {!orderList ? (
                <SkeletonComponent
                  width={"100%"}
                  height={"100%"}
                  borderRadius={16}
                />
              ) : (
                orderList.map((item) => <OrderItem item={item} />)
              )}
            </div>
          </div>

          <div className="orders2">
            <DetailFragment />
          </div>
        </div>
      </div>

      <div className="mobile_version">
        <div className="mt-5">
          {!orderList ? (
            <SkeletonComponent width={"95%"} height={300} borderRadius={16} />
          ) : (
            <Swiper
              watchSlidesProgress={true}
              className="mySwiper"
              style={{ gap: "10px", display: "flex" }}
            >
              {orderList.map((item) => (
                <SwiperSlide>
                  <div style={{ marginRight: "10px" }}>
                    <OrderItem item={item} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
        <div className="mobile_order2">
          <DetailFragment />
        </div>
      </div>

      <div className="tablet_version">
        <div className="orders1">
          <Swiper
            watchSlidesProgress={true}
            slidesPerView={2.1}
            className="mySwiper"
          >
            {!orderList ? (
              <SkeletonComponent
                width={"100%"}
                height={"100%"}
                borderRadius={16}
              />
            ) : (
              orderList.map((item) => (
                <SwiperSlide>
                  <div style={{ marginRight: "10px" }}>
                    <OrderItem item={item} />
                  </div>
                </SwiperSlide>
              ))
            )}
          </Swiper>
        </div>
        <div className="orders2">
          <DetailFragment />
        </div>
      </div>
      <img
        className="mobile_spotify"
        src={spotify}
        alt="spotify"
        style={{ bottom: "1%" }}
      />

      {posts && (
        <ProductModal
          id={posts.productId}
          handleCloseCard={handleCloseModal}
          drawerHeight={"100%"}
          drawerClassName={""}
          dropType={"Presale"}
          category_name={"Fasion"}
          showCard={showModal}
          handleAddToBag={() => {}}
          videoError={false}
          setShowVideo={(val) => {}}
          showLoginedNotification={false}
          setShowLoginedNotification={(not) => {}}
          showNotification={false}
          setShowNotification={(not) => {}}
          setImageIdx={(idx) => {}}
        />
      )}
    </div>
  );
}

export default connect(mapStateToProps)(PersonalInfo);
