import React from "react";
import { ThreeDots } from "react-loader-spinner";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const ReactLoader = ({ color = "#CCB783", height = 80, width = 180 }) => {
  return (
    <div
      className="dots-loader"
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ThreeDots
        wrapperClass="loader"
        height={height}
        width={width}
        radius="10"
        color={color}
        ariaLabel="three-dots-loading"
        visible={true}
      />
    </div>
  );
};

export default ReactLoader;

ReactLoader.defaultProps = {
  color: "#CCB783",
  height: "150",
  width: "250",
};
