import {
  ADD_ITEM_TO_BAG,
  REMOVE_ITEM_FROM_BAG,
  CHANGE_ITEM_COUNT,
  SYNCHRONIZE_WITH_LOCAL_STORE,
} from "actions/types";
import _ from "lodash";

const initialState = {
  dropList: [],
};

function bag(state = initialState, action) {
  switch (action.type) {
    case ADD_ITEM_TO_BAG:
      const isOrderExist = state.dropList.find(
        (e) => e.id === action.payload.id
      );
      let res = state.dropList;
      if (!_.isEmpty(state.dropList)) {
        const filteredOrders =
          !_.isEmpty(state.dropList) &&
          state.dropList?.map((e) => {
            if (e.id === action.payload.id) {
              return { ...e, count: e.count + 1 };
            }
            return e;
          });
        if (!isOrderExist) {
          res = [...state.dropList, action.payload];
        } else {
          res = filteredOrders;
        }
      } else {
        res = [action.payload];
      }
      return {
        ...state,
        dropList: res,
      };
    case REMOVE_ITEM_FROM_BAG:
      const filteredList = state.dropList.filter(
        (e) => e.id !== action.payload
      );
      return {
        ...state,
        dropList: filteredList,
      };
    case CHANGE_ITEM_COUNT:
      const { itemId, count } = action.payload;
      const updatedList = state.dropList.map((el) => {
        if (itemId === el.id) {
          return { ...el, count };
        }
        return el;
      });
      return {
        ...state,
        dropList: updatedList,
      };
    case SYNCHRONIZE_WITH_LOCAL_STORE:
      return {
        ...state,
        dropList: action.payload,
      };
    default:
      return state;
  }
}

export default bag;
