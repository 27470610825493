import { useState, useEffect } from "react";
import { Modal } from "antd";
import "antd/dist/antd.css";

import Login from "components/Checkout/Login";
import CloseButton from "components/common/CloseButton";

import "./css/main.css";

const PhoneModal = ({ isModalOpen, setIsModalOpen, onSuccess }) => {
  const modalButtonId = "login-modal-button";
  const [modalTitle, setModalTitle] = useState("Join or sign in"); // for trigger title between two status (phone number input && code input)
  const [isEnterCode, setIsEnterCode] = useState(false);
  const [logined, setLogin] = useState(false);
  const [timer, setTimer] = useState("block");
  const [loginStep, setLoginStep] = useState(0);

  // const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem("logined", JSON.stringify(logined));
  }, [logined]);

  const handleOk = () => {
    alert("click");
  };
  const handleContinue = () => {
    // shows no tiled modal while ensuring phone number.
    // just want to title remains while ensuring phone number
    // the title removes when code input appears.

    setLoginStep(loginStep + 1);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsEnterCode(false);
  };

  const loginSuccess = () => {
    setLogin(true);
    handleCancel();
    if (onSuccess) {
      onSuccess();
    }
  };
  const phonenumberSuccess = () => {
    console.log("phone number is succeeded!!!!!");
    setModalTitle("Enter the code");
  };

  return (
    <Modal
      className="login_modal"
      title={modalTitle}
      //title={isShowModalTitle ? "Join or sign in" : null}
      styles={{ header: "padding: 0px 24px" }}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width={400}
      footer={[
        <button
          key="continue"
          className="checkout_footer font16"
          id={modalButtonId}
          style={{ textAlign: "center", cursor: "pointer" }}
          //onClick={handleOk}
          onClick={handleContinue}
        >
          Continue
        </button>,
      ]}
    >
      <div id="login">
        <CloseButton className="close_login" onClick={handleCancel} />
        <Login
          noWrapper={true}
          step={loginStep}
          onSuccess={loginSuccess}
          onPhonenumberSuccess={phonenumberSuccess}
          buttonId={modalButtonId}
        />
      </div>
    </Modal>
  );
};

export default PhoneModal;
