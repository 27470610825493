// https://www.gdoctohtml.com/

const Privacy = () => <>
  <p>Aura Privacy Policy</p>
  <p>Welcome to Aura by Sweet Potato Pie!</p>
  <p>Privacy is important to us, and we value your trust. That's why we've created this friendly and easy-to-read Privacy Policy. It applies to personal information collected by Aura through our Application, website, online services, and web pages. Whether you access our Services via computer, mobile device, or any other device, this Policy covers your privacy rights and how we handle your personal data.</p>
  <p>Last updated: November 25, 2021</p>
  <p>Your Consent</p>
  <p>By using Aura, you agree to the collection and processing of your personal information as described in this Policy. You also consent to the transfer of your data to the United States and other countries where Aura operates.</p>
  <p>If you ever want to withdraw your consent, you can email us at [privacy@auradrops.com].</p>
  <p>Collection and Use of Personal Information</p>
  <p>When you interact with Aura, we collect information about you, such as your email address, shopping preferences, and more. You may provide this information directly to us through our websites, applications, surveys, social media platforms, and customer contact centers.</p>
  <p>The types of personal information we collect may include:</p>
  <p>Identifiers/Contact information (e.g., name, address, email address, phone number)</p>
  <p>Demographic information (e.g., gender, date of birth, marital status)</p>
  <p>Payment information (e.g., credit card number)</p>
  <p>Information about your devices and how you interact with our products and services (e.g., IP address, browser type)</p>
  <p>Marketing profile information</p>
  <p>Photographs and videos you submit for contests and social sharing</p>
  <p>Relationships you have with Aura (e.g., rewards card)</p>
  <p>Social Security Number (in limited circumstances)</p>
  <p>Inferences drawn from other personal information (e.g., personal preferences, behavior, attitudes)</p>
  <p>We collect this information from you directly, through your use of our Services, and from third parties that interact with us.</p>
  <p>Rest assured, we only ask for necessary information and clearly indicate what is optional. By providing your personal information to Aura, you consent to its collection, use, and disclosure.</p>
  <p>Passive Information Collection and Use</p>
  <p>As you browse our Services, certain information is collected passively, using various technologies. This includes information gathered through your browser and device, cookies, pixel tags, web beacons, clear GIFs, and IP addresses. We use this information for security purposes, navigation, statistical analysis, and improving our Services.</p>
  <p>You can choose to accept or decline cookies, and most web browsers allow you to modify your settings accordingly. However, please note that disabling cookies may affect some features of our Services.</p>
  <p>Integrated Accounts and Social Media Information</p>
  <p>We may use third-party applications or "widgets" (e.g., Facebook, Twitter) that allow integration or content sharing with other services. These applications may collect information about you, and their privacy policies will govern their tracking and collection practices. We encourage you to review these policies.</p>
  <p>How We Use and Disclose Information</p>
  <p>We may disclose your personal information to contractors, service providers, and other third parties who help us support our business. We may also share your information with third parties for marketing purposes if you have given your consent. Additionally, we may use and disclose your information for various business purposes, such as responding to your inquiries, improving our Services, and complying with legal requirements.</p>
  <p>Rest assured that we take appropriate measures to ensure the confidentiality and security of your personal information. We do not sell your personal information to third parties.</p>
  <p>Marketing Communications</p>
  <p>We may send you promotional communications about our products, services, events, and promotions. These communications may be sent via email, phone, SMS text messages, postal mailings, or third-party social networks. You can always opt-out of receiving such communications by following.</p>
</>

export default Privacy;