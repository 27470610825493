import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import CloseButton from "components/common/CloseButton";
import './noti.css';

import dark404 from 'components/assets/bag_404_dark.svg';
import light404 from 'components/assets/bag_404.svg';

function NewProductAlert(props) {
    const theme = useSelector(state => state.theme);
    const img_404 = theme ? dark404 : light404;

    const handleNoti = () => {
        props.onHide(false);
    }

    const handleClose = () => {
        props.onHide(false);
    }

    return (
        <>
            <Modal {...props} size="md" centered>
                <Modal.Body className='noti_part m-2' style={{ backgroundColor: 'var(--background-color)' }}>
                    <CloseButton onClick={handleClose} />
                    <div className='noti_header font18'>
                        <label className='mb-2 mt-3 recoleta-semibold' style={{ color: 'var(--font_color)', width: '100%', textAlign: 'center' }}>{'🚀 NEW DROP ALERT! ✨'}</label>
                        <div className='image_container'>
                            <img src={img_404} className='product_img' />
                        </div>
                        <div className='font14_w4 text-center' style={{ color: 'var(--font_color)', width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <div style={{ maxWidth: '300px', lineHeight: '22px' }}>
                                <span>{"We're adding more limited drops from cool brands. Just tap"}</span><span style={{ color: '#EB4949', }}>{" ❤ "}</span><span>{"on your faves, and we'll ping you when they drop. Don't miss out!"}</span>
                            </div>
                        </div>
                    </div>
                    <div className='noti_footer text-center font16' onClick={handleNoti}>{'Continue'}</div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default NewProductAlert;
