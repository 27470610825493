import ReactGA4 from "react-ga4";

const InitializeGoogleAnalytics = (id) => {
    ReactGA4.initialize(id);
    console.log("GA Initialized with this ID: ", id);
};

const TrackGoogleAnalyticsEvent = async ({ category, action, label }) => {
    try {
        ReactGA4.event({
            category: category,
            action: action,
            label: label,
        });
        console.log("GA event sent:", category, ":", action, ":", label);
    } catch (error) {
        console.log('GA Event Sending Error: ', error);
    }

};

export default InitializeGoogleAnalytics;
export { TrackGoogleAnalyticsEvent };