import React from "react";
import CloseButton from "components/common/CloseButton";
import card_master from "components/assets/card_master.png"
import card_amex from "components/assets/card_amex.png"

const CVCModal = ({ isShow, setShow }) => {
    return (
        <div>
            <div className={`cvc_modal ${isShow ? "cvc_modal_show" : "cvc_modal_hide"}`}>
                <CloseButton onClick={() => { setShow(false) }} />
                <label className="font16 pl-3 pt-2">Find your CVC</label>
                <div className="pt-2" />
                <div className="row">
                    <div className="col-6 col-sm-6 col-md-6 col-xl-6">
                        <label className="font14 col-12 pl-3 pt-2">Mastercard/Visa/Discover</label>
                        <label className="font12 col-12 pl-3 pt-2">3-digit Security Code</label>
                        <img src={card_master} className="ml-2 pt-2 mb-2" style={{ width: '90%' }} />
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 col-xl-6">
                        <label className="font14 col-12 pl-3 pt-2">AMEX</label>
                        <label className="font12 col-12 pl-3 pt-2">4-digit Security Code</label>
                        <img src={card_amex} className="ml-2 pt-2 mb-2" style={{ width: '90%' }} />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default CVCModal;