import React from "react";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import "react-loading-skeleton/dist/skeleton.css";

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
  };
};

function SkeletonComponent(props) {
  const { theme } = { ...props };

  return (
    <Skeleton
      {...props}
      width={!props?.width ? 144 : props?.width}
      baseColor={theme ? "#252525" : "#EBDFC6"}
      highlightColor={theme ? "#555555" : "#e2d2ba"}
    />
  );
}

export default connect(mapStateToProps)(SkeletonComponent);
