import {
  GET_CREATORS_LIST_LOADING,
  GET_CREATORS_LIST,
  GET_CREATORS_SPACE_LOADING,
  GET_CREATORS_SPACE_DATA,
  GET_SIMILAR_CREATORS_LOADING,
  GET_SIMILAR_CREATORS,
} from "actions/types";

const initialState = {
  creatorsListLoading: true,
  creatorsSpaceLoading: true,
  similarCreatorsLoading: true,
  creatorsList: [],
  similarCreators: [],
  creatorsSpaceData: undefined,
};

// const formatUserName = (data) => {

// }

function creators(state = initialState, action) {
  switch (action.type) {
    case GET_CREATORS_LIST_LOADING:
      return {
        ...state,
        creatorsListLoading: action.payload,
      };
    case GET_CREATORS_SPACE_LOADING:
      return {
        ...state,
        creatorsSpaceLoading: action.payload,
      };
    case GET_SIMILAR_CREATORS_LOADING:
      return {
        ...state,
        similarCreatorsLoading: action.payload,
      };
    case GET_CREATORS_LIST:
      return {
        ...state,
        creatorsList: action.payload,
      };
    case GET_CREATORS_SPACE_DATA:
      return {
        ...state,
        creatorsSpaceData: action.payload,
      };
    case GET_SIMILAR_CREATORS:
      return {
        ...state,
        similarCreators: action.payload,
      };
    default:
      return state;
  }
}

export default creators;
