import { useEffect, useState, useRef } from 'react';
import { Input, Radio } from 'antd';
import { useFormik } from 'formik';
import { toggleTheme } from "actions/index";
import DayNightToggle from "react-day-and-night-toggle";
import PhoneNumber from '../../Checkout/PhoneNumber';
import Loader from "components/common/loader";
import './personalinfo.css';
import spotify from 'components/assets/spotify.png';
import { useUserId, getUserData, setUserPreferences } from "actions/firebase";
import { useDispatch, useSelector } from 'react-redux';
import { SET_SHOW_MENU } from 'actions/types';

function PersonalInfo() {
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const originalUserData = useRef();
    const userId = useUserId();
    const dispatch = useDispatch();
    const theme = useSelector(state => state.theme);

    const formik = useFormik({
        initialValues: {
            name: '',
            username: '',
            birthday: { month: undefined, day: undefined, year: undefined },
            phoneNumber: { short: "US", code: 1, phone: "" },
            email: '',
            communication: 'sms',
            address: {
                line1: '',
                line2: '',
                city: '',
                state: '',
                postal_code: '',
                country: 'US',
            },
            phoneNumber: '',
            imageUrl: '',
        }
    });

    const onChangePhoneNumber = (value) => {
        formik.setFieldValue("phoneNumber", value);
    }

    const saveChanges = () => {
        setIsSaving(true);
        setUserPreferences(formik.values).then(() => {
            setIsSaving(false);
        });
    }

    const cancelChanges = () => {
        initializeFormData(originalUserData.current)
    }

    const initializeFormData = user => {
        for (let key in user) {
            formik.setFieldValue(key, user[key]);
        }
    }

    useEffect(() => {
        if (!userId) {
            return;
        }
        getUserData().then(data => {
            const { user } = data;
            let value = JSON.parse(JSON.stringify(user));

            if (user.phoneNumber) {
                const phoneParts = (user.phoneNumber || '').match(/^\+(\d{1})(\d{10})$/);
                if (phoneParts) {
                    value.phoneNumber = {
                        short: 'US',
                        code: parseInt(phoneParts[1]),
                        phone: phoneParts[2],
                    };
                }
            }
            originalUserData.current = value;
            initializeFormData(value);
            setIsLoading(false);
        })
    }, [userId])

    function isObject(value) {
        return typeof value === 'object' && value !== null && !Array.isArray(value);
    }

    function deepObjectEqual(obj1, obj2) {
        if (!obj1 || !obj2) return false;
        if (Object.keys(obj1).length !== Object.keys(obj2).length) {
            return false;
        }

        for (const key in obj1) {
            const value1 = obj1[key];
            const value2 = obj2[key];

            if (isObject(value1) && isObject(value2)) {
                if (!deepObjectEqual(value1, value2)) {
                    return false;
                }
            } else if (value1 !== value2) {
                return false;
            }
        }

        return true;
    }

    function isFormikDataUnchanged() {
        return !deepObjectEqual(originalUserData.current, formik.values);
    }

    const [isDataChanged, setIsDataChanged] = useState(false);

    useEffect(() => {
        const changeFlag = isFormikDataUnchanged();
        setIsDataChanged(changeFlag);
        dispatch({ type: SET_SHOW_MENU, payload: !changeFlag });
    }, [formik.values]);

    return isLoading ?
        <div className='loader_container'>
            <Loader />
        </div> : <>
            <div className='personal_info'>
                <div className='personal_info1'>
                    <div className='font20 info_title'>
                        <div>Contact Info</div>
                    </div>
                    <div className='info_each'>
                        <label className='font12_mono' style={{ padding: '10px 0' }}>Full name</label>
                        <div className='drop_under font14_w4'>
                            <Input name='name' value={formik.values.name} onChange={formik.handleChange} />
                        </div>
                    </div>
                    <div className='info_each'>
                        <label className='font12_mono' style={{ padding: '10px 0' }}>Nickname</label>
                        <div className='drop_under font14_w4'>
                            <Input name='username' value={formik.values.username} onChange={formik.handleChange} />
                        </div>
                    </div>

                    <div className='info_each'>
                        <label className='font12_mono' style={{ padding: '10px 0' }}>Birthday</label>
                        <div style={{ display: 'flex', gap: 10 }}>
                            <div className='drop_under font14_w4'>
                                <Input name="birthday.month" value={formik.values.birthday.month} onChange={formik.handleChange} placeholder="Month" style={{ width: '100px' }} />
                            </div>
                            <div className='drop_under font14_w4'>
                                <Input name="birthday.day" value={formik.values.birthday.day} onChange={formik.handleChange} placeholder="Day" style={{ width: '70px' }} />
                            </div>
                            <div className='drop_under font14_w4'>
                                <Input name="birthday.year" value={formik.values.birthday.year} onChange={formik.handleChange} placeholder="Year" style={{ width: '80px' }} />
                            </div>
                        </div>
                    </div>
                    <div className='info_each'>
                        <div className='font14_w4' style={{ padding: '10px 0px' }}>
                            <PhoneNumber className="profile_phone" phoneNumber={formik.values.phoneNumber} onChangeHandler={onChangePhoneNumber} isCheckout />
                        </div>
                    </div>
                    <div className='info_each'>
                        <label className='font12_mono' style={{ padding: '10px 0' }}>Email</label>
                        <div className='drop_under font14_w4'>
                            <Input name='email' value={formik.values.email} onChange={formik.handleChange} />
                        </div>
                    </div>
                    <div className='info_each font14_w4'>
                        <label className='font12_mono' style={{ padding: '10px 0' }}>Preferred Communication</label>
                        <div>
                            <Radio.Group name="communication" value={formik.values.communication} onChange={formik.handleChange}>
                                <Radio value='sms'>Text message</Radio>
                                <Radio value='email'>Email</Radio>
                            </Radio.Group>
                        </div>
                    </div>
                </div>

                <div className='personal_info2'>
                    <div className='font20 info_title'>
                        <div style={{ padding: '10px 0' }}>Address</div>
                    </div>
                    <div className='info_each'>
                        <label className='font12_mono' style={{ padding: '10px 0' }}>Street 1</label>
                        <div className='drop_under font14_w4'>
                            <Input name="address.line1" value={formik.values.address.line1} onChange={formik.handleChange} />
                        </div>
                    </div>
                    <div className='info_each'>
                        <label className='font12_mono' style={{ padding: '10px 0' }}>Street 2</label>
                        <div className='drop_under font14_w4'>
                            <Input name="address.line2" value={formik.values.address.line2} onChange={formik.handleChange} />
                        </div>
                    </div>

                    <div>
                        <label className='font12_mono' style={{ padding: '10px 0' }}>City</label>
                        <div className='drop_under font14_w4'>
                            <Input name="address.city" value={formik.values.address.city} onChange={formik.handleChange} />
                        </div>
                    </div>
                    <div className='info_each'>
                        <label className='font12_mono' style={{ padding: '10px 0' }}>State</label>
                        <div className='drop_under font14_w4'>
                            <Input name="address.state" value={formik.values.address.state} onChange={formik.handleChange} />
                        </div>
                    </div>
                    <div className='info_each'>
                        <label className='font12_mono' style={{ padding: '10px 0' }}>Zip code</label>
                        <div className='drop_under font14_w4'>
                            <Input name="address.postal_code" value={formik.values.address.postal_code} onChange={formik.handleChange} />
                        </div>
                    </div>
                    <div className='info_each'>
                        <label className='font12_mono' style={{ padding: '10px 0' }}>Country</label>
                        <div className='drop_under font14_w4'>
                            <Input name="address.country" value={formik.values.address.country} onChange={formik.handleChange} />
                        </div>
                    </div>
                </div>
                <div className='personal_info2'>
                    <div className='font20 info_title'>
                        <div style={{ padding: '10px 0' }}>Mood</div>
                    </div>
                    <div
                        className="d-flex"
                        style={{ margin: "15px 0", alignItems: "end" }}
                    >
                        <DayNightToggle
                            onChange={() => dispatch(toggleTheme(!theme))}
                            checked={theme}
                            size={20}
                        />
                    </div>
                </div>
            </div>
            {isDataChanged && <div className='save_buttons'>
                {isSaving ? <>
                    <Loader />
                </> : <>
                    <div>
                        <button className='save_button font16' onClick={saveChanges}>Save Changes</button>
                    </div>
                    <div>
                        <button className='cancel_button font16' onClick={cancelChanges}>Cancel</button>
                    </div>
                </>}
            </div>}
            <img className='mobile_spotify' src={spotify} alt='spotify' style={{ bottom: 120 }} />
        </>
}
export default PersonalInfo;