import { useState, useRef, useMemo, useEffect } from "react";
import Flippy, { FrontSide, BackSide } from "react-flippy";
import { QRCode } from "react-qrcode-logo";
///////////
import { IconContext } from "react-icons";
import { AiOutlinePhone, AiOutlineMail } from "react-icons/ai";
import { VscLocation } from "react-icons/vsc";
import { CgWebsite } from "react-icons/cg";
///////////////
import useWindowDimensions from "hooks/useWindowDimensions";
import Loader from "components/common/loader";

import pass_avatar from "components/assets/pass_avatar.png";
import pass_code from "components/assets/pass_code.png";
import pass_2code from "components/assets/pass_2code.png";
import pass_rotate from "components/assets/pass_rotate.png";
import pass_left from "components/assets/pass_left.png";
import pass_right from "components/assets/pass_right.png";
import pass_logo from "components/assets/pass_logo.png";
import spotify from "components/assets/spotify.png";
import qr_logo from "components/assets/profile/qr_logo.png";

import "./passport.css";
import { getUserData } from "actions/firebase";
import { useUserId } from "actions/firebase";
import { formatDateFromTimestamp } from "utils/utils";
import { useSelector } from "react-redux";

function Passport() {
  const viewPort = useWindowDimensions();
  const [front, setFront] = useState(true);
  const ref = useRef();
  const userId = useUserId();

  const [isFlipped, setIsFlipped] = useState(false);

  const flipCard = () => {
    setIsFlipped(!isFlipped);
  };

  const spaceData = useSelector((state) => state.creators.creatorsSpaceData);

  console.log(spaceData);
  const [userData, setUserData] = useState({
    name: "",
    username: "",
    phoneNumber: "",
    imageUrl: "",
    birthday: "",
    address: "",
  });
  const [isLoading, setIsLoading] = useState(true);

  const preloadImages = () => {
    const images = [
      pass_avatar,
      pass_code,
      pass_logo /* ... other image URLs ... */,
    ];
    images.forEach((image) => (new Image().src = image));
  };

  useEffect(() => {
    preloadImages();
  }, []);

  const handleChangeView = () => {
    flipCard();
    // ref.current.toggle();
    // setTimeout(() => {
    //   setFront(!front);
    // }, 200);
  };

  const calculatePassportSize = useMemo(() => {
    if (viewPort.width < 520) {
      return {
        width: "100%",
        height: "100%",
      };
    } else if (viewPort.width < 680) {
      return {
        width: "100%",
        height: "90%",
      };
    } else if (viewPort.width < 680) {
      return {
        width: "100%",
        height: "90%",
      };
    } else if (viewPort.width < 1024) {
      return {
        width: "100%",
        height: "max(56%,400px)",
      };
    } else if (viewPort.width < 1280) {
      return {
        width: "90%",

        height: "max(70%,448px)",
      };
    } else if (viewPort.width < 1440) {
      return {
        width: "90%",
        height: "max(70%,500px)",
      };
    } else if (viewPort.width < 1920) {
      return {
        width: "90%",
        height: "max(80%,560px)",
      };
    }
    return {
      width: "75%",
      height: "75%",
    };
  }, [viewPort]);

  const monthNames = {
    January: "01",
    February: "02",
    March: "03",
    April: "04",
    May: "05",
    June: "06",
    July: "07",
    August: "08",
    September: "09",
    October: "10",
    November: "11",
    December: "12",
  };

  useEffect(() => {
    if (!userId) {
      return;
    }
    setIsLoading(true);
    getUserData()
      .then((data) => {
        const { user } = data;
        let value = JSON.parse(JSON.stringify(user));
        setUserData(value);
        console.log(value);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [userId]);

  if (isLoading) {
    return (
      <div className="loader_container">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div class="passport">
        <div
          ref={ref}
          className={`card ${isFlipped ? "flipped" : ""}`}
          onClick={flipCard}
          style={{
            width: calculatePassportSize.width,
            height: calculatePassportSize.height,
            margin: "0 auto",
          }}
        >
          <div class="card-front">
            <div className="pass_main">
              <div
                className="pass_front"
                style={{ display: front ? "flex" : "none" }}
              >
                <div className="pass_info1">
                  <div className="d-flex pass_row">
                    <div className="pass_item">
                      <label className="pass_title">Username</label>
                      <label className="pass_content">
                        {userData.username}
                      </label>
                    </div>
                  </div>
                  <div className="-flex pass_row">
                    <div className="pass_item">
                      <label className="pass_title">Membership #</label>
                      <label className="pass_content">2644</label>
                    </div>
                  </div>
                  <div className="d-flex pass_row">
                    <div className="pass_item">
                      <label className="pass_title">Full Name</label>
                      <label className="pass_content">{userData.name}</label>
                    </div>
                  </div>

                  <div className="d-flex pass_row">
                    <div className="pass_item">
                      <label className="pass_title">Member Since</label>
                      <label className="pass_content">
                        {formatDateFromTimestamp(userData?.createdDts)}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="pass_info2">
                  <div className="pass_identify">
                    <div className="pass_avatar p-3 w-100">
                      <img
                        src={userData?.imageUrl}
                        alt="passcode_avatar"
                        className="d-flex mx-auto w-100 h-100"
                      />
                    </div>
                    <div className="pass_code p-2 w-100">
                      <img
                        src={pass_code}
                        alt="passcode_code"
                        className="mx-auto w-100"
                      />
                    </div>
                  </div>
                  <div className="barcode">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: `3px solid #000`,
                        borderRadius: 10,
                        backgroundColor: "#000",
                      }}
                    >
                      <QRCode
                        logoOnLoad={() => console.log("logo loaded")}
                        bgColor="#000"
                        qrStyle="squares"
                        fgColor="#fff"
                        value="https://auradrops.com"
                        size={
                          window.innerWidth > 680
                            ? 130
                            : (window.innerWidth * 60) / 400
                        }
                        logoImage={qr_logo}
                        logoWidth={30}
                        logoHeight={30}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-back">
            <div className="pass_main">
              <div className="pass_back">
                <img className="pass_logo" src={pass_logo} alt="pass_logo" />
              </div>
            </div>
          </div>
        </div>
        <div className="pass_rotate p-5 mx-auto">
          <div onClick={handleChangeView} className="mt-3">
            <img src={pass_left} alt="pass_left" />
          </div>
          <div>
            <img src={pass_rotate} alt="pass_rotate" />
          </div>
          <div onClick={handleChangeView} className="mt-3">
            <img src={pass_right} alt="pass_right" />
          </div>
        </div>
      </div>
      {/* <div className="passport">
        <Flippy
          flipOnHover={false}
          flipOnClick={true}
          flipDirection="horizontal"
          //  ref={ref}
          style={{
            width: calculatePassportSize.width,
            height: calculatePassportSize.height,
            margin: "0 auto",
          }}
          onClick={handleChangeView}
        >
          <FrontSide style={{ boxShadow: "none" }}>
            <div className="pass_main">
              <div
                className="pass_front"
                style={{ display: front ? "flex" : "none" }}
              >
                <div className="pass_info1">
                  <div className="d-flex pass_row">
                    <div className="pass_item">
                      <label className="pass_title">FullName</label>
                      <label className="pass_content">{userData.name}</label>
                    </div>
                    <div className="pass_item">
                      <label className="pass_title">NickName</label>
                      <label className="pass_content">
                        {userData.username}
                      </label>
                    </div>
                  </div> */}
      {/* <div className="d-flex pass_row">
                  <div className="pass_item">
                    <label className="pass_title">DOB</label>
                    <label className="pass_content">{`${monthNames[userData.birthday?.month]}/${userData.birthday?.day}/${userData.birthday?.year}`}</label>
                  </div>
                  <div className="pass_item">
                    <label className="pass_title">Phone</label>
                    <label className="pass_content">{userData.phoneNumber}</label>
                  </div>
                </div> */}
      {/* <div className="d-flex pass_row">
                    <div className="pass_item">
                      <label className="pass_title">Member Since</label>
                      <label className="pass_content">
                        {formatDateFromTimestamp(userData?.createdDts)}
                      </label>
                    </div>
                    <div className="pass_item">
                      <label className="pass_title">Membership #</label>
                      <label className="pass_content">2644</label>
                    </div>
                  </div> */}
      {/* <div className="d-flex pass_row">
                  <div className="pass_item w-100">
                    <label className="pass_title">Address</label>
                    <label className="pass_content">
                      855 N Vermont Ave Los Angeles CA 90029 USA
                    </label>
                  </div>
                </div>
                <div className="d-flex pass_row">
                  <div className="pass_item">
                    <label className="pass_title">Payment</label>
                    <label className="pass_content">Credit Card</label>
                  </div>
                </div>
                <div className="d-flex pass_row">
                  <div className="pass_item w-50">
                    <label className="pass_content">7374 2454 5540 0126</label>
                  </div>
                  <div className="pass_item">
                    <label className="pass_content">Hotaru Eri</label>
                  </div>
                </div>
                <div className="d-flex pass_row">
                  <div className="pass_item float-left">
                    <label className="pass_content">04/29</label>
                  </div>
                  <div className="pass_item">
                    <label className="pass_content">512</label>
                  </div>
                </div> */}
      {/* </div>
                <div className="pass_info2">
                  <div className="pass_identify">
                    <div className="pass_avatar p-3 w-100">
                      <img
                        src={userData?.imageUrl}
                        alt="passcode_avatar"
                        className="d-flex mx-auto w-100 h-100"
                      />
                    </div>
                    <div className="pass_code p-2 w-100">
                      <img
                        src={pass_code}
                        alt="passcode_code"
                        className="mx-auto w-100"
                      />
                    </div>
                  </div>
                  <div className="barcode">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: `3px solid #000`,
                        borderRadius: 10,
                        backgroundColor: "#000",
                      }}
                    >
                      <QRCode
                        logoOnLoad={() => console.log("logo loaded")}
                        bgColor="#000"
                        qrStyle="squares"
                        fgColor="#fff"
                        value="https://auradrops.com"
                        size={
                          window.innerWidth > 680
                            ? 130
                            : (window.innerWidth * 90) / 400
                        }
                        logoImage={qr_logo}
                        logoWidth={30}
                        logoHeight={30}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </FrontSide>
          <BackSide style={{ boxShadow: "none" }}>
            {!front && (
              <div className="pass_main">
                <div className="pass_back">
                  <img className="pass_logo" src={pass_logo} alt="pass_logo" />
                </div>
              </div>
            )}
          </BackSide>
        </Flippy>

        <div className="pass_rotate p-5 mx-auto">
          <div onClick={handleChangeView} className="mt-3">
            <img src={pass_left} alt="pass_left" />
          </div>
          <div>
            <img src={pass_rotate} alt="pass_rotate" />
          </div>
          <div onClick={handleChangeView} className="mt-3">
            <img src={pass_right} alt="pass_right" />
          </div>
        </div>
        <img
          className="mobile_spotify"
          src={spotify}
          alt="spotify"
          style={{ bottom: "1%" }}
        />
      </div> */}
    </>
  );
}
export default Passport;
