import { OverlayTrigger } from "react-bootstrap";
import { renderTooltip } from "./shareTooltip";
import arrow from "components/assets/arrow.svg";
import arrow2 from "components/assets/arrow2.svg";
import { isIOS } from "utils/utils";

const canShare = window.navigator.share && isIOS();

const ShareIcon = ({theme, link}) => {
  return canShare ?
    <img
      src={theme ? arrow : arrow2}
      alt="Share"
      style={{ cursor: "pointer" }}
      className="profile_arrow"
      onClick={() => window.navigator.share({url: link})}
    />
  :
    <OverlayTrigger
      rootClose
      trigger="click"
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={props => renderTooltip({...props, link})}
    >
      <img
        src={theme ? arrow : arrow2}
        alt="Share"
        style={{ cursor: "pointer" }}
        className="profile_arrow"
      />
    </OverlayTrigger>
}

export default ShareIcon;