import { useEffect, useState, useMemo } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdGridView } from "react-icons/md";
import DayNightToggle from "react-day-and-night-toggle";
import { Collapse, Drawer } from "antd";
import _ from "lodash";

import ellipse from "components/assets/ellipse.png";
import presale from "components/assets/presal.png";
import raffle from "components/assets/raffle.png";
import pin from "components/assets/pin.png";
import ellipse2 from "components/assets/ellipse2.png";
import presale2 from "components/assets/presal2.png";
import raffle2 from "components/assets/raffle2.png";
import pin2 from "components/assets/pin2.png";
import filter_arrow from "components/assets/filter_arrow.png";
import filter_arrow2 from "components/assets/filter_arrow2.png";
import collape_icon from "components/assets/collape_icon.png";
import collape_icon2 from "components/assets/collape_icon2.png";
import collape_icon_d from "components/assets/collape_icon_d.png";
import collape_icon_d2 from "components/assets/collape_icon_d2.png";
import { validateUserName } from "components/common/namesValidator";
import fashion from "components/assets/fashion.png";
import goods from "components/assets/goods.png";
import tech from "components/assets/tech.png";
import beauty from "components/assets/beauty.png";
import fashion2 from "components/assets/fashion2.png";
import goods2 from "components/assets/goods2.png";
import tech2 from "components/assets/tech2.png";
import beauty2 from "components/assets/beauty2.png";
import CloseButton from "components/common/CloseButton";
import Playlist from "components/Spotify/Playlist";

import profileDefaultDark from "components/assets/profile_default_dark.png";
import profileDefaultLight from "components/assets/profile_default_light.png";
import netDark from "components/assets/net_dark.gif";
import netLight from "components/assets/net_light.gif";
import Profile from "components/assets/nav/profile.svg";

import Heart_Fill from "components/assets/nav/tray_heart_fill.svg";
import Heart from "components/assets/nav/tray_heart.svg";

import MapFill from "components/assets/nav/tray_map_fill.svg";
import Map from "components/assets/nav/tray_map.svg";

import Calendar from "components/assets/nav/tray_calendar_unfill.svg";
import CalendarActive from "components/assets/nav/tray_calendar_fill.svg";

import { toggleTheme } from "actions/index";
import { setDropFilters } from "actions/dropFilters";
import { getCreatorsList } from "actions/creator";

import "./css/channels.css";
import "animate.css";
import { setThemeColors } from "utils/utils";
import { useUserData } from "actions/firebase";
import PhoneModal from "./PhoneModal";
import { LogoNoTitle } from "res/svg";
import { ColorChanger } from "res/svg";

const { Panel } = Collapse;

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    dropFilters: state.dropFilters,
    creatorsList: state.creators.creatorsList,
    loading: !state.creators.creatorsListLoading,
  };
};

function Channels(props) {
  const { theme, toggleTheme, dropFilters, creatorsList, loading } = {
    ...props,
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useUserData();
  const location = useLocation();

  const [mode, setMode] = useState(false);
  const [filter, showFilter] = useState(false);
  const [showRaffle, setShowRaffle] = useState(false);
  const [locateView, setLocationView] = useState(true);
  const [isCalendarActive, setIsCalendarActive] = useState(false);
  const [isProfileActive, setIsProfileActive] = useState(false);
  const [isFavorited, setIsFavorited] = useState(false);
  const [isMapActive, setIsMapActive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  let tablet = false;
  if (window.innerWidth > 680 && window.innerWidth < 1200) {
    tablet = true;
  } else {
    tablet = false;
  }

  let mobileView = false;
  if (window.innerWidth < 680) {
    mobileView = true;
  }
  

  useEffect(() => {
    setIsCalendarActive(location.pathname === '/');
    setIsProfileActive(userData && location.pathname.indexOf('/profile') === 0)
    setIsFavorited(location && location?.pathname?.includes('favorites'))
    setIsMapActive(location && location?.pathname?.includes('home/globemap'))
  }, [location]);

  useEffect(() => {
    dispatch(getCreatorsList());
  }, [dispatch]);

  const filteredCreators = useMemo(
    () =>
      creatorsList.filter(
        (creator) => creator.username && creator.profileImage && creator
      ),
    [creatorsList]
  );

  const handleGoHome = () => {
    navigate("/");
  }


  const handleLogin = () => {
    setIsModalOpen(true)
  }

  const handleFavorite = () => {
    navigate('/favorites');
  }

  const handleMap = () => {
    navigate('/home/globemap');
  }

  const onLogin = () => {
    navigate('/profile/personalinfo');
  }

  // const handleSwitch = () => {
  //   toggleTheme(!theme);
  // };
  const handleFilter = () => {
    showFilter(!filter);
  };
  const handleSetFilter = (category, filterType) => {
    dispatch(
      setDropFilters({ category: category, value: filterType.toLowerCase() })
    );
  };
  const onChange = () => {
    console.log("text");
  };

  // const locationView = () => {
  //   setLocationView(!locateView);
  // };
  const full_view = () => {
    setLocationView(true);
  };
  const grid_view = () => {
    setLocationView(true);
  };

  const checkIsButtonActive = (name, type) => {
    if (dropFilters?.filter[type].includes(name.toLowerCase())) {
      return true;
    }
    return false;
  };

  const dropTypeTabs = [
    {
      name: "Now",
      id: "1",
      img: theme ? ellipse : ellipse2,
      activeImg: ellipse2,
    },
    {
      name: "Presale",
      id: "2",
      img: theme ? presale : presale2,
      activeImg: presale2,
    },
    {
      name: "Raffle",
      id: "3",
      img: theme ? raffle : raffle2,
      activeImg: raffle2,
    },
    {
      name: "Pin",
      id: "4",
      img: theme ? pin : pin2,
      activeImg: pin2,
    },
  ];

  const categoryTabs = [
    {
      name: "Fashion",
      id: "1",
      img: theme ? fashion : fashion2,
      activeImg: fashion2,
    },
    {
      name: "Beauty",
      id: "2",
      img: theme ? beauty : beauty2,
      activeImg: beauty2,
    },
    {
      name: "Tech",
      id: "3",
      img: theme ? tech : tech2,
      activeImg: tech2,
    },
    {
      name: "Goods",
      id: "4",
      img: theme ? goods : goods2,
      activeImg: goods2,
    },
  ];

  useEffect(() => {
    localStorage.setItem("mode", JSON.stringify(mode));
    setThemeColors(theme);

    if (filter) {
      document.documentElement.style.setProperty("--filter_width", !tablet ? "220px" : "180px");
    } else {
      document.documentElement.style.setProperty("--filter_width", tablet ? "80px" : "100px");
    }
  }, [theme, filter]);

  const headerStatus = useSelector(state => state.header);

  return (
    <>
      <div
        className="channels"
        style={{
          display: !filter ? "flex" : "none",
          flexDirection: "column",
          justifyContent: "space-between",
          height: '100%',
        }}
      >
        <div style={{ position: 'absolute', left: 'calc(50% - 17px)', }}>
          {headerStatus && <Link to="/">
            <ColorChanger>
              {(fillColor) => <LogoNoTitle fillColor={fillColor} />}
            </ColorChanger>
          </Link>}
        </div>

        <div style={{ marginTop: '120px', gap: '25px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

          <div onClick={handleGoHome} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            <img className="me-1 nav__img" src={isCalendarActive ? CalendarActive : Calendar} />
          </div>

          <div onClick={handleMap} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            <img className="me-1 nav__img" src={isMapActive ? MapFill : Map} />
          </div>

          <div onClick={userData ? handleFavorite : handleLogin} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            <img className="me-1 nav__img" src={isFavorited ? Heart_Fill : Heart} />
          </div>

          <div onClick={userData ? onLogin : handleLogin} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            <img className={userData ? 'nav__profile-img' : 'nav__img'} src={userData?.imageUrl || Profile} />
          </div>

          <PhoneModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            onSuccess={onLogin}
          />

        </div>
        <div className="channel_spotify">
          <div className="desktop_mode">
            <Playlist />
          </div>
          <div style={{ margin: "15px 0px" }}>
            <DayNightToggle
              onChange={() => toggleTheme(!theme)}
              checked={theme}
              size={20}
            />
          </div>
        </div>
      </div>

      <div
        className="filter"
        id="fade_div_component"
        draggable={false}
        style={{
          display: !filter ? "none" : "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div className="filter-content">
          <div style={{ cursor: "pointer", gap: 20 }} onClick={handleFilter}>
            <label className="font16 p-3" style={{ cursor: "pointer" }}>
              Channels
            </label>
            <img src={theme ? filter_arrow : filter_arrow2} alt="" />
          </div>
          <div className="channel_viewer pl-3">
            <Link to="/">
              <div className="grid_view" tabIndex="1" onClick={grid_view}>
                <MdGridView size={25} />
              </div>
            </Link>
            {/* <Link to="/fullscreenview">
              <div className="full_view" tabIndex="2" onClick={full_view}>
                <CgLaptop size={25} />
              </div>
            </Link> */}
            {/* <Link to='/globemap'>
                        <div onClick={locationView} style={{ paddingBottom: 4 }}>
                            <img src={locateView ? location2 : location} alt='' style={{ cursor: "pointer", width: 19, height: 19 }} />
                        </div>
                    </Link> */}
          </div>
          <Collapse
            defaultActiveKey={["1"]}
            onChange={onChange}
            expandIconPosition="end"
            expandIcon={({ isActive }) => (
              <img
                src={
                  isActive
                    ? theme
                      ? collape_icon
                      : collape_icon_d
                    : theme
                      ? collape_icon2
                      : collape_icon_d2
                }
                alt="collape"
              />
            )}
          >
            <Panel header="Drop Type" key="1">
              {dropTypeTabs.map((el) => (
                <div
                  onClick={() => handleSetFilter("dropType", el.name)}
                  className="channel_item"
                  style={{
                    cursor: "pointer",
                    background:
                      checkIsButtonActive(el.name, "dropType") && "#FFF",
                    borderRadius: "20px",
                    padding: "4px 10px 4px 10px",
                  }}
                  key={el.id}
                >
                  <img
                    src={
                      checkIsButtonActive(el.name, "dropType")
                        ? el.activeImg
                        : el.img
                    }
                    alt=""
                  />
                  <div
                    style={{
                      color:
                        checkIsButtonActive(el.name, "dropType") && "#0d0d0d99",
                    }}
                    className="font12_6"
                  >
                    {el.name}
                  </div>
                </div>
              ))}
            </Panel>
            <Panel header="Creators" key="2">
              {!_.isEmpty(filteredCreators) &&
                filteredCreators.map((creator) => (
                  <div
                    onClick={() => {
                      navigate(`/${creator.username}`);
                    }}
                    className="d-flex align-items-center p-2"
                    style={{ gap: 10, cursor: 'pointer' }}
                    key={creator.id}
                  >
                    <div>
                      <img
                        src={creator.profileImage}
                        alt=""
                        style={{ width: 30, height: 30, borderRadius: "100%" }}
                      />
                    </div>
                    <label className="font12_6">
                      {validateUserName(creator.username)}
                    </label>
                  </div>
                ))}
            </Panel>
            <Panel header="Categories" key="3">
              {categoryTabs.map((el) => (
                <div
                  key={el.id}
                  onClick={() => handleSetFilter("categories", el.name)}
                  className="channel_item"
                  style={{
                    cursor: "pointer",
                    background:
                      checkIsButtonActive(el.name, "categories") && "#FFF",
                    borderRadius: "20px",
                    padding: "4px 10px 4px 10px",
                  }}
                >
                  <img
                    src={
                      !checkIsButtonActive(el.name, "categories")
                        ? el.img
                        : el.activeImg
                    }
                    alt=""
                  />
                  <div
                    style={{
                      color: checkIsButtonActive(el.name, "categories")
                        ? "rgba(13, 13, 13, 0.6)"
                        : "#ffffff99",
                      fontWeight: 400,
                      fontSize: "12px",
                    }}
                  >
                    {el.name}
                  </div>
                </div>
              ))}
            </Panel>
          </Collapse>
        </div>

        <div className="channel_spotify">
          <div style={{ margin: "15px 0" }}>
            <Playlist />
          </div>
          <div
            className="d-flex"
            style={{ margin: "15px 0", alignItems: "end" }}
          >
            <DayNightToggle
              onChange={() => toggleTheme(!theme)}
              checked={theme}
              size={20}
            />
          </div>
        </div>
      </div>
      <Drawer
        placement={mobileView ? "bottom" : "right"}
        closable={false}
        onClose={() => setShowRaffle(false)}
        open={showRaffle}
      // size={drawerSize}
      >
        <div
          className="mobile_version"
          style={{ height: 200, background: "transparent" }}
        ></div>
        <div className="tray">
          <CloseButton className="" onClick={() => setShowRaffle(false)} />
        </div>
      </Drawer>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleTheme: (value) => {
      dispatch(toggleTheme(value));
    },
  };
};

// export default Header;
export default connect(mapStateToProps, mapDispatchToProps)(Channels);
