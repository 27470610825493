import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import _ from "lodash";

import SkeletonComponent from "components/Skeleton/index";
import ReactLoader from "components/common/loader";
import { validateUserName } from "components/common/namesValidator";
import avatar_icon from "components/assets/icons/empty_avatar.svg";
import { getSimilarCreators } from "actions/firebase";

import "./layout.css";

function AvatarGroup() {
  const [loading, setLoading] = useState(true);
  const [creatorsList, setCreatorsList] = useState([]);

  useEffect(() => {
    setLoading(true);
    getSimilarCreators().then(results => {
      setCreatorsList(results)
      setLoading(false);
    });
  }, []);

  return (
    <div className="home_avatar_group">
      {creatorsList.length ? (
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          speed={500}
          loop={true}
          touchRatio={1.5}
          navigation={true}
          effect={"flip"}
          pagination={{ clickable: true }}
          breakpoints={{
            // when window width is >= 640px
            350: {
              width: 350,
              slidesPerView: 2.5,
            },
            640: {
              width: 640,
              slidesPerView: 5,
            },
            // when window width is >= 768px
            1200: {
              width: 1200,
              slidesPerView: 8,
            },
            1600: {
              width: 1600,
              slidesPerView: 10,
            },
          }}
          watchSlidesProgress={true}
          className="mySwiper"
        >
          {creatorsList.map(creator =>
            <SwiperSlide key={creator?.id}>
              <Link
                to={`/${creator?.username}`}
                state={{ creatorId: creator.id }}
              >
                <div
                  className="each_avatar"
                  style={{ cursor: "pointer" }}
                >
                  {!loading ? (
                    <div
                      className="avatar-creators"
                      style={{
                        backgroundImage: `url(${creator.profileImage || avatar_icon
                          })`
                      }}
                    />
                  ) : (
                    <SkeletonComponent height={84} width={84} circle />
                  )}
                  {!loading ? (
                    <div className="font12 mt-2 mt-2 text-lowercase">
                      {validateUserName(creator?.username) || ""}
                    </div>
                  ) : (
                    <SkeletonComponent
                      width={44}
                      height={10}
                      style={{ float: "left", marginTop: "10px" }}
                    />
                  )}
                </div>
              </Link>
            </SwiperSlide>
          )}
        </Swiper>
      ) : (
        <ReactLoader />
      )}
    </div>
  );
}
export default AvatarGroup;
