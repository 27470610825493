import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import dark404 from 'components/assets/404_dark.svg';
import light404 from 'components/assets/404_light.svg';
import drop from 'components/assets/dropBack.png';
import './index.css'

const Page404 = () => {

    const theme = useSelector(state => state.theme);
    const img_404 = theme ? dark404 : light404;
    const navigate = useNavigate();

    const handleHome = () => {
        navigate('/');
    }

    return (
        <div className='page_404_container'>
            <div className='flex-1'>
                <div className='page_404_order_font'>{`Sorry, the page you are looking for doesn't exist or has been moved!`}</div>
                <div className='page_404_order_divider' />
                <div className='page_404_order_dropButton' onClick={handleHome}>
                    <img src={drop} />
                    <div className='font16 page_404_order_dropfont'>{'HomePage'}</div>
                </div>
            </div>
            <div className='page404_img_container'>
                <img className='page404_img' src={img_404} />
            </div>
        </div>
    );
}

export default Page404;