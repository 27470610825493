import { useState } from "react";
import { Routes, Route } from "react-router-dom";

import AvatarGroup from "components/Homepage/AvatarGroup";
import CardGroup from "components/Product/CardGroup";
import Banner from "components/Homepage/Banner";
import FullscreenView from "components/Homepage/FullscreenView";
import GlobalView from "components/GlobalView";
import AllDropsButton from "../Button/AllDropsButton";
import ExclusiveButton from "../Button/ExclusiveButton";
// import Favorites from 'components/Profile/Favorites';
import MapWrapper from "components/Darkmap";

function GridView(props) {
  const [isAllDrops, setIsAllDrops] = useState(false);

  return (
    <>
      <div style={{ width: "100%" }}>
        <label
          className="font12 text-left p-2 featured_label"
          style={{ fontFamily: "Vulf Mono Demo" }}
        >
          FEATURED CREATORS
        </label>
        <AvatarGroup />
      </div>
      <Banner />
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ gap: 10 }}
      >
        <AllDropsButton
          isActive={isAllDrops}
          onClick={() => {
            setIsAllDrops(true);
          }}
        />
        <ExclusiveButton
          isActive={!isAllDrops}
          onClick={() => {
            setIsAllDrops(false);
          }}
        />
      </div>
      <label
        className="font12 text-left p-2"
        style={{ fontFamily: "Vulf Mono Demo" }}
      >
        DROPS
      </label>
      <div style={{ flex: 1, overflow: "auto" }} className="scroll">
        <CardGroup show={props.show} />
      </div>
    </>
  );
}

function Header() {
  return (
    <>
      <div className="home_main">
        <div className="main_first_part" style={{ width: "100%" }}>
          <Routes>
            <Route element={<GridView />} path="/" />
            <Route element={<FullscreenView />} path="/fullscreenview" />
            <Route element={<MapWrapper />} path="/map" />
            <Route element={<GlobalView />} path="/globemap" />
          </Routes>
        </div>
      </div>
    </>
  );
}
export default Header;
