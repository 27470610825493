const Color = {
    viewsColor: '#40C397',
    salesColor: '#EE4744',
    connectionsColor: '#F0E681',
    visitorColor: '#F0E681',
    dropsColor: '#3784AB',
    pageViewColor: '#3784AB',
    salesColor90: '#EE4744B2',
    visitorColor90: '#F0E681B2',
    pageViewColor90: '#40C397B2',
    salesColor70: '#EE474488',
    visitorColor70: '#F0E68188',
    pageViewColor70: '#40C39788',
    gray60: "#666666",
    pointHoverBackgroundColor: "#40C094B2",
    pointHoverBorderColor: "rgba(220,220,220,1)",
    listColors: [
        "#fcba03",
        "#fa8c5c",
        "#9fc934",
        "#60d17e",
        "#45afed",
        "#7c5cdb",
        "#ce5cdb",
        "#db5c97",
    ]
}

export default Color;