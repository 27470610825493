import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import Channels from "components/Author/Channels";
import Main from "components/Author/Main";
import spotify from 'components/assets/spotify.png';
import FloatingNav from "components/common/FloatingNav";
import { signInWithToken, useUserData } from "actions/firebase";

function Author() {

    const userData = useUserData();
    const { pathname } = useLocation();
    const [isEditSpace, setIsEditSpace] = useState(false);

    useEffect(() => {
        const funtion = async () => {
            try {
                const token = pathname.split('/')[2];
                token && await signInWithToken({ customToken: token });
            } catch (error) {
                console.error('Error signing in with custom token:', error);
            }
        };
        funtion();
    }, []);

    useEffect(() => {
        setIsEditSpace(pathname.includes(`/${userData?.username}`));
    }, [userData]);

    return (
        <>
            <div className="author_body">
                <Channels />
                <Main isEditSpace={isEditSpace} userName={pathname.split('/')[1]} visitorName={userData?.username} />
            </div>
            <img className='mobile_spotify' src={spotify} alt='spotify' style={{ bottom: '1%' }} />
            {!isEditSpace && <FloatingNav />}
        </>
    )
}
export default Author;