import { API } from "./api";
import axios from "axios";
import _ from "lodash";
import {
  GET_PRODUCT_LIST_BY_SPACE_LOADING,
  GET_PRODUCT_LIST_BY_SPACE,
  UPDATE_PRODUCT_LIST_BY_SPACE,
  GET_PRODUCT_LIST_LOADING,
  GET_PRODUCT_LIST,
  GET_OLD_PRODUCT_LOADING,
  GET_OLD_PRODUCT,
  CLEAR_PRODUCT_LIST_BY_SPACE,
} from "actions/types";

export const clearProductsListBySpace = () => async (dispatch) => {
  dispatch({
    type: CLEAR_PRODUCT_LIST_BY_SPACE,
    payload: [],
  });
};

export const getProductsListBySpace =
  (spaceId, limit = 10, offset = 0, type) =>
    async (dispatch) => {
      dispatch({
        type: GET_PRODUCT_LIST_BY_SPACE_LOADING,
        payload: true,
      });
      try {
        const res = await axios({
          url: `${API}/getProductsListBySpace`,
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          data: JSON.stringify({
            data: {
              limit,
              offset,
              spaceId,
              active: true,
            },
          }),
        });
        if (type === "update") {
          dispatch({
            type: UPDATE_PRODUCT_LIST_BY_SPACE,
            payload: res.data?.result,
          });
        } else {
          dispatch({
            type: GET_PRODUCT_LIST_BY_SPACE,
            payload: res.data?.result,
          });
        }
        dispatch({
          type: GET_PRODUCT_LIST_BY_SPACE_LOADING,
          payload: false,
        });
      } catch (error) {
        console.log("getProductsListBySpace error:", error);
      }
    };

export const getProductsList =
  (limit = 10, offset = 0) =>
    async (dispatch) => {
      dispatch({
        type: GET_PRODUCT_LIST_LOADING,
        payload: true,
      });
      try {
        const res = await axios({
          url: `${API}/getProductsList`,
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          data: JSON.stringify({
            data: {
              limit,
              offset,
              active: true,
            },
          }),
        });
        dispatch({
          type: GET_PRODUCT_LIST,
          payload: res.data?.result,
        });
        dispatch({
          type: GET_PRODUCT_LIST_LOADING,
          payload: false,
        });
      } catch (error) {
        console.log("getProductsList error:", error);
      }
    };

export const getOldProduct = (id) => async (dispatch) => {
  dispatch({
    type: GET_OLD_PRODUCT_LOADING,
    payload: true,
  });
  try {
    const res = await axios({
      url: `https://us-central1-shop-aura.cloudfunctions.net/api/product/${id}`,
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    dispatch({
      type: GET_OLD_PRODUCT,
      payload: res.data,
    });
    dispatch({
      type: GET_OLD_PRODUCT_LOADING,
      payload: false,
    });
  } catch (error) {
    console.log("getOldProduct error:", error);
  }
};
