
import image_dark_active from 'components/assets/aura_drop_dark.svg';
import image_light_active from 'components/assets/aura_drop_light.svg';
import image_dark_inactive from 'components/assets/aura_drop_dark.svg';
import image_light_inactive from 'components/assets/aura_drop_light.svg';
import { useSelector } from 'react-redux';
import './button.css';

const AllDropsButton = ({ isActive = false, onClick }) => {

    const theme = useSelector(state => state.theme);
    const style = {
        backColor: theme ? isActive ? '#3D3D3D' : 'transparent' : isActive ? '#CCB783' : 'transparent',
        fontColor: theme ? isActive ? '#FFFFFF' : '#ADADAD' : isActive ? '#FFFFFF' : '#CCB783',
        borderColor: theme ? '#3D3D3D' : '#CCB783',
        image: !theme ? isActive ? image_light_active : image_dark_inactive : isActive ? image_light_active : image_light_inactive 
    }

    return (
        <div className='button_container' onClick={onClick} style={{ background: style.backColor, borderColor: style.borderColor }} >
            <img src={style.image} className='m-2' alt='aura_button' style={{ width: '15px' }} />
            <label className='font14 recoleta-semibold' style={{ color: style.fontColor, lineHeight: 0 }}>{'ALL DROPS'}</label>
        </div>
    );
}

export default AllDropsButton;