import { useEffect, useState, useRef } from 'react';
import Lottie from "lottie-react";
import rings from "components/assets/lottie/rings.json";
import notes from "components/assets/lottie/music_notes.json";
import logo from 'components/assets/spotify_logo.svg';
import logo_day from 'components/assets/spotify_logo_day.svg';
import logo_play from 'components/assets/spotify_logo_play.svg';
import { authEndpoint, clientId, redirectUri, scopes } from './config';
import './css/playlist.css';
import { useSelector } from 'react-redux';
import CloseButton from 'components/common/CloseButton';

const playlistId = '05KlSHBRbTISzaw2y2Joz0';
const playlistUri = `spotify:playlist:${playlistId}`;
let isNewToken = false;

const Playlist = () => {
  const [token, setToken] = useState("");
  const [deviceList, setDeviceList] = useState();
  const [devicePrompt, setDevicePrompt] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const activeDevice = useRef();
  const theme = useSelector(state => state.theme);

  const logout = () => {
    setToken("");
    setDeviceList(null);
    activeDevice.current = null;
    window.localStorage.removeItem("token")
  }

  const handleClick = async ev => {
    if (devicePrompt) { setDevicePrompt(false); return; }
    console.log({ token, activeDevice, deviceList });
    if (!token) {
      const href = `${authEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes.join("%20")}&response_type=token&show_dialog=true`;
      window.location = href;
    }
    else {
      if (activeDevice.current) {
        const status = await playerStatus();
        if (status) {
          status.is_playing ? pausePlayer() : startPlayer(activeDevice.current, true);
        }
      }
      else {
        setDevicePrompt(true);
      }
    }
  }

  const refreshToken = async token => {
    const response = await fetch(`https://accounts.spotify.com/api/token`, {
      method: 'PUT',
      headers: {
        Authorization: `Basic ${window.btoa(clientId + ':' + clientId)}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
      })
    })
    const json = await response.json();
    console.log(json);
  }

  const startPlayer = async (deviceId, isUnpause) => {
    let body = '';

    if (!isUnpause) {
      body = JSON.stringify({
        context_uri: playlistUri
      })
    }

    await fetch(`https://api.spotify.com/v1/me/player/play?device_id=${deviceId}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body
    })

    setIsPlaying(true);
  }

  const pausePlayer = async () => {
    await fetch(`https://api.spotify.com/v1/me/player/pause?device_id=${activeDevice.current}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
    setIsPlaying(false);
  }

  const playerStatus = async (currentToken) => {
    const response = await fetch(`https://api.spotify.com/v1/me/player`, {
      headers: {
        Authorization: `Bearer ${currentToken || token}`,
        'Content-Type': 'application/json'
      }
    })
    if (response.status === 200) {
      const json = await response.json();
      return json;
    }
    else if (response.status === 204) {
      return { is_playing: false };
    }
  }

  const getDevices = async (currentToken) => {
    const response = await fetch(`https://api.spotify.com/v1/me/player/devices`, {
      headers: {
        Authorization: `Bearer ${currentToken || token}`,
        'Content-Type': 'application/json'
      },
    })
    if (response.status === 200) {
      const { devices } = await response.json();

      // Temperally use this filter block
      console.log('All Devices: ', devices);
      const filteredDevices = devices.length ? devices.filter(device => !device.name.includes('Web Player')) : null;
      setDeviceList(filteredDevices);
      return devices;
    }
  }

  const selectDevice = id => {
    console.log('selectDevice');
    activeDevice.current = id;
    setDevicePrompt(false);
    startPlayer(id)
  }

  useEffect(() => {
    const hash = window.location.hash
    let token = window.localStorage.getItem("token")

    if (hash) {
      token = hash.substring(1).split("&").find(elem => elem.startsWith("access_token")).split("=")[1]
      window.location.hash = ""
      window.localStorage.setItem("token", token)
      isNewToken = true;
    }

    if (token) {
      getDevices(token).then(devices => {
        if (devices) {
          isNewToken && setDevicePrompt(true);
        }
        else {
          logout();
        }
      });
    }

    setToken(token)
  }, [])

  return <div className="spotifyPl">
    <button className="spotifyPl__button" onClick={handleClick}>
      {isPlaying &&
        <Lottie className="spotifyPl__notes" animationData={notes} loop={true} />
      }
      <div className="spotifyPl__logo-container">
        <Lottie className="spotifyPl__rings isPlay" animationData={rings} loop={true} />
        {isPlaying && <img className="spotifyPl__logo" src={logo_play} alt='Spotify Logo' />}
        {!isPlaying && <img className="spotifyPl__logo" src={theme ? logo : logo_day} alt='Spotify Logo' />}
      </div>
    </button>

    {deviceList && devicePrompt &&
      <div className="spotifyPl__devices">
        <div className="spotifyPl__devices--header">Select Device</div>
        <CloseButton isMini onClick={() => { setDevicePrompt(false) }} />
        {deviceList.map(device =>
          <div className="spotifyPl__devices--option" key={device.id} onClick={() => selectDevice(device.id)}>
            {device.name}
          </div>
        )}
        {deviceList?.length === 0 && <div className="spotifyPl__devices--option" key='No Device'>
          {'No devices'}
        </div>}
      </div>
    }
  </div >
}

export default Playlist;