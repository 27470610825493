import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import _ from "lodash";
import SkeletonComponent from "components/Skeleton/index";
import { validateUserName } from "components/common/namesValidator";
import avatar_icon from "components/assets/icons/empty_avatar.svg";
import dove from "components/assets/dove.png";
import dove2 from "components/assets/dove2.png";
import ShareIcon from "components/common/ShareIcon";
import ConnectButton from "components/common/ConnectButton";
import {
  getSimilarCreators,
  getCreatorSpaceDataByUsername
} from "actions/creator";
import { getSpaceData } from "actions/firebase";
import "./css/main.css";

const mapStateToProps = (state) => ({
  creatorsSpaceLoading: state.creators.creatorsSpaceLoading,
  creatorsSpaceData: state.creators.creatorsSpaceData,
  theme: state.theme,
});

function ProfileCard({ theme, creatorsSpaceLoading, creatorsSpaceData }) {
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const [shareLink, setShareLink] = useState();
  const [spaceColor, setSpaceColor] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const { creatorName } = params;
    if (creatorName && location.state?.fetching !== "stop") {
      dispatch(getCreatorSpaceDataByUsername(creatorName, navigate));
    }
  }, []);

  useEffect(() => {
    if (creatorsSpaceData && creatorsSpaceData?.creatorData?.creatorSpaces?.length > 0 && creatorsSpaceData?.creatorData?.creatorSpaces[0]?.color) {
      console.log('SetSpace Color: ', creatorsSpaceData?.creatorData?.creatorSpaces[0]?.color);
      setSpaceColor(creatorsSpaceData?.creatorData?.creatorSpaces[0].color);
    }
  }, [creatorsSpaceData?.creatorData?.creatorSpaces[0]?.color]);

  useEffect(() => {

    if (creatorsSpaceData?.creatorData) {
      dispatch(getSimilarCreators(creatorsSpaceData?.creatorData.creatorId));
    }

    if (creatorsSpaceData && creatorsSpaceData?.creatorData?.creatorSpaces?.length > 0) {
      const spaceData = creatorsSpaceData?.creatorData?.creatorSpaces[0];

      console.log('SpaceData: ', spaceData);

      async function fetchData() {
        try {
          const spaceAllData = await getSpaceData({ name: spaceData.name });
          console.log('SpaceAllData', spaceAllData);
          setSpaceColor(`#${spaceAllData?.group?.QRColor}` || '#990000');
        } catch (error) {
          console.log('SpaceAllData Error: ', error);
        }
      }
      fetchData();
    }

  }, [creatorsSpaceData?.creatorData?.creatorSpaces[0], dispatch]);

  useEffect(() => {
    const { creatorName } = params;
    if (creatorName) {
      const link = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/${creatorName}`;
      setShareLink(link);
    }
  }, [params]);


  return (
    <>
      <div className="profilecard">
        <div className="gradient" style={{ position: "relative", background: spaceColor }}>
          <div className="mobile mobile_profile_header">
            <div>
              {creatorsSpaceLoading ? (
                <img
                  src={
                    creatorsSpaceData?.creatorData.creatorProfileImage ||
                    avatar_icon
                  }
                  alt=""
                  className="mobile_profile_avatar"
                />
              ) : (
                <SkeletonComponent
                  width="20%"
                  style={{ minWidth: "91px", minHeight: "91px" }}
                  circle
                />
              )}
            </div>
            <div className="profile_info">
              <div className="d-flex" style={{ gap: 5 }}>
                <img
                  src={theme ? dove2 : dove}
                  alt="dove"
                  style={{ width: 20, height: 20 }}
                />
                {creatorsSpaceLoading ? (
                  <label className="font12_6 text-lowercase">
                    {validateUserName(
                      creatorsSpaceData?.creatorData?.creatorHandle || ""
                    )}
                  </label>
                ) : (
                  <SkeletonComponent width={38} height={10} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="profile_person">
          <div className="profile_description">
            <div className="mobile mobile_bio">
              <div className="mobile_bio_header">
                {creatorsSpaceLoading ? (
                  <span className="mobile_bio_name">
                    {creatorsSpaceData?.creatorData.creatorName}
                  </span>
                ) : (
                  <SkeletonComponent width={60} height={14} />
                )}
                <div className="profile_buttons mobile_buttons">
                  <div style={{ paddingRight: 10 }}>
                    <ConnectButton creatorName={params.creatorName} />
                  </div>
                  <div
                    className="d-flex justify-content-end"
                    style={{ background: "#F8F0E3", borderRadius: "20px" }}
                  >
                    <ShareIcon link={shareLink} />
                  </div>
                </div>
              </div>
              <div className="mobile_bio_description">
                {creatorsSpaceLoading ? (
                  <span>{creatorsSpaceData?.creatorData.creatorBio}</span>
                ) : (
                  <SkeletonComponent width="100%" height={10} count={5} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(mapStateToProps)(ProfileCard);
