import ic_confirmed from 'components/assets/ic_confirmed.svg';
import ic_onway from 'components/assets/ic_onway.svg';
import ic_outdelivery from 'components/assets/ic_outdelivery.svg';
import ic_delivered from 'components/assets/ic_delivered.svg';

const OrderTypeBadge = ({ dropType = 0 }) => {

  const contents = [
    {
      icon: ic_confirmed,
      text: 'Confirmed',
      background: '#EB4949',
      color: '#333333'
    },
    {
      icon: ic_onway,
      text: 'On its way',
      background: '#F0E681',
      color: '#fff'
    },
    {
      icon: ic_outdelivery,
      text: 'Out for delivery',
      background: '#40C397',
      color: '#fff'
    },
    {
      icon: ic_delivered,
      text: 'Delivered',
      background: '#3784AB',
      color: '#fff'
    }
  ];

  const content = contents[dropType];

  return <button
    className="cardbtn font12 type_badge"
    style={{ background: content.background, border: "none", }}
  >
    <img src={content.icon} alt="" />
    <span style={{ color: content.color }}>
      {content.text}
    </span>
  </button>
}

export default OrderTypeBadge;