import { useState, useEffect, useRef } from "react";
import PhoneNumber from "./PhoneNumber";
import Timer from "../Author/Timer";
import ReactInputVerificationCode from "react-input-verification-code";
import { usePhoneConfirmation, verifyConfirmationCode } from "actions/firebase";

const Login = ({
  noWrapper,
  step,
  onSuccess,
  onPhonenumberSuccess,
  buttonId,
}) => {
  const submitButtonId = buttonId || "submit-phone-button";
  const [phoneNumber, setPhoneNumber] = useState({
    short: "US",
    code: 1,
    phone: "",
  });
  const [phoneNumberString, setPhoneNumberString] = useState();
  const [isPhoneNumber, setIsPhoneNumber] = useState(true);
  const [isConfirm, setIsConfirm] = useState(false);
  const [footerName, setFooterName] = useState("Continue");
  const [timer, setTimer] = useState("Footer");
  const [confirmCode, setConfirmCode] = useState();
  const [errorClass, setErrorClass] = useState();
  const confirmationResult = usePhoneConfirmation(
    phoneNumberString,
    submitButtonId
  );
  const currentStep = useRef(1);
  const validConfirmationResult = useRef();

  const onChangePhoneNumber = (val) => {
    setPhoneNumber(val);
  };

  const handleChangeHelper = (val) => {
    setConfirmCode(val);
  };

  const handleSendNumber = () => {
    if (phoneNumber.phone) {
      const number = `+${phoneNumber.code}${phoneNumber.phone}`;
      //signIn(number, verifier.current)
      setPhoneNumberString(number);
      setErrorClass("");
    } else {
      setErrorClass("error");
    }
  };

  const handleSendCode = async () => {
    const success = await verifyConfirmationCode(
      validConfirmationResult.current,
      confirmCode
    );
    if (success && onSuccess) {
      currentStep.current++;
      onSuccess();
    }
  };

  useEffect(() => {
    //verifier.current = createCaptcha('submit-phone-button')
    if (confirmationResult) {
      validConfirmationResult.current = confirmationResult;
      setIsPhoneNumber(false);
      setIsConfirm(true);
      onPhonenumberSuccess();
      currentStep.current++;
    }
  }, [confirmationResult]);

  useEffect(() => {
    if (!step) {
      return;
    }

    if (currentStep.current === 1) {
      if (phoneNumber.phone) {
        setErrorClass("");
        handleSendNumber();
      } else {
        setErrorClass("error");
      }
    }
    if (currentStep.current === 2) {
      // emit event for phonenumber virification

      handleSendCode();
    }
  }, [step]);

  return (
    <>
      {isPhoneNumber && (
        <div className="phone">
          {!noWrapper && (
            <div className="checkout_header font16">Join or sign in</div>
          )}
          <div className="checkout_body">
            <PhoneNumber
              className={errorClass}
              onChangeHandler={onChangePhoneNumber}
              isCheckout
              phoneNumber={phoneNumber}
            />
            <div className="font12">
              To keep your information safe and sound, we must verify it's you.
              Don't worry. It's a quick and easy process! Verify your identity
              by entering the code we send to your phone.
            </div>
          </div>
          {/* {!noWrapper && (
            <button
              className="checkout_footer text-center font16"
              onClick={handleSendNumber}
              id={submitButtonId}
            >
              Continue
            </button>
          )} */}
        </div>
      )}
      {isConfirm && (
        <div>
          {/* <div className="checkout_header font16">Enter the code</div> */}
          <div className="checkout_body">
            <div className="font14_w4" style={{ color: "var(--font_color)" }}>
              We just sent SMS with the confirmation code to your mobile number{" "}
              <label style={{ color: "#CCB783" }}>{phoneNumber.phone}</label>
            </div>
            <div className="custom-styles">
              <ReactInputVerificationCode
                autoFocus
                placeholder=""
                length={6}
                onChange={handleChangeHelper}
              />
            </div>
            <div style={{ display: "flex", gap: 15, margin: 15 }}>
              <div
                className="font12"
                style={{
                  cursor: "pointer",
                  color: "rgba(204, 183, 131, 0.4)",
                }}
              >
                Resend
              </div>
              <div className="font12_6" style={{ display: timer }}>
                <Timer initialMinute={1} initialSeconds={59} />
              </div>
            </div>
          </div>
          {/* {!noWrapper && (
            <div
              className="checkout_footer text-center font16"
              onClick={handleSendCode}
            >
              {footerName}
            </div>
          )} */}
        </div>
      )}
    </>
  );
};

export default Login;
