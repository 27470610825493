import { useState } from "react";
import checkmark from "components/assets/icons/checkmark.svg";
import CloseButton from "components/common/CloseButton";

const SelectModal = ({ sizes, onChange, value }) => {
  const [selected, setSelected] = useState(value);
  const handleCloseModal = () => {
    onChange(null);
  };

  const selectOption = (value) => {
    setSelected(value);
    onChange(value);
  };

  return (
    <div className="select-modal">
      <div className="font16 select-modal__header">
        <span>Select size</span>
        <CloseButton onClick={handleCloseModal} className="" />
      </div>
      <div className="font14 select-modal__divider" />
      {!Array.isArray(sizes)
        ? null
        : sizes.map((value) => (
            <div
              className="select-modal__option"
              onClick={() => selectOption(value)}
            >
              <span>{value}</span>
              {selected === value && <img src={checkmark} alt="checkmark" />}
            </div>
          ))}
    </div>
  );
};

export default SelectModal;
