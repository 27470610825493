import { STRIPE_TEST_PUBLIC_KEY, STRIPE_PUBLIC_KEY } from "./constants";
import axios from "axios";
import { format, getWeek, getMonth } from "date-fns";
import { API } from "actions/api";
import { addToCart } from "actions/bag";

export const getStripeKey = () => {
  return getStripeTestMode() ? STRIPE_TEST_PUBLIC_KEY : STRIPE_PUBLIC_KEY;
};

export const getStripeTestMode = () => {
  return Boolean(window.localStorage.stripeTestMode);
};

export const setStripeTestMode = (value) => {
  if (value) {
    window.localStorage.stripeTestMode = "1";
  } else {
    window.localStorage.removeItem("stripeTestMode");
  }
};

export function isNightTime() {
  const now = new Date();
  const hours = now.getHours();
  const sunsetHour = 18;
  const sunriseHour = 6;
  return hours >= sunsetHour || hours < sunriseHour;
}

export const shippingFromForm = (data) => {
  return {
    name: data["fullName"],
    phone: data["phoneNumber"].phone,
    address: {
      city: data["city"],
      state: data["state"],
      country: "US",
      line1: data["address"],
      line2: data["address2"],
      postal_code: data["zip"],
    },
  };
};

export const getCartData = () => {
  let cartData = {};
  try {
    cartData = JSON.parse(window.localStorage.shoppingCart || "{}");
  } catch (e) {}
  cartData = cartData || {};

  if (!cartData.cart) {
    cartData.cart = [];
  }
  cartData.count = countCartItems(cartData);
  return cartData;
};

export const getCartDetails = async (shipping) => {
  let result;
  try {
    const cartData = JSON.parse(localStorage.getItem("bag_data"));
    const { cart } = await addToCart(cartData);
    const res = await axios.post(`${API}/getCartDetails`, {
      data: { cart, shipping },
    });
    result = res;
  } catch (error) {
    console.log("getCartDetails error:", error);
  }
  return result;
};

const countCartItems = (data) => {
  let count = 0;
  if (data && data.cart && data.cart.length) {
    data.cart.forEach((group) =>
      group.items.forEach((item) => (count = count + item.quantity))
    );
  }
  return count;
};

export const getPaymentIntent = async (email, shipping, paymentMethod) => {
  const cartData = JSON.parse(localStorage.getItem("bag_data"));
  const { cart } = await addToCart(cartData);
  const res = await axios.post(`${API}/webPaymentIntent`, {
    data: {
      cart,
      email,
      shipping,
      paymentMethod,
      isTestMode: getStripeTestMode(),
    },
  });
  const { data } = res;
  return data;
};

export const getVariantFromProduct = (variants, size, color) => {
  if (variants.length === 1) {
    return variants[0];
  }

  const variant = variants.find((item) => {
    if (item.options && item.options.length) {
      const option = item.options.find((option) => {
        if (
          option["name"] === "size" &&
          option.value === size &&
          option["name"] === "color" &&
          option.value === color.name
        ) {
          return option;
        }

        if (option["name"] === "size" && option.value === size) {
          return option;
        }
        if (option["name"] === "color" && option.value === color.name) {
          return option;
        }
      });

      if (option) {
        return true;
      }
    }
  });

  console.log("final variant", variant);

  if (!variant) {
    return {};
  }

  return variant;
};

export const updateUserData = (data) => {
  let user = null;
  if (data) {
    user = { ...data };
  }
  const updateEvent = new CustomEvent("userDataUpdated", { detail: user });
  window.dispatchEvent(updateEvent);
};

export const isIOS = () => {
  if (typeof window === `undefined` || typeof window.navigator === `undefined`)
    return false;
  return /iPhone|iPad|iPod/i.test(
    window.navigator.userAgent ||
      window.navigator.vendor ||
      (window.opera && window.opera.toString() === `[object Opera]`)
  );
};

export const getContrastTextColor = (hexColor) => {
  // Convert the hex color to RGB
  const r = parseInt(hexColor.substr(1, 2), 16);
  const g = parseInt(hexColor.substr(3, 2), 16);
  const b = parseInt(hexColor.substr(5, 2), 16);

  // Calculate the relative luminance (brightness) of the color
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Choose text color based on luminance
  if (luminance > 0.5) {
    return "#0C0C0C"; // Dark text color for light backgrounds
  } else {
    return "#ffffff"; // Light text color for dark backgrounds
  }
};

const weekdays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const formatDate = (string, schema = "M/d/yy") => {
  const year = string.substring(0, 4);
  const month = string.substring(4, 6) - 1;
  const day = string.substring(6, 8);
  const date = new Date(year, month, day);

  switch (schema) {
    case "week":
      const weekday = date.getDay(); // 0: Sunday, 1: Monday, ..., 6: Saturday
      return weekdays[weekday];
    case "month":
      const currentMonth = getMonth(date); // Get the month (0: January, 1: February, ..., 11: December)
      const weekNumberInMonth = Math.ceil(
        getWeek(date) - getWeek(new Date(year, currentMonth, 1)) + 1
      );
      return `Week${weekNumberInMonth}`;
    case "year":
      const monthName = months[date.getMonth()];
      return monthName;
    default:
      return format(date, schema);
  }
};

export const transformToDate = (string) => {
  return new Date(
    string.substring(0, 4),
    string.substring(4, 6) - 1,
    string.substring(6, 8)
  );
};

export const setThemeColors = (flag) => {
  if (flag) {
    document.documentElement.style.setProperty("--overlay-color", "#0d0d0d96;");
    document.documentElement.style.setProperty("--background-color", "#0D0D0D");
    document.documentElement.style.setProperty("--font_color", "#fff");
    document.documentElement.style.setProperty(
      "--font_color6",
      "rgba(255, 255, 255,0.6)"
    );
    document.documentElement.style.setProperty("--font_color2", "#3D3D3D");
    document.documentElement.style.setProperty(
      "--btn_color",
      "rgba(255, 255, 255,0.2)"
    );
    document.documentElement.style.setProperty(
      "--cookies_back",
      "rgba(13, 13, 13, 0.7)"
    );
    document.documentElement.style.setProperty(
      "--card_border",
      "rgba(13, 13, 13, 0.7)"
    );
    document.documentElement.style.setProperty("--button_color", "#3d3d3d");
    document.documentElement.style.setProperty("--border_color", "#3d3d3d");
    document.documentElement.style.setProperty(
      "--background-gradient-color",
      "linear-gradient(180deg,hsl(0deg 0% 5.1%),hsl(0deg 0% 9.41%))"
    );
  } else {
    document.documentElement.style.setProperty("--overlay-color", "#f8f0e387;");
    document.documentElement.style.setProperty("--background-color", "#F8F0E3");
    document.documentElement.style.setProperty("--font_color", "#000");
    document.documentElement.style.setProperty(
      "--font_color6",
      "rgba(13, 13, 13, 0.6)"
    );
    document.documentElement.style.setProperty(
      "--font_color2",
      "rgba(13, 13, 13, 0.2)"
    );
    document.documentElement.style.setProperty("--btn_color", "#0D0D0D");
    document.documentElement.style.setProperty(
      "--cookies_back",
      "rgba(248, 240, 227, 0.7)"
    );
    document.documentElement.style.setProperty("--button_color", "#CCB783");
    document.documentElement.style.setProperty("--border_color", "#CCB783");
    document.documentElement.style.setProperty(
      "--background-gradient-color",
      "linear-gradient(180deg,hsl(39.23deg 54.17% 90.59%), hsl(44deg 42.86% 93.14%))"
    );
  }
};

export const formatDateToCustomString = (date) => {
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based in JavaScript
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  // return `${month}/${day}/${year}-${hours}:${minutes}:${seconds}`;
  return `${hours}:${minutes}:${seconds}`;
};

export function formatDateFromTimestamp(timestamp) {
  try {
    // Check if the timestamp object has the property _seconds and it's a number
    if (
      !timestamp.hasOwnProperty("_seconds") ||
      typeof timestamp._seconds !== "number"
    ) {
      throw new Error(
        "Invalid timestamp object: Missing or invalid _seconds property"
      );
    }

    // Create a new Date object from the seconds
    const date = new Date(timestamp._seconds * 1000);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date created from timestamp");
    }

    // Get the day, month, and year from the date
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // +1 because months are 0-indexed
    const year = date.getFullYear();

    // Return the formatted date string
    return `${month}/${day}/${year}`;
  } catch (error) {
    // Handle any errors that might have occurred
    console.error(error.message);
    // Return a default value or rethrow the error depending on your use case
    // For example, return null or a specific error message:
    return null; // or "Invalid date"
    // Or rethrow the error if you want the calling function to handle it:
    // throw error;
  }
}
