import { styled } from "@mui/material/styles";

const StepLine1 = styled("div")(() => ({
  height: "2px",
  width: "305px",
  marginTop: "25px",
  background:
    "linear-gradient(90deg, #4B355A 2.57%, #3784AB 25.48%, #40C397 51.66%, #F0E681 74.93%, #EB4949 99.48%)",
}));

const StepLine2 = styled("div")(() => ({
  height: "2px",
  width: "305px",
  background: "var(--font_color2)",
  marginTop: "25px",
}));

const StepLine = ({ isPass }) => {
  return (
    <>
      {isPass && <StepLine1 />}
      {!isPass && <StepLine2 />}
    </>
  )
}

const DeliverStatusBar = ({ isActive, dropType, date, status }) => {

  return (
    <>
      <div className="delivery">
        <div style={{ width: 55, display: "grid" }}>
          <img src="/images/step1.svg" alt="" />
          <label className="font12">{'Confirmed'}</label>
          <label className="font12" style={{ margin: "auto", display: 'none' }}>{'Jun 3'}</label>
        </div>
        <StepLine isPass={true} />
        <div style={{ width: 55, display: "grid" }}>
          <img src="/images/step2.svg" alt="" />
          <label className="font12" style={{ marginLeft: -5, marginRight: -5 }}>{'On its way'}</label>
          <label className="font12" style={{ margin: "auto", display: 'none' }}>{'Jun 13'}</label>
        </div>
        <StepLine isPass={status} />
        <div style={{ width: 55 }}>
          <img src="/images/step3.svg" alt="" />
          <label className="font12" style={{ marginLeft: -50, marginRight: -50, justifyContent: "center", }}>
            {'Out for delivery'}
          </label>
        </div>
        <StepLine isPass={status} />
        <div>
          <img src="/images/step4.svg" alt="" />
          <label className="font12">{'Delivered'}</label>
        </div>
      </div>
    </>
  )
}

export default DeliverStatusBar;