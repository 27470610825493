import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Avatar, Image, message } from 'antd';

import SkeletonComponent from "components/Skeleton/index";
import ShareIcon from 'components/common/ShareIcon';
import { addConnection, removeConnection } from 'actions/firebase';

function ConnectCard(props) {
    const { user } = { ...props }
    const theme = useSelector(state => state.theme);
    const [showConnect, setShowConnect] = useState(false);
    const [loaded, setLoaded] = useState(true);
    const [isConnected, setIsConnected] = useState(true);

    const handleShowConnect = () => {
        setShowConnect(true)
    }

    const handleHideConnect = () => {
        setShowConnect(false)
    }

    const toggleConnection = async () => {
        const idUser = user.id;
        const origIsConnected = isConnected;
        setIsConnected(!isConnected);
        const result = await (isConnected ? removeConnection({ idUser }) : addConnection({ idUser }));
        if (!result.success) {
            setIsConnected(origIsConnected);
        }
        else {
            message.open({
                content: isConnected ?
                    `You have split from ${user.username}.` :
                    `You are linked with ${user.username}.`
            });
        }
    }

    return (
        <div className="connect pr-2 pl-2"
            style={{ border: showConnect ? '1px solid #EB4949' : '1px solid var(--font_color2)' }}
            onMouseEnter={handleShowConnect}
            onMouseLeave={handleHideConnect}
        >
            <div className='connect_image'>
                {
                    loaded ?
                        <Link to={`/${user.username}`}>
                            <Avatar
                                size={108}
                                className="connectimage"
                                alt={user.username}
                                src={
                                    <Image src={user.imageUrl} preview={false} />
                                }
                            />
                        </Link>
                        :
                        <SkeletonComponent
                            width={108}
                            height={108}
                            circle
                            style={{
                                position: 'absolute',
                                top: "-60px",
                                left: "50%",
                                transform: "translate(-50%, -50%)"
                            }}
                        />
                }
            </div>
            <div style={{ display: 'grid' }}>
                {
                    loaded ?
                        <label className="font16_6" style={{ color: 'var(--font_color)', cursor: 'pointer' }}>{user.name}</label>
                        :
                        <SkeletonComponent
                            width={62}
                            height={16}
                        />
                }
                {
                    loaded ?
                        <label className='font12_6' style={{ cursor: 'pointer' }}>@{user.username}</label>
                        :
                        <SkeletonComponent
                            width={72}
                            height={10}
                        />
                }
            </div>
            <div className="justify-content-center mt-2" style={{ display: showConnect ? 'flex' : 'none', gap: 9 }}>
                <div>
                    <div className='profile_connect'
                        style={{
                            cursor: "pointer",
                            background: isConnected ? '#eb4949' : 'transparent',
                            borderWidth: isConnected ? 0 : 1,
                            color: isConnected ? 'white' : 'var(--font_color)'
                        }}
                        onClick={toggleConnection}
                    >
                        {isConnected ? 'Connected' : 'Link'}
                    </div>
                </div>
                <div>
                    <ShareIcon theme={theme} link={`https://auradrops.com/${user.username}`} />
                </div>
            </div>
        </div>
    )
}

export default ConnectCard; 