import { useMemo } from "react";
import { getDropTypeIcon } from "components/common/getIconsModule"
import moment from "moment";

const TypeBadge = ({ dropType, releaseDts }) => {
  const timestamp = useMemo(
    () => {
      if (releaseDts) {
        return new Date(releaseDts._seconds * 1000 + releaseDts._nanoseconds / 1000000)
      }
    },
    [releaseDts]
  );

  return <button
    className="cardbtn font12 type_badge"
    style={{
      background: getDropTypeIcon(dropType).color,
      border: "none",
    }}
  >
    <img src={getDropTypeIcon(dropType).icon} alt="" />
    <span style={(dropType === 'Presale') ? { color: 'rgba(0,0,0,0.6)' } : ((dropType === 'Now' || dropType === 'Pin') ? { color: '#F8F0E3' } : null)}>
      {dropType || ""}
    </span>
    {dropType === 'Presale' && timestamp &&
      <span style={{ fontWeight: 700, color: 'rgba(0,0,0,0.6)' }}>{moment(timestamp).format('MM/DD/YY')}</span>
    }
  </button>
}

export default TypeBadge;