import { GET_DROP_CALENDAR, GET_DROP_CALENDAR_LOADING, SET_SHOW_MENU } from "actions/types";

const initialState = {
  // dropCalendarData: {
  //   result: {
  //     creatorData: {
  //       creator_handle: "",
  //       creator_name: "",
  //       creator_picture: "",
  //     },
  //     dropData: {
  //       category_name: "",
  //       shipping_price: "",
  //       price: "",
  //       release: "",
  //       returnPolicy: "",
  //       description: "",
  //       address: "",
  //       drop_color: "",
  //       picture: "",
  //     },
  //   },
  // },
  dropCalendarData: undefined,
  loading: true,
  showMenu: true,
};

function dropCalendar(state = initialState, action) {
  switch (action.type) {
    case SET_SHOW_MENU:
      return {
        ...state,
        showMenu: action.payload,
      };
    case GET_DROP_CALENDAR:
      // Here I brought the data to the form that was originally made. Now this modification happens in one place
      // const { result } = action.payload;
      // const updatedCreatorData = {
      //   creator_handle: result?.creatorData?.creatorHandle,
      //   creator_name: result?.creatorData?.creatorName,
      //   creator_picture: result?.creatorData?.creatorProfilePicture,
      // };
      // const updatedDropData = {
      //   category_name: result.dropData.category,
      //   shipping_price: result.dropData.shippingPrice,
      //   price: result.dropData.price,
      //   release: result.dropData.release,
      //   returnPolicy: result.dropData.returnPolicy,
      //   description: result.dropData.description,
      //   address: result.dropData.address,
      //   drop_color: result.dropData.colors.name,
      //   picture: result.dropData.picture,
      // };

      // const data = {
      //   result: {
      //     creatorData: updatedCreatorData,
      //     dropData: updatedDropData,
      //   },
      // };
      return {
        ...state,
        dropCalendarData: action.payload,
      };
    case GET_DROP_CALENDAR_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}

export default dropCalendar;
