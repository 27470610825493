import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { clearCreatorSpaceData } from "actions/creator";
import { clearProductsListBySpace } from "actions/products";
import ProfileCard from "./ProfileCard";
import AvatarGroup from "./AvatarGroup";
import CardGroup from "../Product/CardGroup";
import logo from "components/assets/Aura_logo_gold.svg";
import logoText from "components/assets/Aura_text.svg";
import logoText_Day from "components/assets/Aura_text_day.svg";
import { TrackGoogleAnalyticsEvent } from "utils/googleAnalytics";

function Header({ isEditSpace = false, userName = "", visitorName = "" }) {
  const dispatch = useDispatch();
  const theme = useSelector(state => state.theme);

  TrackGoogleAnalyticsEvent({ category: "Visitors", action: `Visitors_${userName}`, label: visitorName });

  useEffect(() => {
    return () => {
      dispatch(clearCreatorSpaceData());
      dispatch(clearProductsListBySpace());
    };
  }, [dispatch]);

  return (
    <div className="main">
      <div className="main_first_part">
        <div>
          <ProfileCard />
        </div>
        <div>
          <AvatarGroup />
        </div>
        <div style={{ flex: 1, overflow: "auto" }} className="scroll">
          <CardGroup showBuy={true} />
        </div>
      </div>
      {!isEditSpace && <div className="empowered-by">
        <Link to="/">
          <span>
            Empowered by
          </span>
          <img src={logo} /> <img src={theme ? logoText : logoText_Day} />
        </Link>
      </div>}
    </div>
  );
}
export default Header;
