import { useEffect, useState } from 'react';

export const ColorChanger = ({ children }) => {

    const fillColors = [
        '#4B355A', '#3784AB', '#40C397', '#F0E681', '#EB4949'
    ]

    const hexToRgb = (hex) => {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    };

    const interpolateColor = (color1, color2, factor) => {
        const r = color1.r + (color2.r - color1.r) * factor;
        const g = color1.g + (color2.g - color1.g) * factor;
        const b = color1.b + (color2.b - color1.b) * factor;
        return `rgb(${Math.round(r)}, ${Math.round(g)}, ${Math.round(b)})`;
    };

    const [currentColorIndex, setCurrentColorIndex] = useState(0);
    const [fillColor, setFillColor] = useState(fillColors[0]);
    const [interpolationFactor, setInterpolationFactor] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (interpolationFactor < 1) {
                setInterpolationFactor(interpolationFactor + 0.1);
                const color1 = hexToRgb(fillColors[currentColorIndex]);
                const color2 = hexToRgb(fillColors[(currentColorIndex + 1) % fillColors.length]);
                setFillColor(interpolateColor(color1, color2, interpolationFactor));
            } else {
                setCurrentColorIndex((prevIndex) => (prevIndex + 1) % fillColors.length);
                setInterpolationFactor(0);
            }
        }, 100);
        return () => clearInterval(interval);
    }, [currentColorIndex, interpolationFactor]);
    return (
        children(fillColor)
    )
}

export const Logo = ({ fillColor }) => {
    return (
        <svg width="72" height="28" viewBox="0 0 72 28" fill={fillColor} xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M1.16586 14.9362L1.18808 14.9496C-1.72845 20.2287 1.60411 27.8643 8.47864 27.8723L20.3168 27.886V27.8851C26.1822 27.8495 30.8894 21.0492 27.4618 14.8846L21.5991 4.34033C18.6862 -0.998226 10.5606 -1.87312 7.09647 4.32506L1.16586 14.9362ZM21.8447 8.45875C21.6543 0.590346 11.6082 -2.06454 7.80096 4.74741L3.63764 12.1965C3.79806 12.0799 3.96431 11.9669 4.13648 11.8579L6.83445 10.1499L13.2157 6.29628C13.9726 5.83919 14.908 5.83711 15.6668 6.29084L21.8822 10.0074L21.8447 8.45875ZM22.6585 8.43762C22.654 8.25035 22.6444 8.06574 22.63 7.88385L26.7564 15.3054C30.5488 22.1261 23.3058 29.8094 16.6312 26.046L15.3034 25.2973L21.5484 21.5631C22.3072 21.1093 22.7732 20.2694 22.7694 19.3619L22.7377 11.7115L22.7098 10.5594L22.7084 10.5014L22.6585 8.43762ZM14.0415 24.5859L14.041 23.8373C14.0384 19.5843 10.732 16.1319 6.63571 16.0843L6.63571 19.2281C6.63571 19.833 6.94865 20.3917 7.45577 20.6919L14.0415 24.5859ZM6.63571 15.2403V11.2638L7.25181 10.8738L13.6257 7.02458C13.7576 6.94496 13.8975 6.88605 14.0414 6.84786L14.041 7.48728C14.0384 11.7402 10.732 15.1926 6.63571 15.2403ZM21.1414 20.833L14.8558 24.5915L14.8563 23.8373C14.8588 19.6956 17.9946 16.3131 21.9419 16.095L21.9554 19.3655C21.9579 19.9705 21.6473 20.5305 21.1414 20.833ZM21.9383 15.2294C17.9927 15.0095 14.8588 11.6277 14.8563 7.48728L14.8559 6.84939C14.9955 6.88703 15.1315 6.94421 15.2598 7.02095L21.9061 10.9952L21.9238 11.7239L21.9383 15.2294ZM16.7214 27.0388L8.47955 27.0292C0.879904 27.0204 -1.92321 16.6822 4.56101 12.5772L5.8217 11.7791L5.8217 19.2281C5.8217 20.1355 6.29112 20.9734 7.0518 21.4238L13.4647 25.2206L16.2419 26.7865C16.4015 26.8764 16.5614 26.9605 16.7214 27.0388ZM14.4487 21.177C13.6294 18.571 11.6413 16.5114 9.12526 15.6623C11.6413 14.8132 13.6294 12.7536 14.4487 10.1475C15.2679 12.7536 17.256 14.8132 19.7721 15.6623C17.256 16.5114 15.2679 18.571 14.4487 21.177Z"
            />
            <path
                d="M52.9746 19.0249C52.9746 19.4573 52.3486 19.5551 51.3228 19.5551C50.2971 19.5551 49.8957 19.3149 49.8151 18.625C49.3983 19.2668 48.6453 19.6203 47.5544 19.6203C45.9351 19.6203 44.5561 18.5135 44.5561 16.4594V13.7807C44.5561 13.0274 43.9146 12.8335 43.9146 12.4336V12.3702C43.9146 12.1934 44.0586 12.0338 44.2833 12.0012L46.6727 11.6803C47.1376 11.6168 47.4257 11.7129 47.4257 12.0012L47.3777 16.7494C47.3623 18.0656 47.6984 18.5615 48.4034 18.5615C48.9969 18.5615 49.4617 17.9352 49.4617 16.9571V13.7807C49.4617 13.0274 48.8202 12.8335 48.8202 12.4336V12.3702C48.8202 12.1934 48.9643 12.0338 49.189 12.0012L51.5784 11.6803C52.0432 11.6168 52.2834 11.7129 52.2834 12.0012V17.6932C52.2834 18.9923 52.9729 18.3676 52.9729 19.0249H52.9746Z"
            />
            <path
                d="M60.259 13.2491C60.259 14.0505 59.9537 14.7575 59.024 14.7575C57.5643 14.7575 58.223 13.2491 57.4203 13.2491C56.9074 13.2491 56.8268 14.0831 56.8268 14.7884V17.7725C56.8268 18.5104 57.6758 18.7026 57.6758 19.1522V19.2157C57.6758 19.4079 57.5163 19.5212 57.2916 19.5212H53.8439C53.6192 19.5212 53.4597 19.4096 53.4597 19.2157V19.1522C53.4597 18.7026 54.1012 18.5104 54.1012 17.7725V14.5807C54.1012 13.8274 53.3311 13.7622 53.3311 13.2972V13.2491C53.3311 13.0569 53.4597 12.9128 53.6998 12.7841L55.9606 11.7253C56.3774 11.5331 56.5386 11.7579 56.5386 12.0788V13.2491C56.892 12.2229 57.645 11.6601 58.7513 11.6601C59.6981 11.6601 60.259 12.3499 60.259 13.2491Z"
            />
            <path
                d="M68.997 19.028C68.997 19.445 68.0673 19.5582 67.1857 19.5582C66.2234 19.5582 65.7586 19.366 65.6299 18.7877C65.2937 19.2528 64.6522 19.6217 63.5939 19.6217C61.8786 19.6217 60.563 18.8358 60.563 17.1833C60.563 15.5307 61.9266 14.7294 63.4498 14.7294C64.1239 14.7294 64.6694 14.889 65.069 15.1309V15.0829C64.8769 13.1901 64.7637 12.3716 63.9301 12.3716C62.8237 12.3716 63.2731 14.5217 61.846 14.5217C61.1719 14.5217 60.7242 14.1047 60.7242 13.4784C60.7242 12.1622 62.2954 11.6011 63.9318 11.6011C66.081 11.6011 67.5716 12.852 67.8289 14.8426L68.1822 17.7135C68.3263 18.8529 69.0004 18.5801 69.0004 19.0297L68.997 19.028ZM65.3092 17.6483L65.2457 16.9104C65.1342 15.9477 64.7157 15.5788 64.2508 15.5788C63.786 15.5788 63.4326 16.0112 63.4326 16.6702C63.4326 17.8096 63.8169 18.8032 64.4755 18.8032C65.0055 18.8032 65.3092 18.194 65.3092 17.6483Z"
            />
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M33.3174 19.2537V19.3171C33.3174 19.5093 33.4615 19.6226 33.7016 19.6226H37.6159C37.8406 19.6226 37.9846 19.5111 37.9846 19.3171V19.2537C37.9846 18.931 37.4975 18.7423 37.3174 18.3871C37.1973 18.152 37.3792 17.874 37.6433 17.874H40.5267C40.7908 17.874 40.9727 18.152 40.8526 18.3871C40.6725 18.7406 40.1853 18.931 40.1853 19.2519V19.3154C40.1853 19.5076 40.3294 19.6209 40.5696 19.6209H44.3809C44.621 19.6209 44.7651 19.5093 44.7651 19.3154V19.2519C44.7651 18.8023 44.1716 18.7234 43.8354 17.8723C43.8354 17.8723 40.5764 9.30249 40.4409 9.00047C40.4409 9.00047 40.1699 8.22998 39.5781 8.22998H38.8886C38.3277 8.22998 38.127 8.47022 37.9023 9.00047L34.2471 17.874C33.9109 18.708 33.3174 18.8041 33.3174 19.2537ZM38.3373 14.8024C38.3373 15.2963 37.135 15.4573 37.135 15.9952H37.1332C37.1332 16.2531 37.4811 16.4196 37.86 16.4196H40.2227C40.6016 16.4196 40.9496 16.2531 40.9496 15.9952C40.9496 15.4555 39.7473 15.2963 39.7473 14.8024C39.7473 14.3084 40.5434 14.3578 40.5434 13.7468C40.5434 13.2345 39.5997 13.4614 39.5997 12.9162C39.5997 12.693 39.658 12.5357 39.658 12.3747C39.658 12.0015 39.4358 11.7417 39.0423 11.7417C38.6488 11.7417 38.4266 12.0015 38.4266 12.3747C38.4266 12.5357 38.4849 12.693 38.4849 12.9162C38.4849 13.4614 37.5412 13.2345 37.5412 13.7468C37.5412 14.3578 38.3373 14.3084 38.3373 14.8024Z"
            />
            <path
                d="M71.4945 18.3656C71.2944 18.5658 71.0533 18.6667 70.7712 18.6667C70.489 18.6667 70.2462 18.5658 70.0444 18.3656C69.8426 18.1655 69.7417 17.9225 69.7417 17.6402C69.7417 17.358 69.8426 17.1167 70.0444 16.9166C70.2462 16.7164 70.4873 16.6172 70.7712 16.6172C71.055 16.6172 71.2944 16.7164 71.4945 16.9166C71.6946 17.1167 71.7938 17.358 71.7938 17.6402C71.7938 17.9225 71.6946 18.1655 71.4945 18.3656ZM70.7712 18.4905C70.9935 18.4905 71.1833 18.4067 71.3406 18.239C71.4996 18.0714 71.5783 17.8712 71.5783 17.6402C71.5783 17.4093 71.4996 17.2091 71.3406 17.0415C71.1816 16.8755 70.9918 16.7917 70.7712 16.7917C70.5506 16.7917 70.3573 16.8755 70.1983 17.0415C70.0393 17.2074 69.9606 17.4076 69.9606 17.6402C69.9606 17.8729 70.0393 18.0765 70.1966 18.2425C70.3539 18.4084 70.5454 18.4922 70.7712 18.4922V18.4905ZM70.7814 18.2117C70.6173 18.2117 70.4787 18.1535 70.3642 18.0389C70.2513 17.9242 70.1932 17.7874 70.1932 17.6317C70.1932 17.476 70.2496 17.334 70.3607 17.2262C70.4719 17.1185 70.6138 17.0654 70.7848 17.0671C70.9216 17.0688 71.0396 17.1031 71.1388 17.1698C71.238 17.2365 71.2876 17.3169 71.2876 17.4093C71.2876 17.4435 71.2739 17.4726 71.2466 17.4948C71.2192 17.5188 71.1816 17.5308 71.1354 17.5308C71.0944 17.5308 71.0602 17.5188 71.0311 17.4931C71.002 17.4692 70.9798 17.4401 70.9644 17.4059C70.949 17.3717 70.9319 17.3374 70.9165 17.3032C70.9011 17.269 70.8789 17.2382 70.8515 17.2143C70.8242 17.1903 70.7917 17.1766 70.7523 17.1766C70.6805 17.1766 70.6275 17.2074 70.5916 17.2673C70.5557 17.3289 70.5369 17.4093 70.5369 17.5085C70.5369 17.6351 70.5694 17.7378 70.6326 17.8165C70.6976 17.8952 70.79 17.9345 70.9097 17.9345C71.0294 17.9345 71.1303 17.8815 71.1935 17.7737C71.2072 17.7549 71.226 17.7515 71.2517 17.7617C71.2773 17.772 71.2893 17.7891 71.2876 17.8148C71.279 17.9277 71.2295 18.0218 71.1388 18.0953C71.0482 18.1689 70.9285 18.2065 70.7797 18.2065L70.7814 18.2117Z"
            />
        </svg>
    )
};

export const LogoNoTitle = ({ fillColor }) => {
    return (
        <svg width="30" height="28" viewBox="0 0 28 28" fill={fillColor} xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M1.16586 14.9362L1.18808 14.9496C-1.72845 20.2287 1.60411 27.8643 8.47864 27.8723L20.3168 27.886V27.8851C26.1822 27.8495 30.8894 21.0492 27.4618 14.8846L21.5991 4.34033C18.6862 -0.998226 10.5606 -1.87312 7.09647 4.32506L1.16586 14.9362ZM21.8447 8.45875C21.6543 0.590346 11.6082 -2.06454 7.80096 4.74741L3.63764 12.1965C3.79806 12.0799 3.96431 11.9669 4.13648 11.8579L6.83445 10.1499L13.2157 6.29628C13.9726 5.83919 14.908 5.83711 15.6668 6.29084L21.8822 10.0074L21.8447 8.45875ZM22.6585 8.43762C22.654 8.25035 22.6444 8.06574 22.63 7.88385L26.7564 15.3054C30.5488 22.1261 23.3058 29.8094 16.6312 26.046L15.3034 25.2973L21.5484 21.5631C22.3072 21.1093 22.7732 20.2694 22.7694 19.3619L22.7377 11.7115L22.7098 10.5594L22.7084 10.5014L22.6585 8.43762ZM14.0415 24.5859L14.041 23.8373C14.0384 19.5843 10.732 16.1319 6.63571 16.0843L6.63571 19.2281C6.63571 19.833 6.94865 20.3917 7.45577 20.6919L14.0415 24.5859ZM6.63571 15.2403V11.2638L7.25181 10.8738L13.6257 7.02458C13.7576 6.94496 13.8975 6.88605 14.0414 6.84786L14.041 7.48728C14.0384 11.7402 10.732 15.1926 6.63571 15.2403ZM21.1414 20.833L14.8558 24.5915L14.8563 23.8373C14.8588 19.6956 17.9946 16.3131 21.9419 16.095L21.9554 19.3655C21.9579 19.9705 21.6473 20.5305 21.1414 20.833ZM21.9383 15.2294C17.9927 15.0095 14.8588 11.6277 14.8563 7.48728L14.8559 6.84939C14.9955 6.88703 15.1315 6.94421 15.2598 7.02095L21.9061 10.9952L21.9238 11.7239L21.9383 15.2294ZM16.7214 27.0388L8.47955 27.0292C0.879904 27.0204 -1.92321 16.6822 4.56101 12.5772L5.8217 11.7791L5.8217 19.2281C5.8217 20.1355 6.29112 20.9734 7.0518 21.4238L13.4647 25.2206L16.2419 26.7865C16.4015 26.8764 16.5614 26.9605 16.7214 27.0388ZM14.4487 21.177C13.6294 18.571 11.6413 16.5114 9.12526 15.6623C11.6413 14.8132 13.6294 12.7536 14.4487 10.1475C15.2679 12.7536 17.256 14.8132 19.7721 15.6623C17.256 16.5114 15.2679 18.571 14.4487 21.177Z"
            />
        </svg>
    );
}