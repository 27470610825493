import Modal from "react-bootstrap/Modal";
import React from "react";
import failed from "components/assets/unsuccess.png";
import CloseButton from "components/common/CloseButton";
import "./checkout.css";

function Failed(props) {

  return (
    <Modal
      {...props}
      // show={showCheckout}
      size="sm"
      centered
    >
      <Modal.Body>
        <CloseButton className="close_login" onClick={()=>props.onHide(false)} />
        <div>
          <img
            className="d-flex"
            style={{ margin: "auto" }}
            src={failed}
            alt=""
          />
          <div className="font20 p-4 text-center">
            <label>Transaction Unsuccessful</label>
          </div>
          <div
            className="success_footer text-center font16"
            // onClick={closeModal}
            style={{ cursor: "pointer" }}
          >
            Try again
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Failed;
