import { useState, useCallback, useEffect } from "react";
import { addDays } from "date-fns";
import { Line, Bar, Pie } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

import {
  getAccessToken2,
  getSpaceDataByUserName,
  useUserId,
} from "actions/firebase";
import { useQueryReport } from "hooks/useQueryReport";
import { formatDate } from "utils/utils";
import "./index.css";
import Empty from "./Empty";
import Colors from "res/colors";
import Constants from "res/constants";
import { useLocation } from "react-router-dom";
import { Col, Row } from "reactstrap";

Chart.register(...registerables);

const PerformanceMain = () => {
  const INITIAL_STATE = {
    labels: [],
    values: [],
  };

  const [reportData_PageView, setPageViewReportData] = useState(INITIAL_STATE);
  const [reportData_Viewer, setViewerReportData] = useState(INITIAL_STATE);
  const [reportData_Sales, setSalesReportData] = useState(INITIAL_STATE);
  const [reportData_Device, setDeviceReportData] = useState(INITIAL_STATE);
  const [tabIndex, setTabIndex] = useState(0);

  const [startDate, setStartDate] = useState(addDays(new Date(), -7));
  const [endDate, setEndDate] = useState(new Date());
  const [token, setToken] = useState("");
  const { fetchData } = useQueryReport();
  const [isEmpty, setEmpty] = useState(-1);
  const [userName, setUserName] = useState("");

  const { pathname } = useLocation();

  useEffect(() => {
    const funtion = async () => {
      try {
        const name = pathname.split("/")[2];
        console.log(name);
        if (name) {
          setUserName(name);
        }
      } catch (error) {
        console.error("Error signing in with custom token:", error);
      }
    };
    funtion();
  }, []);

  useEffect(() => {
    if (userName) {
      getSpaceDataByUserName(userName)
        .then((result) => {
          console.log("Result: ", result);
          setTotalValues((prev) => ({
            ...prev,
            drops: result?.creatorData?.creatorDropsCount,
            connections: result?.creatorData?.creatorConnectionCount,
          }));
        })
        .catch((e) => {
          console.log("Error: ", e);
        });
    }
  }, [userName]);

  const [totalValues, setTotalValues] = useState({
    sales: 0,
    visitors: 0,
    pageViews: 0,
    drops: 0,
    connections: 0,
  });

  const days = [
    Constants.Performance.weekdays,
    Constants.Performance.weeks,
    Constants.Performance.months,
  ];

  const displayResults = useCallback(({ setData, response, tabID, key }) => {
    const queryResult = response.rows || [];
    let labels = [];
    let values = [];

    days[tabID].forEach((val) => {
      let value = 0;
      labels.push(val);
      values.push(0);
      queryResult.forEach((row) => {
        let label = formatDate(
          row.dimensionValues[0].value,
          Constants.Performance.dateSchema[tabID]
        );

        {
          if (label == val) {
            value += parseInt([row.metricValues[0].value]);
            values[values.length - 1] = value.toString();
          } else {
            value = 0;
          }
        }
      });
    });

    if (labels.length > 0 && values.length > 0) setEmpty(0);
    setData((prev) => ({ ...prev, labels, values }));
    let totalValue = 0;
    try {
      totalValue = response?.totals[0]?.metricValues[0]?.value;
    } catch (error) {
      totalValue = 0;
    }
    setTotalValues((prev) => ({ ...prev, [key]: totalValue }));
  }, []);

  const displayResults_Sales = useCallback(
    ({ setData, response, tabID, key }) => {
      const queryResult = response.rows || [];
      let labels = [];
      let values = [];
      let total = 0;

      days[tabID].forEach((val) => {
        let value = 0;
        labels.push(val);
        values.push(0);

        queryResult.forEach((row) => {
          let label = formatDate(
            row.dimensionValues[0].value,
            Constants.Performance.dateSchema[tabID]
          );

          {
            if (label == val) {
              const price = parseInt(
                row.dimensionValues[1].value.split("-").slice(-1)[0]
              );
              value += parseInt([row.metricValues[0].value]) * price;
              total += parseInt([row.metricValues[0].value]) * price;
              values[values.length - 1] = value.toString();
            } else {
              value = 0;
            }
          }
        });
      });

      if (labels.length > 0 && values.length > 0) setEmpty(0);
      setData((prev) => ({ ...prev, labels, values }));
      setTotalValues((prev) => ({ ...prev, [key]: total.toString() }));
    },
    []
  );

  const displayResults_Devices = useCallback((response) => {
    const queryResult = response.rows;
    let labels = [];
    let values = [];
    let bgColors = [];
    queryResult.forEach((row, id) => {
      if (row.dimensionValues[0].value === "(not set)") return;
      labels.push(row.dimensionValues[0].value);
      values.push(row.metricValues[0].value);
      bgColors.push(Colors.listColors[id]);
    });
    setDeviceReportData((prev) => ({
      ...prev,
      labels,
      values,
      colors: bgColors,
    }));
  }, []);

  useEffect(() => {
    getAccessToken2()
      .then((result) => {
        setToken(result);
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
  }, []);

  useEffect(() => {
    if (!token) return;
    // const request_User = {
    //     dateRanges: [{
    //         startDate: startDate,
    //         endDate: endDate
    //     }],
    //     dimensions: [{ name: "date" }],
    //     metrics: [{ name: "activeUsers" }],
    //     orderBys: [{ dimension: { orderType: "NUMERIC", dimensionName: "date" } }],
    //     token: token
    // };

    const request_PageView = {
      dateRanges: [
        {
          startDate: startDate,
          endDate: endDate,
        },
      ],
      dimensions: [{ name: "date" }, { name: "eventName" }],
      metrics: [{ name: "eventCount" }],
      orderBys: [
        { dimension: { orderType: "NUMERIC", dimensionName: "date" } },
      ],
      dimensionFilter: {
        filter: {
          fieldName: "eventName",
          stringFilter: {
            matchType: "CONTAINS",
            caseSensitive: false,
            value: `Visitors_${userName}`,
          },
        },
      },
      token: token,
    };

    const request_Sales = {
      dateRanges: [
        {
          startDate: startDate,
          endDate: endDate,
        },
      ],
      dimensions: [{ name: "date" }, { name: "eventName" }],
      metrics: [{ name: "eventCount" }],
      orderBys: [
        { dimension: { orderType: "NUMERIC", dimensionName: "date" } },
      ],
      dimensionFilter: {
        filter: {
          fieldName: "eventName",
          stringFilter: {
            matchType: "CONTAINS",
            caseSensitive: false,
            value: `Sales-${userName}`,
          },
        },
      },
      token: token,
    };

    // const request_Devices = {
    //     dateRanges: [{
    //         startDate: startDate,
    //         endDate: endDate
    //     }],
    //     dimensions: [{ name: "browser" }],
    //     metrics: [{ name: "activeUsers" }],
    //     token: token
    // };

    // fetchData(request_User)
    //     .then((resp) => {
    //         displayResults({ setData: setViewerReportData, response: resp, tabID: tabIndex, key: 'visitors' });
    //         console.log('Result_User: ', resp);
    //     })
    //     .catch((error) => console.error(error));

    fetchData(request_PageView)
      .then((resp) => {
        displayResults({
          setData: setPageViewReportData,
          response: resp,
          tabID: tabIndex,
          key: "pageViews",
        });
        console.log("Result_PageView: ", resp);
      })
      .catch((error) => console.error(error));

    fetchData(request_Sales)
      .then((resp) => {
        displayResults_Sales({
          setData: setSalesReportData,
          response: resp,
          tabID: tabIndex,
          key: "sales",
        });
        console.log("Result_Sales: ", resp);
      })
      .catch((error) => console.error(error));

    // fetchData(request_Devices)
    //     .then((resp) => {
    //         displayResults_Devices(resp);
    //         console.log('Result_Devices: ', resp);
    //     })
    //     .catch((error) => console.error(error));
  }, [token, tabIndex, fetchData]);

  const defaultDataSet = {
    fill: true,
    lineTension: 0.3,
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: Colors.pointHoverBackgroundColor,
    pointHoverBorderColor: Colors.pointHoverBorderColor,
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
  };

  const data_sales = {
    labels: reportData_Sales.labels,
    datasets: [
      {
        ...defaultDataSet,
        label: `Sales`,
        data: reportData_Sales.values,
        backgroundColor: Colors.salesColor90,
        borderColor: Colors.salesColor70,
      },
    ],
  };

  const data_visitors = {
    labels: reportData_Viewer.labels,
    datasets: [
      {
        ...defaultDataSet,
        label: `Visitors`,
        data: reportData_Viewer.values,
        backgroundColor: Colors.visitorColor90,
        borderColor: Colors.visitorColor70,
      },
    ],
  };

  const data_pageViews = {
    labels: reportData_PageView.labels,
    datasets: [
      {
        ...defaultDataSet,
        label: `Views`,
        data: reportData_PageView.values,
        backgroundColor: Colors.pageViewColor90,
        borderColor: Colors.pageViewColor70,
      },
    ],
  };

  const data_userperdevices = {
    labels: reportData_Device.labels,
    datasets: [
      {
        label: `Users Per Devices`,
        data: reportData_Device.values,
        backgroundColor: Colors.listColors,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        ticks: { color: Colors.gray60 },
        grid: { color: Colors.gray60 },
      },
      y: {
        ticks: {
          color: Colors.gray60,
          beginAtZero: true,
        },
        grid: { color: Colors.gray60 },
      },
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        onClick: function () {},
      },
    },
  };

  const options_sales = {
    scales: {
      x: {
        ticks: { color: Colors.gray60 },
        grid: { color: Colors.gray60 },
      },
      y: {
        ticks: {
          color: Colors.gray60,
          beginAtZero: true,
          callback: function (value, index, values) {
            if (value < 0) return value;
            return "$" + value;
          },
        },
        min: 0,
        max: 1000,
        suggestedMin: 0,
        grid: { color: Colors.gray60 },
      },
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        onClick: function () {},
      },
    },
  };

  const options_pie = {
    legend: { display: true, position: "bottom" },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        font: {
          size: 0,
        },
      },
    },
  };

  const TopElement = ({ color, label, value }) => {
    return (
      <Col className="top_element" style={{ borderColor: color }}>
        <Row>
          <label className="top_value ml-3 mt-3">{value}</label>
        </Row>
        <Row className="py-1">
          <label className="top_label mb-3 ml-3">{label}</label>
        </Row>
      </Col>
    );
  };

  //   const [isFlipped, setIsFlipped] = useState(false);

  //   const flipCard = () => {
  //     setIsFlipped(!isFlipped);
  //   };

  return (
    <>
      {/* <div class="card-container">
        <div
          className={`card ${isFlipped ? "flipped" : ""}`}
          onClick={flipCard}
        >
          <div class="card-front">
            <img
              src="./img/cool-background.png"
              alt="background"
              class="card-img"
            />
            <ul class="card-text">
              <li>React | Redux</li>
              <li>NodeJS</li>
              <li>JavaScript</li>
            </ul>
          </div>
          <div class="card-back">
            <ul class="card-text">
              <li>hello</li>
              <li>twice</li>
            </ul>
          </div>
        </div>
      </div> */}
      <div style={{ overflowX: "hidden", height: '100%' }}>
        <div className="tab_container">
          <div className="tab_button_container">
            <div
              className={`tab_button button_text ${
                tabIndex === 0 ? "isActiveButton" : ""
              }`}
              onClick={() => {
                setStartDate(addDays(new Date(), -7));
                setTabIndex(0);
              }}
            >
              WTD
            </div>
          </div>
          <div className="tab_button_container">
            <div
              className={`tab_button button_text ${
                tabIndex === 1 ? "isActiveButton" : ""
              }`}
              onClick={() => {
                setStartDate(addDays(new Date(), -30));
                setTabIndex(1);
              }}
            >
              MTD
            </div>
          </div>
          <div className="tab_button_container">
            <div
              className={`tab_button button_text ${
                tabIndex === 2 ? "isActiveButton" : ""
              }`}
              onClick={() => {
                setStartDate(addDays(new Date(), -365));
                setTabIndex(2);
              }}
            >
              YTD
            </div>
          </div>
        </div>

        <div className="pt-4">
          <Col>
            <Row className="py-2">
              <Col className="px-2">
                <TopElement
                  label={"Connections"}
                  value={totalValues.connections}
                  color={Colors.connectionsColor}
                />
              </Col>
              <Col className="px-2">
                <TopElement
                  label={"Drops"}
                  value={totalValues.drops}
                  color={Colors.dropsColor}
                />
              </Col>
            </Row>
            <Row className="py-2">
              <Col className="px-2">
                <TopElement
                  label={"Sales"}
                  value={`$${totalValues.sales}`}
                  color={Colors.salesColor}
                />
              </Col>
              <Col className="px-2">
                <TopElement
                  label={"Views"}
                  value={totalValues.pageViews}
                  color={Colors.viewsColor}
                />
              </Col>
            </Row>
          </Col>
        </div>

        {isEmpty == 1 && <Empty />}
        <div style={{}}>
          {isEmpty == 0 && (
            <div className="chart_container">
              <Line
                data={data_sales}
                options={options_sales}
                width={100}
                height={250}
              />
            </div>
          )}
          {isEmpty == 0 && (
            <div className="chart_container">
              <Line
                data={data_pageViews}
                options={options}
                width={100}
                height={250}
              />
            </div>
          )}
          {/* {isEmpty == 0 && <div className="chart_container">
                        <Line data={data_visitors} options={options} width={100} height={250} />
                    </div>} */}
          {/* {isEmpty == 0 && <div className="chart_container">
                        <Pie data={data_userperdevices} options={options_pie} width={100} height={250} />
                    </div>} */}
        </div>
      </div>
    </>
  );
};

export default PerformanceMain;
