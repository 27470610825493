import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import _ from "lodash";
import { getProductsListBySpace } from "actions/products";
import SkeletonComponent from "components/Skeleton/index";
import space_icon from "components/assets/icons/empty_space_icon.svg";

import "swiper/css";
import "./css/main.css";

const mapStateToProps = (state) => ({
  creatorsSpaceLoading: state.creators.creatorsSpaceLoading,
  creatorsSpaceData: state.creators.creatorsSpaceData,
});

function AvatarGroup({ creatorsSpaceLoading, creatorsSpaceData }) {
  const dispatch = useDispatch();
  const [activeId, setActiveId] = useState("");
  const handleChangeActiveSpace = (spaceId) => {
    dispatch(getProductsListBySpace(spaceId));
    setActiveId(spaceId);
  };

  useEffect(() => {
    if (creatorsSpaceData?.creatorData?.creatorSpaces?.length) {
      dispatch(
        getProductsListBySpace(
          creatorsSpaceData?.creatorData?.creatorSpaces[0]?.id || "0"
        )
      );
      setActiveId(creatorsSpaceData?.creatorData?.creatorSpaces[0]?.id || "0");
    }
  }, [creatorsSpaceData, dispatch]);

  return (
    <div className="avatar_group p-2">
      {!_.isEmpty(creatorsSpaceData?.creatorData?.creatorSpaces) &&
        creatorsSpaceData?.creatorData.creatorSpaces.map((space) => (
          <div style={{ cursor: "pointer" }} key={space.id} data-id={space.id}>
            <div>
              <img
                src={space.image || space_icon}
                alt="icon"
                style={{ borderColor: space?.color ? space?.color : '#990000' }}
                className={`avatar_item avatar_author ${activeId === space.id ? "avatar_author_active_tab" : ""
                  }`}
                onClick={() => handleChangeActiveSpace(space.id)}
              />
            </div>
            <div>{space.name}</div>
          </div>
        ))}
      <div>
        {creatorsSpaceLoading ? null : (
          <SkeletonComponent width={72} height={72} circle />
        )}
        {creatorsSpaceLoading ? null : (
          <SkeletonComponent width={70} height={10} />
        )}
      </div>
    </div>
  );
}
export default connect(mapStateToProps)(AvatarGroup);
