import { useEffect, useState } from "react";
import GlobeMap from "./Globemap";
import Map from "./Map";
import Marker from './Marker';
import LocalMapModal from 'components/Notification/LocalMapModal';
import youImage from "components/assets/map_you.svg";
import './globe.css';
import { useSelector } from "react-redux";
import CategoryBadge from "components/Product/CategoryBadge";
import TypeBadge from "components/Product/TypeBadge";

const GlobalView = () => {
    const [center, setCenter] = useState([0, 0]);
    const [showModal, setShowModal] = useState(true);
    const [isGlobal, setIsGlobal] = useState(true);
    const [location, setLocation] = useState(null);
    const theme = useSelector(state => state.theme);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                });
            });
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    }, []);

    return (
        <div className='map_container'>
            <div style={{ border: 'solid 0px red', flexDirection: 'column', display: 'flex', overflow: 'hidden', alignItems: 'center' }}>
                <div className='w-100' style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                    {/* <span style={{ fontFamily: 'Recoleta-SemiBold', fontWeight: 600, fontSize: '16px', color: 'var(--font_color)' }}>{'Pin'}</span> */}
                    <TypeBadge dropType={'Pin'} />

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/* <span style={{ fontFamily: 'Recoleta', fontWeight: 400, fontSize: '12px', marginLeft: '20px', color: 'var(--font_color2)', marginRight: '10px' }}>{'78 drops'}</span> */}
                        <div className={isGlobal ? 'map_button' : 'map_button_active'} onClick={() => { setIsGlobal(false) }}>
                            <span style={{ fontFamily: 'Vulf Sans Demo', fontWeight: 400, fontSize: '14px', color: 'var(--font_color)' }}>{'My Location'}</span>
                        </div>
                        <div className={isGlobal ? 'map_button_active' : 'map_button'} style={{ borderRadius: '0 4px 4px 0' }} onClick={() => { setIsGlobal(true) }}>
                            <span style={{ fontFamily: 'Vulf Sans Demo', fontWeight: 400, fontSize: '14px', color: 'var(--font_color)' }}>{'Globe'}</span>
                        </div>
                    </div>
                </div>
                <div style={{ paddingTop: '70px' }}>
                    <GlobeMap center={center} />
                    {/* {isGlobal && <GlobeMap center={center} />} */}
                    {/* {!isGlobal &&
                        <div style={{border: 'solid red 1px', width: '500px', height: '500px'}}>
                            <Map
                                id="map2"
                                center={location}
                                zoom={4}>
                                <Marker
                                    position={location}
                                    userImageURL={youImage}
                                    isYou
                                    onSelect={() => { console.log('Clicked marker!') }}
                                />
                            </Map>
                        </div>} */}
                </div>
                <LocalMapModal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                />
            </div>
        </div>
    )
}

export default GlobalView;
