import { useCallback } from "react";
import { format } from "date-fns";

export function useQueryReport() {

  const fetchData = useCallback(
    async (props) => {

      const resp = await fetch(
        `https://analyticsdata.googleapis.com/v1beta/properties/242656208:runReport`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${props?.token}`,
          },
          body: JSON.stringify({
            dateRanges: props?.dateRanges,
            dateRanges: [
              {
                startDate: format(new Date(props?.dateRanges[0].startDate), "yyyy-MM-dd"),
                endDate: format(new Date(props?.dateRanges[0].endDate), "yyyy-MM-dd"),
              },
            ],
            metrics: props?.metrics,
            dimensions: props?.dimensions,
            orderBys: props?.orderBys || [],
            metricAggregations: ["TOTAL"],
            dimensionFilter: props?.dimensionFilter
          }),
        }
      );
      const data = await resp.json();

      if (data.error) {
        return data.error;
      }
      return data;
    },
    []
  );

  return { fetchData };
}
