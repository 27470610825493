import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import CloseButton from "components/common/CloseButton";
import './noti.css';

import dark404 from 'components/assets/pages/map/map_modal_icon_dark.svg';
import light404 from 'components/assets/pages/map/map_modal_icon.svg';

function LocalMapModal(props) {
    const theme = useSelector(state => state.theme);
    const img_404 = theme ? dark404 : light404;

    const handleNoti = () => {
        props.onHide(false);
    }

    const handleClose = () => {
        props.onHide(false);
    }

    return (
        <>
            <Modal {...props} size="md" centered>
                <Modal.Body className='noti_part m-2' style={{ backgroundColor: 'var(--background-color)' }}>
                    <CloseButton onClick={handleClose} />
                    <div className='noti_header font18'>
                        <label className='mb-2 mt-3 recoleta-semibold' style={{ color: 'var(--font_color)', width: '100%', textAlign: 'center' }}>{'LOCAL DROPS 🗺️'}</label>
                        <div className='image_container'>
                            <img src={img_404} className='map_img' />
                        </div>
                        <div className='font14_w4 text-center' style={{ color: 'var(--font_color)', width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <div style={{ maxWidth: '300px', lineHeight: '22px' }}>
                                <span>{"Exciting news! IRL local drops are joining Aura. Want to showcase your local releases on our map and manage them efficiently?"}</span>
                            </div>
                        </div>
                    </div>
                    <div className='noti_footer text-center font16' onClick={handleNoti}>
                        <a style={{ color: 'white' }} href="mailto:love@auradrops.com?subject=I want to know more about your in-person local drops!">
                            Get on the map
                        </a></div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default LocalMapModal;
