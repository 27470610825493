import { useEffect, useState } from "react";
import { useUserData } from "actions/firebase";
import { getConnectionStatus } from "actions/firebase";

const userConnections = {};
const connectionStatusUpdateEventName = 'connectionStatusUpdateEvent.';

export const updateConnectionStatus = (creatorName, isConnected) => {
  if (userConnections[creatorName] !== isConnected) {
    userConnections[creatorName] = isConnected;
    const updateEvent = new CustomEvent(connectionStatusUpdateEventName + creatorName, { detail: {isConnected} });
    window.dispatchEvent(updateEvent);
  }
}

export const useConnectionStatus = creatorName => {
  const userData = useUserData();
  const [ connectionStatus, setConnectionStatus ] = useState(Boolean(userConnections[creatorName]));

  useEffect(() => {
    const handleUpdate = e => {
      setConnectionStatus(Boolean(e.detail?.isConnected));
    }
    window.addEventListener(connectionStatusUpdateEventName + creatorName, handleUpdate);

    return () => {
      window.removeEventListener(connectionStatusUpdateEventName + creatorName, handleUpdate);
    }
  }, [])

  useEffect(() => {
    const username = creatorName;
    if (username && userConnections[username] === undefined) {
      userConnections[username] = null;
      getConnectionStatus({username}).then(connection =>
        updateConnectionStatus(username, connection.isConnected)
      )
    }
  }, [creatorName, userData])

  return connectionStatus;
}
