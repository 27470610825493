import { useEffect, useState } from "react";

import Loader from "components/common/loader";
import ConnectCard from "components/Profile/Connected/connect";
import spotify from "components/assets/spotify.png";
import { getConnections } from "actions/firebase";

import "./connected.css";
import LinkEmpty from "./LinkEmpty";

function Connected() {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getConnections()
      .then((results) => {
        setUsers(results.users);
      })
      .catch((e) => console.log(e))
      .finally(() => setIsLoading(false));
  }, []);

  if (!isLoading && !users.length) return <LinkEmpty />;

  return isLoading ? (
    <div className="loader_container">
      <Loader />
    </div>
  ) : (
    <>
      <div className="container-fluid favorites connect_container_pb">
        <div
          className="text-left pb-3 mb-4 desktop_version"
          style={{ borderBottom: "1px solid var(--font_color2)" }}
        >
          <label className="font20 text-left">Links</label>
        </div>
        <div className="row" style={{ paddingBottom: "68px" }}>
          {users ? (
            users.map((user) => (
              <div className="col-6 col-md-4 col-xl-2 mb-3" key={user.id}>
                <ConnectCard user={user} />
              </div>
            ))
          ) : (
            <Loader />
          )}
        </div>
        <img
          className="mobile_spotify"
          src={spotify}
          alt="spotify"
          style={{ bottom: "1%" }}
        />
      </div>
    </>
  );
}

export default Connected;
