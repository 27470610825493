import * as yup from 'yup';

export const validationSchema = yup.object({
  email: yup.string().email('Invalid Email').required('Please enter your email'), 
  fullName: yup.string().required('Please enter your name'), 
  phoneNumber: yup.object(), 
  address: yup.string().required('Invalid data'), 
  address2: yup.string().required('Invalid data'), // apartment
  city: yup.string().required('Invalid data'),
  state: yup.string().required('Invalid data'),
  zip: yup.number().required('Invalid ZIP code'),
  building: yup.number(),
});