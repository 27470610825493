import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Drawer, Collapse } from "antd";
import logo from "components/assets/logo.svg";
import bag from "components/assets/bag.png";
import logo2 from "components/assets/logo2.svg";
import profileDefaultDark from "components/assets/profile_default_dark.png";
import profileDefaultLight from "components/assets/profile_default_light.png";
import ellipse from "components/assets/ellipse.png";
import presale from "components/assets/presal.png";
import raffle from "components/assets/raffle.png";
import pin from "components/assets/pin.png";
import ellipse2 from "components/assets/ellipse2.png";
import presale2 from "components/assets/presal2.png";
import raffle2 from "components/assets/raffle2.png";
import pin2 from "components/assets/pin2.png";
import collape_icon from "components/assets/collape_icon.png";
import collape_icon2 from "components/assets/collape_icon2.png";
import collape_icon_d from "components/assets/collape_icon_d.png";
import collape_icon_d2 from "components/assets/collape_icon_d2.png";
import fashion from "components/assets/fashion.png";
import goods from "components/assets/goods.png";
import tech from "components/assets/tech.png";
import beauty from "components/assets/beauty.png";
import fashion2 from "components/assets/fashion2.png";
import goods2 from "components/assets/goods2.png";
import tech2 from "components/assets/tech2.png";
import beauty2 from "components/assets/beauty2.png";
import bag2 from "components/assets/bag2.png";
import "./Author/css/header.css";
import "./Author/css/sms.css";
import "antd/dist/antd.css";
import CheckOut from "./Checkout";
import { connect, useDispatch } from "react-redux";
import Tooltip from "react-bootstrap/Tooltip";
import { MdGridView } from "react-icons/md";
import { toggleTheme } from "actions/index";
import DayNightToggle from "react-day-and-night-toggle";
import { setDropFilters } from "actions/dropFilters";
import { getCreatorsList } from "actions/creator";
import { validateUserName } from "./common/namesValidator";
import PhoneModal from "./Author/PhoneModal";
import { useUserData } from "actions/firebase";
import Playlist from "./Spotify/Playlist";
import CloseButton from "./common/CloseButton";
import { Logo } from "res/svg";
import { ColorChanger } from "res/svg";
import { LogoNoTitle } from "res/svg";
import { setHeaderStatus } from "actions";

const { Panel } = Collapse;

const noti_menu = [
  {
    id: 1,
    description: "Lex Murphy requested access to UNIX directory tree hierarchy",
    date: "Today at 9:42 AM",
  },
  {
    id: 2,
    description:
      "Ray Arnold left 6 comments on Isla Nublar SOC2 compliance report",
    date: "Yesterday at 11:42 PM",
  },
  {
    id: 3,
    description:
      "Ray Arnold left 6 comments on Isla Nublar SOC2 compliance report",
    date: "Yesterday at 11:42 PM",
  },
  {
    id: 4,
    description:
      "Ray Arnold left 6 comments on Isla Nublar SOC2 compliance report",
    date: "Yesterday at 11:42 PM",
  },
];

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
    dropFilters: state.dropFilters,
    creatorsList: state.creators.creatorsList,
    loading: !state.creators.creatorsListLoading,
    bagList: state.bag.dropList,
    creatorsSpaceData: state.creators.creatorsSpaceData,
  };
};

function Header(props) {
  const {
    theme,
    toggleTheme,
    dropFilters,
    creatorsList,
    bagList,
    creatorsSpaceData,
  } = {
    ...props,
  };
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkoutShow, setCheckoutShow] = useState(false);
  const [logined, setLogin] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [locateView, setLocationView] = useState(true);
  const [profileImage, setProfileImage] = useState(
    theme ? profileDefaultDark : profileDefaultLight
  );
  const [spaceData, setSpaceData] = useState();
  const dispatch = useDispatch();
  const userData = useUserData();
  const [isScrollDown, setIsScrollDown] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  const { pathname } = useLocation();
  const hidePageList = ["/performance", "/404"];
  const isHideHeader = hidePageList.some((page) => pathname.includes(page));

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    const maxScrollHeight =
      document.documentElement.scrollHeight - window.innerHeight;

    setPrevScrollPos((prev) => {
      if (
        Math.abs(currentScrollPos - prev) < 20 ||
        currentScrollPos > maxScrollHeight ||
        currentScrollPos < 0
      )
        return prev;
      setIsScrollDown(currentScrollPos > prev);
      return currentScrollPos;
    });
  };

  useEffect(() => {
    dispatch(setHeaderStatus(isScrollDown));
  }, [isScrollDown]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("logined", JSON.stringify(logined));
  }, [logined]);

  const handleSetFilter = (category, filterType) => {
    dispatch(
      setDropFilters({ category: category, value: filterType.toLowerCase() })
    );
  };

  const handleCheckout = () => {
    if (bagList.length) {
      setCheckoutShow(true);
    }
  };

  const closeCheckout = (showCheckout) => {
    setCheckoutShow(showCheckout);
  };

  const handleLogin = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    console.log("user data", userData);
    setLogin(userData?true:false);
    if (userData?.imageUrl) {
      setProfileImage(userData.imageUrl);
    }
  }, [userData]);

  useEffect(() => {
    dispatch(getCreatorsList());
  }, [dispatch]);

  useEffect(() => {
    if (creatorsSpaceData?.creatorData?.creatorSpaces?.length) {
      setSpaceData(creatorsSpaceData.creatorData.creatorSpaces[0]);
    } else {
      setSpaceData(null);
    }
  }, [creatorsSpaceData, dispatch]);

  const filteredCreators = useMemo(
    () =>
      creatorsList.filter(
        (creator) => creator.username && creator.profileImage && creator
      ),
    [creatorsList]
  );

  const checkIsButtonActive = (name, type) => {
    if (dropFilters?.filter[type]?.includes(name.toLowerCase())) {
      return true;
    }
    return false;
  };

  const dropTypeTabs = [
    {
      name: "Now",
      id: "1",
      img: theme ? ellipse : ellipse2,
      activeImg: ellipse2,
    },
    {
      name: "Presale",
      id: "2",
      img: theme ? presale : presale2,
      activeImg: presale2,
    },
    {
      name: "Raffle",
      id: "3",
      img: theme ? raffle : raffle2,
      activeImg: raffle2,
    },
    {
      name: "Pin",
      id: "4",
      img: theme ? pin : pin2,
      activeImg: pin2,
    },
  ];

  const categoryTabs = [
    {
      name: "Fashion",
      id: "1",
      img: theme ? fashion : fashion2,
      activeImg: fashion2,
    },
    {
      name: "Beauty",
      id: "2",
      img: theme ? beauty : beauty2,
      activeImg: beauty2,
    },
    {
      name: "Tech",
      id: "3",
      img: theme ? tech : tech2,
      activeImg: tech2,
    },
    {
      name: "Goods",
      id: "4",
      img: theme ? goods : goods2,
      activeImg: goods2,
    },
  ];

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {noti_menu.map((item) => (
        <div className="p-2">
          <div
            className="bg-danger"
            style={{ width: 4, height: 4, borderRadius: 10 }}
          ></div>
          <div className="d-grid text-left pl-2">
            <label className="font12">{item.description}</label>
            <label className="font10_2">{item.date}</label>
          </div>
        </div>
      ))}
    </Tooltip>
  );

  return isHideHeader ? (
    <></>
  ) : (
    <>
      <div className="author_header">
        <div
          className={`display-header ${
            isScrollDown ? "scrolled_header" : "unscrolled_header"
          }`}
        >
          <div style={{ display: "flex", gap: 10 }}>
            {spaceData ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <div
                  className="avatar-creators"
                  style={{
                    width: 40,
                    height: 40,
                    display: "inline-block",
                    backgroundImage: `url(${spaceData.image})`,
                  }}
                />
                <span className="font16">{spaceData.name}</span>
              </div>
            ) : (
              <Link to="/">
                <ColorChanger>
                  {(fillColor) => <Logo fillColor={fillColor} />}
                </ColorChanger>
              </Link>
            )}
          </div>
          <div className="profile">
            <div className="mobile_mode">
              <Playlist />
            </div>
            <div className="profile_set" style={{ gap: logined ? 2 : 30 }}>
              {/* <OverlayTrigger
                rootClose
                trigger="click"
                placement="bottom-end"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
                // onExit={() => setShow(false)}
              >
                <div
                  className="logined_badge "
                  style={{ display: logined ? "flex" : "none" }}
                >
                  <img
                    src={theme ? noti : noti2}
                    alt=""
                    style={{ cursor: "pointer" }}
                  />
                  <label
                    className="badge"
                    style={{ display: logined ? "block" : "none" }}
                  >
                    3
                  </label>
                </div>
              </OverlayTrigger> */}

              {/* <img
                src={theme ? noti : noti2}
                alt=""
                style={{
                  cursor: "pointer",
                  display: logined ? "none" : "block",
                }}
              /> */}

              {/**
               * 
              <div
                className="login_avatar"
                style={{
                  backgroundImage: theme
                    ? `url("${netDark}")`
                    : `url("${netLight}")`,
                  display: logined ? "flex" : "none",
                }}
              >
                <Link to="/profile/personalinfo">
                  <img width={40} style={{borderRadius: '50%'}} src={profileImage} alt="avatar" />
                </Link>
              </div>

              <img
                src={theme ? profile : profile2}
                alt=""
                onClick={handleLogin}
                style={{
                  display: logined ? "none" : "block",
                  cursor: "pointer",
                }}
              />
              */}

              {!bagList.length ? (
                <img
                  src={theme ? bag : bag2}
                  alt=""
                  onClick={handleCheckout}
                  style={{
                    cursor: "pointer",
                    display: logined ? "none" : "block",
                  }}
                />
              ) : (
                <div className="logined_badge " style={{ display: "flex" }}>
                  <img
                    src={theme ? bag : bag2}
                    alt=""
                    onClick={handleCheckout}
                    style={{ cursor: "pointer" }}
                  />
                  <label className="badge">{bagList.length}</label>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Login part */}

        <PhoneModal
          isModalOpen={isModalOpen}
          setIsModalOpen={(v) => setIsModalOpen(v)}
        />

        <CheckOut show={checkoutShow} onHide={() => closeCheckout()} />

        <Drawer
          placement="left"
          closable={false}
          onClose={() => setShowMenu(false)}
          open={showMenu}
        >
          <CloseButton
            className="menu_close"
            onClick={() => setShowMenu(false)}
          />
          <div
            className="filter"
            id="fade_div_component"
            draggable={false}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div className="filter-content">
              <label className="font16 p-3" style={{ cursor: "pointer" }}>
                Channels
              </label>
              <div className="channel_viewer pl-3">
                <Link to="/">
                  <div
                    className="grid_view"
                    tabIndex="1"
                    onClick={() => (setLocationView(true), setShowMenu(false))}
                  >
                    <MdGridView size={25} />
                  </div>
                </Link>
              </div>
              <Collapse
                defaultActiveKey={["1"]}
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <img
                    src={
                      isActive
                        ? theme
                          ? collape_icon
                          : collape_icon_d
                        : theme
                        ? collape_icon2
                        : collape_icon_d2
                    }
                    alt="collape"
                  />
                )}
              >
                <Panel header="Drop Type" key="1">
                  {dropTypeTabs.map((el) => (
                    <div
                      onClick={() => handleSetFilter("dropType", el.name)}
                      className="channel_item"
                      style={{
                        cursor: "pointer",
                        background:
                          checkIsButtonActive(el.name, "dropType") && "#FFF",
                        borderRadius: "20px",
                        padding: "4px 10px 4px 10px",
                      }}
                      key={el.id}
                    >
                      <img
                        src={
                          checkIsButtonActive(el.name, "dropType")
                            ? el.activeImg
                            : el.img
                        }
                        alt=""
                      />
                      <div
                        style={{
                          color:
                            checkIsButtonActive(el.name, "dropType") &&
                            "#0d0d0d99",
                        }}
                        className="font12_6"
                      >
                        {el.name}
                      </div>
                    </div>
                  ))}
                </Panel>
                <Panel header="Creators" key="2">
                  {!_.isEmpty(filteredCreators) &&
                    filteredCreators.map((creator) => (
                      <div
                        onClick={() => {
                          navigate(`/${creator.username}`);
                          setShowMenu(false);
                        }}
                        className="d-flex align-items-center p-2"
                        style={{ gap: 10, cursor: "pointer" }}
                        key={creator.id}
                      >
                        <div>
                          <img
                            src={creator.profileImage}
                            alt=""
                            style={{
                              width: 30,
                              height: 30,
                              borderRadius: "100%",
                            }}
                          />
                        </div>
                        <label className="font12_6">
                          {validateUserName(creator.username)}
                        </label>
                      </div>
                    ))}
                </Panel>
                <Panel header="Categories" key="3">
                  {categoryTabs.map((el) => (
                    <div
                      key={el.id}
                      onClick={() => handleSetFilter("categories", el.name)}
                      className="channel_item"
                      style={{
                        cursor: "pointer",
                        background:
                          checkIsButtonActive(el.name, "categories") && "#FFF",
                        borderRadius: "20px",
                        padding: "4px 10px 4px 10px",
                      }}
                    >
                      <img
                        src={
                          !checkIsButtonActive(el.name, "categories")
                            ? el.img
                            : el.activeImg
                        }
                        alt=""
                      />
                      <div
                        style={{
                          color: checkIsButtonActive(el.name, "categories")
                            ? "rgba(13, 13, 13, 0.6)"
                            : "#ffffff99",
                          fontWeight: 400,
                          fontSize: "12px",
                        }}
                      >
                        {el.name}
                      </div>
                    </div>
                  ))}
                </Panel>
              </Collapse>
            </div>
            <div className="channel_spotify">
              <div style={{ margin: "15px 0" }}></div>
              <div
                className="d-flex"
                style={{ margin: "15px 0", alignItems: "end" }}
              >
                <DayNightToggle
                  onChange={() => toggleTheme(!theme)}
                  checked={theme}
                  size={20}
                />
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleTheme: (value) => {
      dispatch(toggleTheme(value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
