import { Routes, Route } from "react-router-dom";

import PersonalInfo from "components/Profile/PersonalInfo";
import PaymentInfo from "components/Profile/PaymentInfo";
import Orders from "components/Profile/Orders";
import Favorites from "components/Profile/Favorites";
import Connected from "components/Profile/Connected";
import Passport from "components/Profile/Passport";
import Channels from "components/Author/Channels";
import ProfileMenu from "components/Profile/ProfileMenu";
import FloatingNav from "components/common/FloatingNav";
import { useUserId } from "actions/firebase";
import { useSelector } from "react-redux";
import EditSpace from "components/Profile/EditSpace";

function Profile() {
    const userId = useUserId();
    const showMenu = useSelector(state => state.dropCalendar.showMenu);

    return (
        <div className="profile_body">
            {userId && <>
                <Channels />
                <ProfileMenu />
                <div className="w-100 h-100">
                    <Routes>
                        <Route element={<PersonalInfo />} path="/personalinfo" />
                        <Route element={<PaymentInfo />} path="/paymentinfo" />
                        <Route element={<Orders />} path="/orders" />
                        <Route element={<Favorites />} path="/favorites" />
                        <Route element={<Connected />} path="/connected" />
                        <Route element={<Passport />} path="/passport" />
                        <Route element={<EditSpace />} path="/editspace" />
                    </Routes>
                </div>
            </>}
            {showMenu && <FloatingNav />}
        </div>
    )
}
export default Profile;