import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import VideoPlayer from "react-background-video-player";
import { Card } from "antd";
import moment from "moment";
import "swiper/css";

import SkeletonComponent from "components/Skeleton/index";
import OrderTypeBadge from "./OrderTypeBadge";
import "components/Product/css/productcard.css";

// for receipt page
import { useUserId } from "actions/firebase";
import { getUserOrders } from "actions/firebase";

const mapStateToProps = (state) => ({
  theme: state.theme,
});

const isVideo = (src) => /\.(mov|mp4)/i.test(src);

function GraphicCard(props) {
  const userId = useUserId();
  console.log("Idddddddddddddddddddddddddddddddd:", userId);
  const {
    date,
    id,
    data,
    cardimg,
    dropType,
    setSelectedOrder,
    isSelected,
    setClickReview,
  } = props;
  const loading = true;
  const [logined, setLogin] = useState(false);

  const timestamp = useMemo(() => new Date(date), [date]);

  let mobileView = false;

  if (window.innerWidth < 680) {
    mobileView = true;
  }

  useEffect(() => {
    const logined1 = JSON.parse(localStorage.getItem("logined"));
    setLogin(logined1);
  }, [logined]);

  const handleShowCard = () => {
    setSelectedOrder(data);
  };

  const [videoError, setVideoError] = useState(false);

  const onReviewClick = () => {
    // Data to be sent
    const dataToSend = {
      uid: userId,
    };
    // Convert data to query parameters
    const queryParams = new URLSearchParams(dataToSend).toString();
    // URL of the destination page with data as query parameters
    const destinationUrl = `https://receipt-2sez1gghznx7.web.app?${queryParams}`;
    //const destinationUrl = "localhost:3001"; // for dev
    console.log("destination url", destinationUrl);
    // Open the destination page in a new tab
    // while broadcast the localstorage item "logged"
    // so when opening the new tab, get the orders automatically.
    // Use BroadcastChannel to notify other tabs
    const channel = new BroadcastChannel("tab-channel-receipt");
    channel.postMessage({
      key: "logined",
      value: localStorage.getItem("logined"),
    });

    const newTab = window.open(destinationUrl, "_blank");
    // setClickReview();
    // const newTab = window.open(
    //   "https://receipt-2sez1gghznx7.web.app/",
    //   "_blank"
    // );
    if (newTab) {
      // Tab opened successfully
      newTab.focus();
    } else {
      // Browser blocked the pop-up, handle accordingly
      console.error("Popup blocked by the browser");
      // You might want to display a user-friendly message here
    }
  };

  return (
    data && (
      <>
        <div className="graphic_card" data-productid={id}>
          <video
            style={{ display: "none" }}
            onError={() => setVideoError(true)}
          >
            <source src={cardimg} type="video/mp4" />
          </video>
          <Card
            style={{
              width: "100%",
              cursor: "pointer",
            }}
            onClick={handleShowCard}
            cover={
              !loading || cardimg === "" ? (
                <SkeletonComponent
                  height={200}
                  width="100%"
                  borderRadius="12px 12px 0 0"
                  style={{ lineHeight: "2" }}
                />
              ) : !videoError && isVideo(cardimg) ? (
                <div className="video_background">
                  <VideoPlayer
                    className="video_cover"
                    src={cardimg}
                    autoPlay
                    muted
                    playsInline
                    style={{ borderRadius: "10px 10px 0 0" }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    position: "relative",
                    backgroundImage: `url(${cardimg})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: "200px",
                    borderRadius: "12px 12px 0 0",
                  }}
                ></div>
              )
            }
          >
            <div className="card_content">
              <div className="text-left">
                <div className="font10_2">Order Placed</div>
                <div className="font10">
                  {moment(timestamp).format("MM/DD/YY")}
                </div>
              </div>
              <div className="d-flex">
                {loading ? (
                  <>
                    <OrderTypeBadge dropType={2} releaseDts={date} />
                    <div className="review_button" onClick={onReviewClick}>
                      View
                    </div>
                  </>
                ) : (
                  <SkeletonComponent
                    height={29}
                    width={86}
                    style={{ marginRight: "10px" }}
                  />
                )}
              </div>
            </div>
          </Card>
        </div>
      </>
    )
  );
}

export default connect(mapStateToProps)(GraphicCard);
