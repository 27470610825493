import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import "antd/dist/antd.css";

import './App.css';
import Author from 'pages/Author';
import Homepage from 'pages/Homepage';
import Profile from 'pages/Profile';
import Legal from 'pages/Legal';
import DevTools from 'pages/DevTools';
import Performance from 'pages/Performance';

import 'components/Author/css/main.css';
import Header from 'components/Header';

import { toggleTheme } from 'actions';
import { useUserData } from 'actions/firebase';

import { getStripeKey, isNightTime } from 'utils/utils';
import { TrackGoogleAnalyticsEvent } from 'utils/googleAnalytics';
import { setThemeColors } from 'utils/utils';
import Page404 from 'components/404';
import Favorites from 'components/Profile/Favorites';

const stripePromise = loadStripe(getStripeKey());
const options = {
  fonts: [{
    cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat:400,500',
  }]
};

function App() {
  return (
    <Elements stripe={stripePromise} options={options}>
      <BrowserRouter>
        <div className='switch_mode'>
          <Header />
          <LayoutRoutes />
        </div>
      </BrowserRouter>
    </Elements>
  );
}

const LayoutRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const userData = useUserData();

  useEffect(() => {
    const theme = isNightTime()
    dispatch(toggleTheme(theme));
    setThemeColors(theme);
  }, [])

  useEffect(() => {
    if (location) {
      window.gtag('set', 'page_path', location.pathname);
      window.gtag('event', 'page_view');
      if (userData?.username)
        TrackGoogleAnalyticsEvent({ category: "NewPageView", action: `NewPageView_${userData?.username}_${location.pathname}`, label: userData?.usermame });
    }
  }, [location]);

  return (
    <Routes>
      <Route path="/author/@allankingdom" element={<Navigate to="/allankingdom" replace />} />
      <Route path="/performance/:userName" element={<Performance />} />
      <Route path="/:creatorName" element={<Author />}>
        <Route index element={<Author />} />
        <Route path=":token" element={<Author />} />
        <Route path=":spaceName/:productName" element={<Author />} />
      </Route>
      <Route path="/profile/*" element={<Profile />} />
      <Route path="/404/page404" element={<Page404 />} />
      <Route path="/legal/:section" element={<Legal />} />
      <Route path="/devtools" element={<DevTools />} />
      <Route path="/favorites" element={<Favorites />} />
      <Route path="/*" element={<Homepage />} />
      <Route path="/home/*" element={<Homepage />} />

    </Routes>
  );
}

export default App;