import { useSelector } from 'react-redux';

import empty_dark from 'components/assets/favorite_empty_dark.svg';
import empty_light from 'components/assets/favorite_empty_light.svg';
import './favorite.css'

const FavoriteEmpty = ({ className }) => {

    const theme = useSelector(state => state.theme);
    const img_empty = theme ? empty_dark : empty_light;

    return (
        <div className={`favorite_empty_container ${className}`}>
            <img src={img_empty} className='favorite_empty_image' />
            <div className='font16_6 favorite_empty_font'>{`Your favorites list is empty! Let's change that-start favoriting cool stuff now!`}</div>
        </div>
    );
}

export default FavoriteEmpty;