import { combineReducers } from "redux";
import theme from "./theme";
import header from "./header";
import dropCalendar from "./dropCalendar";
import dropFilters from "./dropFilters";
import creators from "./creator";
import products from "./products";
import bag from "./bag";


const reducer = combineReducers({
    theme,
    header,
    dropCalendar,
    dropFilters,
    creators,
    products,
    bag,
})

export default reducer;