import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BlockPicker } from 'react-color';
import { browserName } from 'react-device-detect';
import { Input, Drawer, Upload } from 'antd';
import { useFormik } from 'formik';

import { GET_CREATORS_SPACE_DATA } from 'actions/types';
import Loader from "components/common/loader";
import edit from 'components/assets/edit.png';
import { getContrastTextColor } from 'utils/utils';
import {
    useUserId,
    getUserData,
    setUserPreferences,
    uploadFile,
    useUserData,
    getSpaceData,
    updateSpaceData,
    getCustomToken
} from 'actions/firebase';

import './editspace.css'; // ... rest of the code

function EditSpace({
    handleCloseModal,
    showModal,
}) {

    const originalUserData = useRef({
        userName: '',
        userImage: '',
        creatorHandle: '',
        bio: '',
        spaceName: '',
        spaceImage: '',
        spaceColor: '',
    });

    const formik = useFormik({
        initialValues: {
            userName: '',
            userImage: '',
            creatorHandle: '',
            bio: '',
            spaceName: '',
            spaceImage: '',
            spaceColor: '',
        }
    });

    const creatorsSpaceData = useSelector(state => state.creators.creatorsSpaceData);
    const dispatch = useDispatch();

    const userId = useUserId();
    const userData = useUserData();

    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [showColorModal, setShowColorModal] = useState(false);
    const [profileImage, setProfileImage] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [spaceData, setSpaceData] = useState({});
    const [isDataChanged, setIsDataChanged] = useState(false);

    const mobileView = window.innerWidth < 680;

    useEffect(() => {

        console.log('----: ', creatorsSpaceData);

        if (creatorsSpaceData && creatorsSpaceData?.creatorData?.creatorSpaces?.length > 0) {
            const spaceData = creatorsSpaceData?.creatorData?.creatorSpaces[0];
            async function fetchData() {
                try {
                    const spaceAllData = await getSpaceData({ name: spaceData.name });
                    setSpaceData({ ...spaceData, color: `#${spaceAllData?.group?.QRColor}` || '#990000' });
                    originalUserData.current.spaceColor = `#${spaceAllData?.group?.QRColor}` || '#990000';
                    originalUserData.current.spaceName = spaceAllData?.group?.name;
                    originalUserData.current.spaceImage = spaceAllData?.group?.profileImage;
                    initializeFormData(originalUserData.current);
                    if (!creatorsSpaceData?.creatorData?.creatorSpaces[0].color)
                        updateLocalSpaceData({ color: originalUserData.current.spaceColor });
                } catch (error) {
                    console.log('SpaceAllData Error: ', error);
                }
            }
            fetchData();

        }
    }, [creatorsSpaceData, showModal]);

    function updateLocalSpaceData(params) {
        const { color } = params;
        var updatedSpaceData = { ...creatorsSpaceData }
        updatedSpaceData.creatorData.creatorSpaces[0].color = color;
        dispatch({
            type: GET_CREATORS_SPACE_DATA,
            payload: updatedSpaceData,
        });
    }

    function isFormikDataUnchanged() {
        for (const key in originalUserData.current) {
            if (originalUserData.current[key] !== formik.values[key]) {
                return false;
            }
        }
        return true;
    }



    useEffect(() => {
        setIsDataChanged(!isFormikDataUnchanged());
    }, [formik.values]);

    const saveChanges = async () => {
        setIsSaving(true);
        setUserPreferences({
            username: formik.values.creatorHandle,
            whatsYourStory: formik.values.bio,
            imageUrl: formik.values.userImage
        }).then(() => { });

        try {
            const updateData = {
                idGroup: spaceData.id,
                name: formik.values.spaceName,
                profileImage: formik.values.spaceImage,
                profileName: formik.values.userName,
                QRColor: formik.values.spaceColor.substring(1)
            };
            const updatedResult = await updateSpaceData(updateData);
            console.log('Updated Result: ', updatedResult);
        } catch (error) {
            console.log('Updated Error: ', error);
        }
        setIsSaving(false);
        handleCloseModal();

        var updatedSpaceData = { ...creatorsSpaceData };
        updatedSpaceData.creatorData.creatorHandle = formik.values.creatorHandle;
        updatedSpaceData.creatorData.creatorName = formik.values.userName;
        updatedSpaceData.creatorData.creatorBio = formik.values.bio;
        updatedSpaceData.creatorData.creatorProfileImage = formik.values.userImage;
        updatedSpaceData.creatorData.creatorSpaces[0].color = formik.values.spaceColor;
        updatedSpaceData.creatorData.creatorSpaces[0].name = formik.values.spaceName;
        updatedSpaceData.creatorData.creatorSpaces[0].image = formik.values.spaceImage;
        originalUserData.current = formik.values;
        console.log('UpdatedSpaceData: ', updatedSpaceData);
        dispatch({
            type: GET_CREATORS_SPACE_DATA,
            payload: updatedSpaceData,
        });
    }

    const cancelChanges = () => {
        initializeFormData(originalUserData.current)
        handleCloseModal();
    }

    useEffect(() => {
        for (let key in originalUserData.current) {
            formik.setFieldValue(key, originalUserData.current[key]);
        }
    }, [originalUserData]);

    const initializeFormData = user => {
        for (let key in user) {
            formik.setFieldValue(key, user[key]);
        }
    }

    useEffect(() => {
        if (!userId) {
            return;
        }
        getUserData().then(data => {
            const { user } = data;
            console.log(user);
            originalUserData.current.userName = user.name;
            originalUserData.current.creatorHandle = user.username;
            originalUserData.current.userImage = user.imageUrl;
            originalUserData.current.bio = user.whatsYourStory;
            initializeFormData(originalUserData.current)
            setIsLoading(false);
        })
    }, [userId])

    useEffect(() => {
        if (userData?.imageUrl) {
            setProfileImage(userData.imageUrl)
        }
    }, [userData])

    const handleColorPicker = () => {
        setShowColorModal(!showColorModal);
    }

    const fileAdded = async info => {
        setIsUploading(true);
        const imageUrl = await uploadFile(info, 'Profile');
        if (imageUrl) {
            formik.setFieldValue('userImage', imageUrl);
        }
        setIsUploading(false);
    }

    const spaceFileAdded = async info => {
        setIsUploading(true);
        const imageUrl = await uploadFile(info, 'Space_profile');
        if (imageUrl) {
            formik.setFieldValue('spaceImage', imageUrl);
        }
        setIsUploading(false);
    }

    const height = browserName == 'Mobile Safari' ? '90%' : '100%';

    return (
        <        >
            {isLoading ? <Loader /> : <>
                <div style={{ height: height, position: 'absolute', width: '100%', bottom: 0 }}>
                    <div className='edit_personal_info'>
                        <div style={{ height: 80, width: '100%', backgroundColor: formik.values.spaceColor, borderRadius: '16px 16px 0 0' }}></div>
                        <div style={{ margin: '10px 0', position: 'relative' }}>
                            <Upload.Dragger
                                style={{ border: 0, backgroundColor: 'transparent', width: '100px', height: '100px', marginTop: '-70px', marginLeft: '10px' }}
                                accept="image/*"
                                multiple={false}
                                showUploadList={false}
                                customRequest={fileAdded}
                            >
                                <img src={profileImage} alt="profile" style={{ width: '80px', height: '80px', borderRadius: '50%' }} />
                                <img className='editer' src={edit} alt='space' style={{ cursor: "pointer", width: '20px', height: '20px' }} />
                                {isUploading &&
                                    <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
                                        <Loader />
                                    </div>
                                }
                            </Upload.Dragger>
                        </div>
                        <div className='edit_personal_info1'>
                            <div className='info_each'>
                                <label className='font12_mono' style={{ padding: '10px 0' }}>Name <span style={{ color: '#EB4949', marginLeft: 5 }}>*</span></label>
                                <Input name='userName' placeholder="Enter text" value={formik.values.userName} onChange={formik.handleChange} />
                            </div>
                            <div className='info_each'>
                                <label className='font12_mono' style={{ padding: '10px 0' }}>Creator Handle <span style={{ color: '#EB4949', marginLeft: 5 }}>*</span></label>
                                <Input name='creatorHandle' value={formik.values.creatorHandle} onChange={formik.handleChange} />
                            </div>
                            <div className='info_each'>
                                <label className='font12_mono' style={{ padding: '10px 0' }}>Bio</label>
                                <Input.TextArea name='bio' showCount maxLength={250} style={{ height: 100 }} value={formik.values.bio} onChange={formik.handleChange} />
                            </div>
                            <div className='info_each'>
                                <label className='font12_mono' style={{ padding: '10px 0' }}>Space Name <span style={{ color: '#EB4949', marginLeft: 5 }}>*</span></label>
                                <div className='space-info'>
                                    {formik.values.spaceImage &&
                                        <>
                                            <Upload.Dragger
                                                style={{ border: 0, backgroundColor: 'transparent', width: '50px', height: '50px', marginRight: '10px' }}
                                                accept="image/*"
                                                multiple={false}
                                                showUploadList={false}
                                                customRequest={spaceFileAdded}
                                            >
                                                <img src={formik.values.spaceImage} alt="Space" style={{ width: '50px', height: '50px', borderRadius: '50%', margin: '10px' }} />
                                                {isUploading &&
                                                    <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
                                                        <Loader />
                                                    </div>
                                                }
                                            </Upload.Dragger>

                                        </>
                                    }
                                    <Input name='spaceName' value={formik.values.spaceName} onChange={formik.handleChange} />
                                </div>
                            </div>
                            <div className='info_each'>
                                <label className='font12_mono' style={{ padding: '10px 0' }}>Aura Color</label>
                                <div className='space-color' onClick={handleColorPicker}>
                                    <div style={{ width: 30, height: 30, borderRadius: '100px', padding: '0px 10px', border: '1px solid white', backgroundColor: formik.values.spaceColor }} />
                                    <Input name='spaceColor' placeholder="pick color" value={formik.values.spaceColor} onChange={formik.handleChange} style={{ borderWidth: 0, margin: '10px 10px' }} />
                                    {showColorModal && <div style={{ position: 'fixed', width: '100%', height: '100%', display: 'flex', alignItems: 'flex-start', inset: '0px', justifyContent: 'center', marginTop: '95px' }}>
                                        <div style={{}}>
                                            <BlockPicker
                                                color={formik.values.spaceColor}
                                                onChangeComplete={(event) => { formik.setFieldValue('spaceColor', event.hex); }} />
                                        </div>
                                    </div>}
                                </div>
                            </div>
                            {isDataChanged && <div className='save_buttons1'>
                                {isSaving ? <>
                                    <Loader />
                                </> : <>
                                    <div>
                                        <button className='cancel_button font16' onClick={cancelChanges}>Cancel</button>
                                    </div>
                                    <div>
                                        <button
                                            className='save_button font16'
                                            style={{ backgroundColor: formik.values.spaceColor, color: getContrastTextColor(formik.values.spaceColor) }}
                                            onClick={saveChanges}
                                        >
                                            Save Changes
                                        </button>
                                    </div>
                                </>}
                            </div>}

                        </div>
                    </div>
                </div>
            </>}
        </>
    );


}
export default EditSpace;