import Modal from 'react-bootstrap/Modal';
import ProductCardView from 'components/Product/ProductCardView';
import './noti.css';
import { formatDateToCustomString } from 'utils/utils';

function Notification(props) {

    const eventDetails = {
        title: `New Drop Alert! ✨ ${props?.data?.name}`,
        description: `🚀 New Drop Alert! ✨ ${props?.data?.name} is launching today at ${formatDateToCustomString(new Date())}. Don't forget to check it out at ${`https://auradrops.com/${props?.data?.path}`}. You won't want to miss this!`,
        startTime: new Date(),
        endTime: new Date(new Date().setHours(new Date().getHours() + 1)),
        location: 'Online'
    };

    const generateICS = (event) => {
        const formattedStartTime = event.startTime.toISOString().replace(/-|:|\.\d+/g, '');
        const formattedEndTime = event.endTime.toISOString().replace(/-|:|\.\d+/g, '');

        const icsMSG = [
            'BEGIN:VCALENDAR',
            'VERSION:2.0',
            'BEGIN:VEVENT',
            `UID:${new Date().toISOString()}`,
            `DTSTAMP:${formattedStartTime}`,
            `DTSTART:${formattedStartTime}`,
            `DTEND:${formattedEndTime}`,
            `SUMMARY:${event.title}`,
            `DESCRIPTION:${event.description}`,
            `LOCATION:${event.location}`,
            'END:VEVENT',
            'END:VCALENDAR'
        ].join('\n');

        const blob = new Blob([icsMSG], { type: 'text/calendar' });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = `${props?.data?.name}.ics`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleNoti = () => {

        // const calendarURL = `https://www.google.com/calendar/render?action=TEMPLATE&text=${eventDetails.title}&dates=${eventDetails.startTime.toISOString()}/${eventDetails.endTime.toISOString()}&details=${eventDetails.description}&location=${eventDetails.location}`;
        // window.open(calendarURL, '_blank', 'noopener,noreferrer');
        // props.onHide(false);
        generateICS(eventDetails);
        props.onHide(true);
    }

    const handleSkip = () => {
        props.onHide(false);
    }

    return (
        <>
            <Modal {...props} size="md" centered>
                <Modal.Body className='noti_part' style={{ backgroundColor: 'var(--background-color)' }}>
                    <div className='noti_header'>
                        <div className='d-flex' style={{ justifyContent: 'right' }}>
                            <div className='font14_w4 skip_button' onClick={handleSkip}>Skip</div>
                        </div>
                        <label className='font18 mb-2' style={{ color: 'var(--font_color)', paddingTop: 27 }}>Want to make double sure you don't miss out?</label>
                        <label className='font12_6 text-left'>We'll send you a friendly text reminder on the big day of the drop! You can also pop the drop date and time into your personal calendar.</label>
                    </div>

                    <div className='notification_body'>
                        {props?.id && <ProductCardView {...props} />}
                    </div>
                    <div className='noti_footer text-center font16' onClick={handleNoti}>Add to calendar</div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Notification;
