import { SET_DROP_FILTERS } from "actions/types";

const initialState = {
  filter: {
    price: [],
    categories: [],
    creators: [],
    dropType: [],
    seasons: [],
  },
};

function dropFilters(state = initialState, action) {
  switch (action.type) {
    case SET_DROP_FILTERS:
      const { category, value } = action.payload;
      let updatedFilters = { ...state.filter };
      if (updatedFilters[category].includes(value)) {
        updatedFilters = {
          ...updatedFilters,
          [category]: updatedFilters[category].filter((e) => e !== value),
        };
      } else {
        updatedFilters = {
          ...updatedFilters,
          [category]: [...state.filter[category], value],
        };
      }
      return {
        ...state,
        filter: updatedFilters,
      };
    default:
      return state;
  }
}

export default dropFilters;
