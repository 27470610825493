import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import profileDefaultDark from "components/assets/profile_default_dark.png";
import profileDefaultLight from "components/assets/profile_default_light.png";
import netDark from "components/assets/net_dark.gif";
import netLight from "components/assets/net_light.gif";
import Profile from "components/assets/nav/profile.svg";

import Heart_Fill from "components/assets/nav/tray_heart_fill.svg";
import Heart from "components/assets/nav/tray_heart.svg";

import MapFill from "components/assets/nav/tray_map_fill.svg";
import Map from "components/assets/nav/tray_map.svg";

import Calendar from "components/assets/nav/tray_calendar_unfill.svg";
import CalendarActive from "components/assets/nav/tray_calendar_fill.svg";

import PhoneModal from "components/Author/PhoneModal";
import { useUserData } from "actions/firebase";

import "./css/main.css";

const mapStateToProps = (state) => {
  return {
    theme: state.theme,
  };
};

const FloatingNav = ({ theme }) => {
  const location = useLocation();
  const [isCalendarActive, setIsCalendarActive] = useState(false);
  const [isProfileActive, setIsProfileActive] = useState(false);
  const [isFavorited, setIsFavorited] = useState(false);
  const [isMapActive, setIsMapActive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userData = useUserData();
  const navigate = useNavigate();

  useEffect(() => {
    setIsCalendarActive(location.pathname === "/");
    setIsProfileActive(userData && location.pathname.indexOf("/profile") === 0);
    setIsFavorited(location && location?.pathname?.includes("favorites"));
    setIsMapActive(location && location?.pathname?.includes("home/globemap"));
  }, [location]);

  const handleLogin = () => {
    setIsModalOpen(true);
  };

  const handleFavorite = () => {
    navigate("/favorites");
  };

  const handleMap = () => {
    navigate("/home/globemap");
  };

  const onLogin = () => {
    navigate("/profile/personalinfo");
  };

  let mobileView = false;
  if (window.innerWidth < 680) {
    mobileView = true;
  }

  return (
    <>
      <div
        className="floating-nav"
        style={{ display: mobileView ? "show" : "none" }}
      >
        <div className="floating-nav__icon">
          <Link to="/">
            <img
              className="floating-nav__img"
              src={isCalendarActive ? CalendarActive : Calendar}
            />
          </Link>
        </div>
        <div className="floating-nav__icon">
          <div onClick={handleMap} style={{ cursor: "pointer" }}>
            <img
              className="floating-nav__img"
              src={isMapActive ? MapFill : Map}
            />
          </div>
        </div>
        <div className="floating-nav__icon">
          <div
            onClick={userData ? handleFavorite : handleLogin}
            style={{ cursor: "pointer" }}
          >
            <img
              className="floating-nav__img"
              src={isFavorited ? Heart_Fill : Heart}
            />
          </div>
        </div>
        <div
          className={`floating-nav__icon ${
            isProfileActive ? "floating-nav__profile-active" : ""
          }`}
          style={{
            backgroundImage: theme ? `url("${netDark}")` : `url("${netLight}")`,
          }}
        >
          {userData ? (
            <Link to="/profile/personalinfo">
              <div>
                <img
                  className="floating-nav__profile-img"
                  src={
                    userData.imageUrl ||
                    (theme ? profileDefaultDark : profileDefaultLight)
                  }
                />
              </div>
            </Link>
          ) : (
            <div onClick={handleLogin}>
              <img className="floating-nav__img" src={Profile} />
            </div>
          )}
        </div>
      </div>

      <PhoneModal
        isModalOpen={isModalOpen} // data from parent to child
        setIsModalOpen={setIsModalOpen} // data from child to parent
        onSuccess={onLogin} // data from child to parent
      />
    </>
  );
};

export default connect(mapStateToProps)(FloatingNav);
