import { getDropCategoryIcon } from "components/common/getIconsModule"
import moment from "moment";

const CategoryBadge = ({category_name, theme}) => {
  return <button
    className="pinbtn font12 category_badge"
    style={{
      background: !theme
        ? getDropCategoryIcon(category_name).background
        : "transparent",
      border: `1px solid ${getDropCategoryIcon(category_name).color}`,
    }}
  >
    <img
      src={
        theme
          ? getDropCategoryIcon(category_name).icon
          : getDropCategoryIcon(category_name).icon_dark
      }
      alt=""
    />
    {category_name}
  </button>
}

export default CategoryBadge;