import React, { useState, useEffect, useMemo } from "react";
import VideoPlayer from "react-background-video-player";
import { connect } from "react-redux";
import { AiOutlineDown } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Drawer, Button, Divider } from "antd";

import avatar_icon from "components/assets/icons/empty_avatar.svg";
import video_play from "components/assets/video_play.svg";
import SkeletonComponent from "components/Skeleton/index";
import { getDropCalendar } from "actions/firebase";
import TypeBadge from "./TypeBadge";
import CategoryBadge from "./CategoryBadge";
import ShareIcon from "components/common/ShareIcon";
import SelectModal from "./SelectModal";
import CloseButton from "components/common/CloseButton";
import { useUserData } from "actions/firebase";

import heart from "../assets/heart.svg";
import heart_favorited from "../assets/heart_red.svg";
import { addFavorite } from "actions/firebase";

const mapStateToProps = (state) => ({
  theme: state.theme,
});
const isVideo = (src) => /\.(mov|mp4)/i.test(src);

function ProductModal({
  id,
  handleCloseCard,
  drawerHeight,
  handleAddToBag,
  drawerClassName,
  dropData,
  theme,
  category_name,
  dropType,
  showCard,
  setShowVideo,
  showNotification,
  showLoginedNotification,
  setShowNotification,
  setShowLoginedNotification,
  setImageIdx,
  isFavorited = false,
  updateProductList,
}) {
  const [showPlayButton1, setShowPlayButton1] = useState("none");
  const [logined, setLogin] = useState(false);
  const [favor, setFavor] = useState(isFavorited);
  const mobileView = window.innerWidth < 680;
  const [dropCalendarData, setDropCalendarData] = useState();
  const [loadingDrop, setLoadingDrop] = useState(true);
  const [shareLink, setShareLink] = useState();
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectModalVisible, setSelectModalVisible] = useState(false);
  const handleSelectColor = (color) => setSelectedColor(color);
  const handleSelectSize = (size) => setSelectedSize(size);
  const userData = useUserData();
  const buildShareLink = ({ dropData }) => {
    const link = `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ""
    }${dropData.path}`;
    setShareLink(link);
  };

  const handleVideo = () => {
    setShowVideo(true);
  };

  const handlePlayBtn1 = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowPlayButton1("block");
  };

  const handleFavorite = () => {
      if (logined) {
      addFavorite({
        idProduct: id,
        isSkip: favor,
      })
        .then((res) => {
          if (!favor) setShowLoginedNotification(!showLoginedNotification);
          setFavor(!favor);
          console.log("Add Product: ", id, res);

          updateProductList();
        })
        .catch((error) => {
          console.log("Error Add Produce: ", error);
        });
    } else {
      setShowNotification(!showNotification);
    }
  };

  const isBuyEnabled = useMemo(() => {
     if (loadingDrop) {
      return false;
    } else {
      const hasSizes = Boolean(
        dropCalendarData?.dropData?.availableSizes?.length
      );
      return hasSizes ? selectedSize : true;
    }
  }, [dropCalendarData, selectedSize, loadingDrop]);

  useEffect(() => {
    
    const loggedIn = Boolean(userData);
    setLogin(loggedIn);
    /*
    try {
      const loggedObj1 = localStorage.getItem("logined");
      const loggedObj2 = localStorage.getItem("login");

      console.log(loggedObj1, loggedObj2);
      const loggedIn =
        (loggedObj1 ? JSON.parse(loggedObj1) : false) ||
        (loggedObj2 ? JSON.parse(loggedObj2) : false);
      console.log({ loggedIn });
      setLogin(loggedIn);
    } catch (error) {}
    */
    
  }, []);

  useEffect(() => {
    if (dropData) {
      setDropCalendarData(dropData);
      buildShareLink(dropData);
    } else if (id && showCard) {
      setLoadingDrop(true);
      getDropCalendar({ productId: id }).then((result) => {
        console.log({ result });
        setDropCalendarData(result);
        setLoadingDrop(false);
        buildShareLink(result);
      });
    }
  }, [id, showCard, dropData]);

  return (
    <Drawer
      className="product_details_panel"
      placement={mobileView ? "bottom" : "right"}
      closable={false}
      onClose={handleCloseCard}
      open={showCard}
      style={{ height: drawerHeight }}
      bodyStyle={{ display: !showCard ? "none" : "block" }}
    >
      <div
        className="mobile_version"
        style={{ height: 200, background: "transparent" }}
      />
      {selectModalVisible && <div className="overlay"></div>}
      <div className={`tray ${selectModalVisible ? "white" : ""}`}>
        <div className="space_between">
          <div className="font14">
            {loadingDrop ? (
              <SkeletonComponent height={32} width={138} borderRadius="32px" />
            ) : (
              <Button type="round" style={{ color: "black" }}>
                {dropCalendarData?.spaceData.name || "Unknown"}
              </Button>
            )}
          </div>
          <CloseButton
            onClick={handleCloseCard}
            className="close_button_pointer"
          />
        </div>
        <div>
          <Swiper
            spaceBetween={16}
            slidesPerView={1.2}
            breakpoints={{
              420: {
                slidesPerView: 2.1,
              },
            }}
          >
            {dropCalendarData?.dropData?.images?.length &&
              dropCalendarData?.dropData?.images?.map((el, idx) => (
                <SwiperSlide
                  key={el.id}
                  onMouseOver={(e) => handlePlayBtn1(e)}
                  onMouseLeave={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowPlayButton1("none");
                  }}
                >
                  {loadingDrop ? (
                    <SkeletonComponent height="280px" width="200px" />
                  ) : isVideo(el.src) ? (
                    <div
                      className="video_background"
                      style={{ height: "280px" }}
                      onClick={() => setImageIdx(idx)}
                    >
                      <VideoPlayer
                        className="video_cover"
                        src={el.src}
                        autoPlay
                        muted
                        playsInline
                        style={{ borderRadius: "10px" }}
                      />
                    </div>
                  ) : (
                    <div
                      className="video_image"
                      style={{ backgroundImage: `url(${el.src})` }}
                      onClick={() => {
                        handleVideo();
                        setImageIdx(idx);
                      }}
                    />
                  )}
                  {isVideo(el.src) && (
                    <img
                      className="video_play"
                      onClick={handleVideo}
                      src={video_play}
                      alt=""
                      style={{ display: showPlayButton1 }}
                    />
                  )}
                </SwiperSlide>
              ))}
          </Swiper>
        </div>

        <div className="space_between">
          <div style={{ display: "grid" }}>
            {loadingDrop ? (
              <SkeletonComponent height={20} width={138} />
            ) : (
              <label className="font16">
                {dropCalendarData?.dropData.name}
              </label>
            )}
            {loadingDrop ? (
              <SkeletonComponent height={10} width={108} />
            ) : (
              <label className="font12_6">
                {dropCalendarData?.spaceData.name || "Unknown"}
              </label>
            )}
          </div>
          <div className="d-flex" style={{ gap: 10 }}>
            <div>
              <ShareIcon link={shareLink} theme={theme} />
            </div>
          </div>
        </div>
        <div>
          <Divider />
        </div>
        <div className="d-flex">
          <CategoryBadge category_name={category_name} theme={theme} />
          <TypeBadge
            dropType={dropType}
            releaseDts={dropCalendarData?.dropData?.releaseDts}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "20px 0",
            gap: 10,
          }}
        >
          {loadingDrop ? (
            <SkeletonComponent height={40} width={45} circle />
          ) : (
            <>
              <div className="product__variant">
                <div
                  className="select-button"
                  onClick={() => setSelectModalVisible(true)}
                >
                  <div className="select-button__text">
                    {selectedSize || "Size"}
                  </div>
                  <div className="select-button__arrow">
                    <AiOutlineDown />
                  </div>
                </div>
              </div>
            </>
          )}
          {dropCalendarData?.dropData?.colors?.length &&
            (loadingDrop ? (
              <SkeletonComponent height={40} width={45} circle />
            ) : (
              <div className="size_part">
                {dropCalendarData?.dropData?.colors?.length
                  ? dropCalendarData?.dropData.colors.map((e) => (
                      <div
                        key={e.name + e.hex}
                        className="color_button"
                        style={{ background: `#${e.hex}` }}
                        onClick={() => handleSelectColor(e)}
                      />
                    ))
                  : null}
              </div>
            ))}
        </div>

        <div className="drawer_contents">
          <div style={{ width: "100%", margin: "20px 0" }}>
            {loadingDrop ? (
              <SkeletonComponent height={10} width={270} count={3} />
            ) : (
              <label className="font12_6">
                {dropCalendarData?.dropData.description}
              </label>
            )}
          </div>

          <div className="d-flex">
            {loadingDrop ? (
              <SkeletonComponent
                style={{ marginLeft: "10px" }}
                height={10}
                width={100}
                count={1}
              />
            ) : (
              <label className="font12">
                {dropCalendarData?.dropData.returnPolicy}
              </label>
            )}
          </div>
          <div style={{ display: "flex" }}>
            {dropCalendarData?.dropData?.tags?.length ? (
              <div style={{ marginTop: 15 }}>
                <label className="font12_6" style={{ marginBottom: 10 }}>
                  Tags
                </label>
                <div>
                  {dropCalendarData?.dropData.tags.map((e) => (
                    <button key={e} className="tags_button">
                      #{e}
                    </button>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div>
          <Divider />
        </div>
        <div className="space_between">
          <div>
            <label className="font12_6">Drop from</label>
          </div>
          <Link to={`/${dropCalendarData?.creatorData.creatorHandle}`}>
            <div className="d-flex">
              <label className="font12">
                {dropCalendarData?.creatorData.creatorName}
              </label>
              <img
                src={
                  dropCalendarData?.creatorData.creatorProfilePicture ||
                  avatar_icon
                }
                alt=""
                style={{ marginLeft: 10, width: 40, clipPath: "circle()" }}
              />
            </div>
          </Link>
        </div>
        <div style={{ height: 100 }}></div>
      </div>
      <div
        className={`${drawerClassName} space_between add_bag_btn ${
          isBuyEnabled ? "enabled" : "disabled"
        }`}
      >
        <div
          style={{
            width: "30%",
            borderRight: "1px solid rgb(0,0,0,0.2)",
            paddingRight: 15,
          }}
          className={drawerClassName !== "" ? "opacity_bag" : ""}
        >
          <div className="space_between">
            <label className="font12_6 white_font">Price</label>
            <label className="total_price font20 white_font">
              ${dropCalendarData?.dropData.price}
            </label>
          </div>
        </div>
        <div className={drawerClassName !== "" ? "opacity_bag" : ""}>
          {isBuyEnabled ? (
            <div
              className="font20 white_font"
              style={{ cursor: "pointer" }}
              onClick={() =>
                handleAddToBag(selectedSize, selectedColor, dropCalendarData)
              }
            >
              add to bag
            </div>
          ) : (
            <div className="font20 white_font">select size</div>
          )}
        </div>
        <div className={drawerClassName !== "" ? "opacity_bag" : ""} />
        <img
          src={favor ? heart_favorited : heart}
          alt=""
          style={{ cursor: "pointer" }}
          onClick={handleFavorite}
        />
      </div>
      {selectModalVisible && (
        <SelectModal
          sizes={dropCalendarData?.dropData.availableSizes}
          value={selectedSize}
          onChange={(val) => {
            if (val) {
              handleSelectSize(val);
            }
            setSelectModalVisible(false);
          }}
        />
      )}
    </Drawer>
  );
}

export default connect(mapStateToProps)(ProductModal);
