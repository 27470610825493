import raffle from "components/assets/icons/raffle.svg";
import raffle_dark from "components/assets/icons/raffle_dark.svg";
import now from "components/assets/icons/now.svg";
import now_dark from "components/assets/icons/now_dark.svg";
import presale from "components/assets/icons/presale_dark.svg";
import presale_dark from "components/assets/icons/presale_dark.svg";
import pin from "components/assets/icons/pin.svg";
import pin_dark from "components/assets/icons/pin_dark.svg";

import fashion from "components/assets/icons/fashion.svg";
import fashion_dark from "components/assets/icons/fashion_dark.svg";
import beauty from "components/assets/icons/beauty.svg";
import beauty_dark from "components/assets/icons/beauty_dark.svg";
import goods from "components/assets/icons/goods.svg";
import goods_dark from "components/assets/icons/goods_dark.svg";
import tech from "components/assets/icons/tech.svg";
import tech_dark from "components/assets/icons/tech_dark.svg";

export const getDropTypeIcon = (dropType) => {
  const lowercaseType = dropType?.toLowerCase();
  switch (lowercaseType) {
    case "raffle":
      return {
        icon: raffle,
        icon_dark: raffle_dark,
        color: "#40C397",
      };
    case "now":
      return {
        icon: now,
        icon_dark: now_dark,
        color: "#4B355A",
      };
    case "presale":
      return {
        icon: presale,
        icon_dark: presale_dark,
        color: "#F0E681",
      };
    case "pin":
      return {
        icon: pin,
        icon_dark: pin_dark,
        color: "#3784AB",
      };
    default:
      return {
        icon: raffle,
        icon_dark: raffle_dark,
        color: "#40C397",
      };
  }
};

export const getDropCategoryIcon = (category) => {
  const lowercaseStatus = category?.toLowerCase();
  switch (lowercaseStatus) {
    case "fashion":
      return {
        icon: fashion,
        icon_dark: fashion_dark,
        color: "#4B355A",
        background: "#4B355A33",
      };
    case "beauty":
      return {
        icon: beauty,
        icon_dark: beauty_dark,
        color: "#F0E681",
        background: "#F0E68133",
      };
    case "goods":
      return {
        icon: goods,
        icon_dark: goods_dark,
        color: "#40C397",
        background: "#40C39733",
      };
    case "tech":
      return {
        icon: tech,
        icon_dark: tech_dark,
        color: "#3784AB",
        background: "#3784AB33",
      };
    default:
      return {
        icon: tech,
        icon_dark: tech_dark,
        color: "#3784AB",
        background: "#3784AB33",
      };
  }
};
