import { useEffect, useState } from "react";
import { initializeApp, getApps } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getAuth, signOut, onAuthStateChanged, RecaptchaVerifier, signInWithPhoneNumber, signInWithCustomToken } from "firebase/auth";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { updateUserData } from "utils/utils";
import { addToCart } from "actions/bag";
import * as utils from "utils/utils";

export const firebaseConfig = {
  apiKey: "AIzaSyCtzveYVKunyJZfK4Ptklgc8CDQI-qdyAw",
  authDomain: "shop-aura.firebaseapp.com",
  databaseURL: "https://shop-aura.firebaseio.com",
  projectId: "shop-aura",
  storageBucket: "shop-aura.appspot.com",
  messagingSenderId: "188819437988",
  appId: "1:188819437988:web:d42a9609bc9af7ced8a931",
  measurementId: "G-4PCWBG6V77"
}
const apps = getApps();
let app = apps.length ? apps[0] : null;

if (apps.length === 0) {
  app = initializeApp(firebaseConfig);
}

const firebaseFunctions = getFunctions();
const auth = getAuth(app);
let userData;

export const extendDropData = (calendarDropData) => {
  const { rawProduct, dropData } = calendarDropData;
  const product = rawProduct;

  Object.assign(dropData, {
    type: product.type,
    name: product.name,
    brand: product.brandName,
    release: product.releaseDts ? product.releaseDts._seconds : null,
    category: product.category,
    dropType: product.dropType,
    price: product.price,
    colors: product.dropColors,
    description: product.description,
    tags: product.tags,
    returnPolicy: product.returnPolicy,
    picture: product.imageUrl,
    coverImage: product.coverImage || { src: product.imageUrl },
    images: product.images,
    variants: product.variants || [],
    releaseDts: product.releaseDts || product.createdDts,
  });
}

export const useUserId = () => {
  const [userId, setUserId] = useState(auth.currentUser?.uid);
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      const uid = user.uid;
      if (uid !== userId) {
        setUserId(uid);
      }
      // ...
    } else {
      // User is signed out
      // ...
      if (userId) {
        setUserId(null);
      }
    }
  });
  return userId;
}

export const logout = async () => {
  await signOut(auth);
}

export const usePhoneConfirmation = (phoneNumber, id) => {
  const [confirmationResult, setConfirmationResult] = useState();

  useEffect(() => {
    if (!phoneNumber || !id) {
      return;
    }

    const appVerifier = new RecaptchaVerifier(id, {
      'size': 'invisible',
      'callback': response => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // onSignInSubmit();
        console.log('success', response)
      }
    }, auth);

    signInWithPhoneNumber(auth, phoneNumber, appVerifier).then(result => {
      setConfirmationResult(result)
    }).catch(e => {
      console.error('sign in error ' + e.message)
      setConfirmationResult(false)
      appVerifier.render().then(function (widgetId) {
        window.grecaptcha.reset(widgetId);
      });
    })
  }, [phoneNumber])

  return confirmationResult;
}

export const signInWithToken = async ({ customToken }) => {
  if (customToken) {
    console.log('Custome Token: ', customToken);
    try {
      const credential = await signInWithCustomToken(auth, customToken);
      console.log('UserCredential: ', credential);
    } catch (error) {
      console.log('Token SignIn Error: ', error);
    }
  }
}

export const verifyConfirmationCode = async (confirmationResult, code) => {
  try {
    await confirmationResult.confirm(code)
    return true;
  }
  catch (e) {
    console.error('verify confirmation error: ' + e.message)
    return false;
  }
}

export const getUserData = async () => {
  const fn = httpsCallable(firebaseFunctions, 'getUser');
  const { data } = await fn({});
  return data;
};

export const getAccessToken = async () => {
  const fn = httpsCallable(firebaseFunctions, 'getAccessToken');
  const { data } = await fn({});
  return data;
};

export const getAccessToken2 = async () => {
  const fn = httpsCallable(firebaseFunctions, 'getAccessToken2');
  const { data } = await fn({});
  return data;
};

export const getCustomToken = async () => {
  const fn = httpsCallable(firebaseFunctions, 'getCustomToken');
  const { data } = await fn({});
  return data;
};

export const getSpaceDataByUserName = async (username) => {
  const fn = httpsCallable(firebaseFunctions, 'getCreatorSpaceDataByUsername');
  const { data } = await fn({ username });
  return data;
};

export const useUserData = () => {
  const [data, setData] = useState(userData);

  if (userData === undefined && auth?.currentUser?.uid) {
    userData = null;
    getUserData().then(({ user }) => updateUserData(user))
  }

  window.addEventListener('userDataUpdated', e => {
    let updatedData;
    if (e.detail) {
      updatedData = {
        ...(userData || {}),
        ...e.detail
      };
    }
    userData = updatedData;
    setData(updatedData);
  });

  onAuthStateChanged(auth, user => {
    if (user) {
      if (userData === undefined) {
        userData = null;
        getUserData().then(({ user }) => updateUserData(user))
      }
    } else {
      userData = undefined;
      updateUserData();
    }
  });
  return data;
}

export const setUserPreferences = async (updates) => {
  const fn = httpsCallable(firebaseFunctions, 'setUserPreferences');
  const { data } = await fn(updates);
  return data;
};

export async function uploadFile(info, folder, filename) {
  const storage = getStorage(app);
  const file = info.file;
  const fileExt = file.type.split('/').pop();
  const fileName = (filename || file.uid) + '.' + fileExt;
  const storageRef = ref(storage, `${folder}/${fileName}`);
  const snapshot = await uploadBytes(storageRef, file);
  return await getDownloadURL(snapshot.ref);
}

export const getSimilarCreators = async (options = {}) => {
  const { creatorId, creatorName, limit } = options;
  const fn = httpsCallable(firebaseFunctions, 'getSimilarCreators');
  const { data } = await fn({ creatorId, creatorName, limit });
  return data;
};

export const getSpaceData = async (options = {}) => {
  const { name } = options;
  const fn = httpsCallable(firebaseFunctions, 'getUserSpaceData');
  const { data } = await fn({ name: name, vanity: name, startAfter: null, limit: 0, showUnavailable: true });
  return data;
};

export const updateSpaceData = async (options = {}) => {
  const { idGroup } = options;
  const fn = httpsCallable(firebaseFunctions, 'updateUserSpace');
  const { data } = await fn(options);
  return data;
};

/**
 * Connect endpoints
 */

export const addConnection = async ({ username, idUser }) => {
  const fn = httpsCallable(firebaseFunctions, 'addConnection');
  const { data } = await fn({ username, idUser });
  return data;
};

export const removeConnection = async ({ username, idUser }) => {
  const fn = httpsCallable(firebaseFunctions, 'removeConnection');
  const { data } = await fn({ username, idUser });
  return data;
};

export const getConnections = async () => {
  const fn = httpsCallable(firebaseFunctions, 'getConnections');
  const { data } = await fn();
  return data;
};

export const getConnectionStatus = async ({ username }) => {
  const fn = httpsCallable(firebaseFunctions, 'getConnectionStatus');
  const { data } = await fn({ username });
  return data;
};

/**
 * Product endpoints
 */

export const getDropCalendar = async ({ productId, vanity, productSlug }) => {
  const fn = httpsCallable(firebaseFunctions, 'getDropCalendar');
  const { data } = await fn({ productId, vanity, productSlug });
  extendDropData(data);
  return data;
};

export const getProductsFeed = async ({ limit, startAfter }) => {
  const fn = httpsCallable(firebaseFunctions, 'getProductsFeed');
  const { data } = await fn({ limit, startAfter });
  return data;
};

export const getProductsListForUser = async ({ username, spaceId, limit, startAfter }) => {
  const fn = httpsCallable(firebaseFunctions, 'getProductsListForUser');
  const { data } = await fn({ username, spaceId, limit, startAfter });
  return data;
};

/**
 * Payment endpoints
 */

export const getPaymentIntent = async ({ email, shipping, paymentMethod }) => {
  const cartData = JSON.parse(window.localStorage.getItem("bag_data"));
  const { cart } = await addToCart(cartData);
  const fn = httpsCallable(firebaseFunctions, 'webPaymentIntent');
  const { data } = await fn({ cart, email, shipping, paymentMethod, isTestMode: utils.getStripeTestMode() })
  return data;
};

export const estimateCosts = async () => {
  const cartData = JSON.parse(window.localStorage.getItem("bag_data"));
  const { cart } = await addToCart(cartData);
  const fn = httpsCallable(firebaseFunctions, 'estimateCosts');
  const { data } = await fn({ cart });
  return data;
};


/**
 * Order endpoints
 */
export const getGroupOrders = async (options = {}) => {
  const { idGroup } = options;
  const fn = httpsCallable(firebaseFunctions, 'getGroupOrders');
  const { data } = await fn({ idGroup: idGroup });
  return data;
};

export const getUserOrder = async (options = {}) => {
  const { uid } = options;
  const fn = httpsCallable(firebaseFunctions, 'getUserOrder');
  const { data } = await fn({ uid: uid });
  return data;
};

export const getUserOrders = async (options = {}) => {
  const { uid } = options;
  const fn = httpsCallable(firebaseFunctions, 'getOrders');
  const { data } = await fn({ idUser: uid, limit: 10 });
  return data;
};

export const getUserOrderConfirmation = async (options = {}) => {
  const { userId, isTestMode } = options;
  const fn = httpsCallable(firebaseFunctions, 'getUserOrderConfirmation');
  const { data } = await fn({ userId, isTestMode });
  return data;
};

export const getFavorites = async (options = {}) => {
  const { isSkip, startAfterDate } = options;
  const fn = httpsCallable(firebaseFunctions, 'getFavorites');
  const { data } = await fn({ isSkip, startAfterDate });
  return data;
};

export const addFavorite = async (options = {}) => {
  const { idProduct, isSkip, idVariant } = options;
  const fn = httpsCallable(firebaseFunctions, 'addFavorite');
  const { data } = await fn({ idProduct, isSkip, idVariant });
  return data;
};