import PerformanceMain from "components/Performance/PerformanceMain";

function Performance() {

  return (
    <div className="performance">
      <PerformanceMain />
    </div>
  );
}
export default Performance;
