import { useSelector } from 'react-redux';

import dark404 from 'components/assets/404_link_dark.svg';
import light404 from 'components/assets/404_link_light.svg';
import './connected.css'

const LinkEmpty = () => {

    const theme = useSelector(state => state.theme);
    const img_404 = theme ? dark404 : light404;

    return (
        <div className='link_empty_container'>
            <img src={img_404} className='link_empty_image' />
            <div className='font16_6 link_empty_font'>{`You haven't made any connections yet. Your links will show up here.`}</div>
        </div>
    );
}

export default LinkEmpty;