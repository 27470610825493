import { connect } from 'react-redux';

import startJourney from 'components/assets/start_journey.svg';

import left_star_light from 'components/assets/home/left_star_light.svg';
import left_star_dark from 'components/assets/home/left_star_dark.svg';
import right_star_light from 'components/assets/home/right_star_light.svg';
import right_star_dark from 'components/assets/home/right_star_dark.svg';

import { isIOS } from 'utils/utils';
import './banner.css';

function Banner(props) {
    const { theme } = { ...props };

    return (
        <div className="banner">
            <div className='h-100'>
                <img className='star_front' src={theme ? left_star_dark : left_star_light} alt='star_front' />
            </div>
            <div className='banner_content'>
                <div className='banner_text'>
                    <label className='font24 drop_text'>A Release Company</label>
                    <label className='mid_content become_text font16_6 d-flex align-items-center'><a href="https://collective.auradrops.com/">Learn More</a></label>
                </div>

                <div className='banner_app'>
                    <a href='https://download.auradrops.com/'>
                        <div className='row'>
                            <div className='col-12'>
                                <label className='font16_6'>Start your journey</label>
                            </div>
                            <div className='col-12'>
                                <img className='m-2' src={startJourney} alt='Start Your Journey' />
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div className='video_player'>
                {!isIOS() && <video autoPlay muted loop id="video"  >
                    <source src='/banner.mp4' type="video/mp4" />
                </video>}
            </div>
            <div className='mobile_version star'>
                <img src={theme ? right_star_dark : right_star_light} alt='star' />
            </div>
        </div >
    )
}
const mapStateToProps = state => {
    return {
        theme: state.theme,
    };
};

export default connect(mapStateToProps)(Banner);