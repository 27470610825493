import Modal from "react-bootstrap/Modal";
import { useState, useMemo, useCallback, useEffect } from "react";
import _ from "lodash";
import Loader from "components/common/loader";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "./checkout.css";
import PhoneNumber from "./PhoneNumber";
import OrderedProduct from "./OrderedProduct";
import { useUserData } from "actions/firebase";
import { Tabs, Input, Menu, message } from "antd";
import SkeletonComponent from "components/Skeleton/index";
import Success from "./success";
import Failed from "./failed";
import Login from "./Login";
import { IoIosAlert } from "react-icons/io";
import { connect, useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { validationSchema } from "schemas/validationSchema";
import { synchronizeBagWithLocalStorage } from "actions/bag";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { HiQuestionMarkCircle } from "react-icons/hi";
import { isValidPhoneNumber } from "react-phone-number-input";
import { shippingFromForm, getCartDetails } from "utils/utils";
import { getPaymentIntent } from "actions/firebase";
import PaymentButton from "./PaymentButton";
import CVCModal from "./CVCModal";
import { TrackGoogleAnalyticsEvent } from "utils/googleAnalytics";
import CloseButton from "components/common/CloseButton";

function CheckOut(props) {
  const dispatch = useDispatch();
  const bagData = useSelector(state => state.bag.dropList);
  const [footerName, setFooterName] = useState("Continue");
  const [activeKey, setActiveKey] = useState("1");
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailed, setShowFailed] = useState(false);
  const [showTax, setShowTax] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [cartDetails, setCartDetails] = useState(null);
  const [isPhoneCorrect, setIsPhoneCorrect] = useState(true);
  const [hasPaymentButton, setHasPaymentButton] = useState(false);
  const [showCVCModal, setShowCVCModal] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const userData = useUserData();

  const theme = useSelector(state => state.theme);

  const CARD_OPTIONS = {
    iconStyle: "solid",
    placeholder: "",
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: theme ? "#fff" : "#111",
        fontWeight: 500,
        fontFamily: "Montserrat, sans-serif",
        fontSize: "13px",
        fontSmoothing: "antialiased",
        ":-webkit-animation": "none",
        ":-webkit-autofill": {
          color: theme ? "#fff" : "#111",
        },
      },
      invalid: {
        iconColor: "ffc7ee",
        color: "ffc7ee",
      },
    },
  };

  const CARD_OPTIONS_CVC = {
    style: {
      base: {
        width: 100,
        iconColor: "#c4f0ff",
        color: theme ? "#fff" : "#111",
        fontWeight: 500,
        // fontFamily: "Vulf Sans",
        fontFamily: "Montserrat, sans-serif",
        fontSize: "13px",
        fontSmoothing: "antialiased",
        ":-webkit-animation": "none",
        ":-webkit-autofill": {
          color: theme ? "#fff" : "#111",
        },
      },
      invalid: {
        iconColor: "ffc7ee",
        color: "ffc7ee",
      },
    },
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      fullName: "",
      phoneNumber: { short: "US", code: 1, phone: "" },
      address: "",
      building: null,
      address2: null,
      zip: null,
      city: "",
      state: "",
      cardNumber: null,
      expiryDate: null,
      cvc: null,
    },
    validationSchema: validationSchema,
  });

  const loading = false;

  const tax = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div>
        <label>
          Aura is required by law to collect sales tax in certain US states on
          behalf of our sellers. We don’t profit from this tax, and it’s not an
          Aura fee.
        </label>
      </div>
    </Tooltip>
  );

  useEffect(() => {
    if (_.isEmpty(bagData)) dispatch(synchronizeBagWithLocalStorage());
  }, [dispatch, bagData]);

  const handleShareTax = () => {
    setShowTax(!showTax);
  };
  const handleDelivery = () => {
    setError("");

    switch (activeKey) {
      case "1":
        setActiveKey("2");
        setFooterName("Continue to payment");
        break;
      case "2":
        setActiveKey("3");
        setFooterName("Place your order");
        break;
      default:
        handleSubmit();
    }
    // case "2":
    // alert(activeKey);
  };

  const onChange = (key) => {
    setActiveKey(key);
  };

  const getDetails = useCallback(async () => {
    const shipping = shippingFromForm(formik.values);
    const { city, address, zip, state } = formik.values;
    if (city && address && zip && state) {
      try {
        const res = await getCartDetails(shipping);
        setCartDetails(res?.data?.result);
      } catch (err) {
        console.log("🚀 ~ getDetails error", err);
      }
    }
  }, [formik.values, bagData]);

  useEffect(() => {
    // TODO: fix hack to update cart data
    if (bagData)
      localStorage.setItem("bag_data", JSON.stringify(bagData));
    getDetails();
  }, [formik.values, getDetails, bagData]);

  useEffect(() => {
    if (!userData) {
      return;
    }

    const initializeFormData = data => {
      for (let key in data) {
        formik.setFieldValue(key, data[key]);
      }

      // Parse phone number
      if (data.phoneNumber) {
        const phoneParts = (data.phoneNumber || '').match(/^\+(\d{1})(\d{10})$/);
        if (phoneParts) {
          formik.setFieldValue("phoneNumber", {
            short: 'US',
            code: phoneParts[1],
            phone: phoneParts[2],
          });
        }
      }
    }

    initializeFormData({
      email: userData.email,
      fullName: userData.name,
      phoneNumber: userData.phoneNumber,
      address: userData.address?.line1,
      address2: userData.address?.line2,
      zip: userData.address?.postal_code,
      city: userData.address?.city,
      state: userData.address?.state
    });

  }, [userData])

  const handleSubmit = async (e) => {

    bagData.forEach(element => {
      TrackGoogleAnalyticsEvent({ category: "Sales", action: `Sales-${userData.username}-${element.creatorHandle}-${element.price * element.count}`, label: element.creatorHandle });
    });

    if (!stripe || !elements) {
      console.log('No Stripe Element Found');
      return;
    }

    const card = elements.getElement(CardNumberElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card,
    });

    if (!error) {
      try {
        setIsLoading(true);
        const shipping = shippingFromForm(formik.values);
        const response = await getPaymentIntent({
          email: formik.values.email,
          shipping
        });
        if (response.error) {
          return;
        }
        const clientSecret = response.clientSecret;


        const result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: paymentMethod.id,
        });
        if (result.error?.code === "card_declined") {
          setShowFailed(true);
          closeModal();
          setIsLoading(false);
        }
        if (result.paymentIntent.status === "succeeded") {
          onSuccessHandler();
        }
      } catch (error) {
        console.log("Error ", error);
      }
    } else {
      console.log(error.message);
    }
  };

  const onSuccessHandler = () => {
    setShowSuccess(true);
    closeModal();
    setIsLoading(false);
    localStorage.setItem("bag_data", JSON.stringify([]));
  }

  const onChangePhoneNumber = (value) => {
    formik.setFieldValue("phoneNumber", value);
    setIsPhoneCorrect(isValidPhoneNumber(`+${value.code}${value.phone}`));
  };

  const tabItems = [
    {
      label: <label className="font14_w4">Contact Info</label>,
      key: String(1),
      children: (
        <>
          <div className="bag-form-input">
            <PhoneNumber
              onChangeHandler={onChangePhoneNumber}
              isCheckout
              phoneNumber={formik.values.phoneNumber}
              className="bag-form-input"
            />
          </div>
          <div>
            <div className="bag-form-input">
              <label className="font12_6 pb-2">Full Name</label>
            </div>
            <Input
              name="fullName"
              placeholder="Full Name"
              value={formik.values.fullName}
              onChange={formik.handleChange}
              className="bag-form-input"
            />
          </div>
          <div>
            <div className="bag-form-input">
              <label className="font12_6">Email</label>
            </div>
            <Input
              name="email"
              placeholder="email@example.com"
              value={formik.values.email}
              onChange={formik.handleChange}
              className="bag-form-input"
            />
          </div>
        </>
      ),
    },
    {
      label: <label className="font14_w4">Shipping</label>,
      key: String(2),
      children: (
        <div>
          <div>
            <label className="font12_6 pb-2 pt-2 bag-form-input">Street</label>
          </div>
          <Input
            name="address"
            value={formik.values.address}
            onChange={formik.handleChange}
            className="bag-form-input"
            type="text"
          />
          <div>
            <div>
              <div>
                <label className="font12_6 pb-2 pt-2 bag-form-input">
                  Street Line 2
                </label>
              </div>
              <Input
                name="address2"
                value={formik.values.address2}
                onChange={formik.handleChange}
                className="bag-form-input"
                type="text"
              />
            </div>
          </div>
          <div>
            <label className="font12_6 pb-2 pt-2 bag-form-input">City</label>
          </div>
          <Input
            name="city"
            value={formik.values.city}
            onChange={formik.handleChange}
            className="bag-form-input"
          />
          <div style={{ display: "flex", gap: 5 }}>
            <div>
              <div className="bag-form-input">
                <label className="font12_6 pb-2 pt-2">State</label>
              </div>
              <Input
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
                className="bag-form-input"
                type="text"
              />
            </div>

            <div>
              <div>
                <label className="font12_6 pb-2 pt-2 bag-form-input">ZIP</label>
              </div>
              <Input
                name="zip"
                value={formik.values.zip}
                onChange={formik.handleChange}
                className="bag-form-input"
                type="number"
                pattern="\d*"
              />
            </div>
          </div>
        </div>
      ),
    },
    {
      label: <label className="font14_w4">Payment</label>,
      key: String(3),
      children: (
        <>
          <div>
            <div>
              <div className="bag-form-input">
                <label className="font12_6 pb-2 pt-2">Credit card number</label>
              </div>
              <CardNumberElement
                options={CARD_OPTIONS}
                type="text"
                pattern="\d*"
              />
            </div>
            <div className="bag-form-input">
              <div className="bag-form-input">
                <label className="font12_6 pb-2 pt-2">Name on card</label>
              </div>
              <Input className="bag-form-input bordered-input" />
            </div>
            <div
              className="space_between"
              style={{ display: "flex", marginTop: 24, gap: 10 }}
            >
              <div className="day_year bag-form-input">
                <label className="font12_6 pb-2 pt-2">Day/Month</label>
                <CardExpiryElement
                  fontFamily="Vulf Sans Demo"
                  options={CARD_OPTIONS_CVC}
                  type="text"
                  pattern="\d*"
                />
              </div>
              <div style={{ flex: "1 0 auto" }}>
                <label className="font12_6 pb-2 pt-2 text-center bag-form-input">
                  CVC
                </label>
                <div className="d-flex align-items-center relative-d bag-form-input">
                  <CardCvcElement
                    family="Vulf Sans Demo"
                    options={CARD_OPTIONS_CVC}
                    type="text"
                    pattern="\d*"
                  />
                  <div style={{ cursor: 'pointer' }} onClick={() => { setShowCVCModal(true) }}>
                    <HiQuestionMarkCircle size={20} />
                  </div>
                </div>
              </div>
            </div>
            {error && <p className="commonError"> {error} </p>}
          </div>
          <CVCModal isShow={showCVCModal} setShow={setShowCVCModal} />
        </>
      ),
    },
  ];



  const closeModal = () => {
    console.log("button closed");
    props.onHide();
  };

  const totalPrice = useMemo(() => {
    let result = 0;
    bagData.length &&
      bagData?.forEach((el) => (result = el.price * el.count + result));
    return result;
  }, [bagData]);

  const totalShippingPrice = useMemo(() => {
    let result = 0;
    bagData.length &&
      bagData?.forEach((el) => {
        if (el.shippingPrice !== "free") {
          result = el.shippingPrice * el.count + result;
        }
      });
    return result;
  }, [bagData]);

  return (
    <>
      <Modal
        onHide={props.onHide}
        show={props.show}
        size="lg"
        centered
      // onHide={handleClose}
      >
        <Modal.Body className="login_part">
          <CloseButton className="close_login checkout_close_mobile" onClick={closeModal} />
          {isLoading ? (
            <div style={{ height: "300px", display: "flex", alignItems: "center" }} >
              <Loader />
            </div>
          ) : (
            <>
              {!userData &&
                <Login />
              }
              <div style={{ display: userData ? 'block' : 'none' }}>
                <div className="checkout_header">
                  <label className="font16">
                    Checkout
                  </label>
                  {totalPrice && <span className="font12_6">Subtotal ${totalPrice}</span>}
                </div>
                <PaymentButton onSuccess={onSuccessHandler} onLoad={() => setHasPaymentButton(true)} total={cartDetails?.total} />
                {hasPaymentButton && <div className="payment-choice-divider">
                  <div></div>
                  <span>or pay with card</span>
                </div>}

                <div className="checkout_body">
                  {userData && <div
                    className="d-flex align-items-center"
                    style={{ gap: 10 }}
                  >
                    <div>
                      <img width={40} style={{ borderRadius: '50%' }} src={userData.imageUrl} alt={userData.name} />
                    </div>
                    <label>{userData.name}</label>
                  </div>}

                  <Tabs
                    centered
                    onChange={onChange}
                    activeKey={activeKey}
                    items={tabItems}
                  />
                </div>

                <div
                  className="checkout_footer text-center font16"
                  onClick={handleDelivery}
                >
                  {footerName}
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Body className="checkout_part">
          <CloseButton className="close_login checkout_close_desktop" onClick={closeModal} />
          <div>
            <div className="checkout_header font16">Order details</div>
            <div className="checkout_body">
              {!_.isEmpty(bagData) &&
                bagData?.map((el) => (
                  <OrderedProduct key={el.id} loading={false} data={el} />
                ))}
            </div>
            <div className="order_footer p-3">
              <div className="space_between m-1">
                <label className="font12_6">Subtotal</label>
                {loading ? (
                  <SkeletonComponent width={40} />
                ) : (
                  <label className="font12 recoleta">${totalPrice}</label>
                )}
              </div>
              <div className="space_between m-1">
                <div className="d-flex">
                  <label className="font12_6 mr-2">Shipping </label>
                </div>
                {loading ? (
                  <SkeletonComponent width={40} />
                ) : (
                  <label className="font12 recoleta">
                    {cartDetails?.shippingTotal > 0
                      ? cartDetails?.shippingTotal
                      : "Free"}
                  </label>
                )}
              </div>
              <div className="space_between m-1">
                <div
                  className="d-flex align-items-center font12_6"
                  style={{ gap: 10 }}
                >
                  <label className="mr-2">Tax </label>
                  <OverlayTrigger
                    rootClose
                    trigger="click"
                    placement="bottom-start"
                    delay={{ show: 250, hide: 400 }}
                    overlay={tax}
                    onExit={() => setShowTax(false)}
                  >
                    <label
                      className="d-flex align-items-end"
                      onClick={handleShareTax}
                    >
                      <IoIosAlert size={20} />
                    </label>
                  </OverlayTrigger>
                </div>
                {loading ? (
                  <SkeletonComponent width={40} />
                ) : (
                  <label className="font12 recoleta">
                    {cartDetails
                      ? `$${cartDetails.tax === 0 ? "0.00" : cartDetails.tax}`
                      : "Calculated on delivery"}
                  </label>
                )}
              </div>
              <div className="order_footer_middle text-center p-2">
                <label className="font12">
                  We pledge 1% of our profit to positive environmental, climate,
                  and social causes.
                </label>
              </div>
              <div className="space_between p-2">
                <label className="font12_6">Order Total</label>
                {loading ? (
                  <SkeletonComponent width={40} />
                ) : (
                  <label className="font16">
                    {cartDetails ? `$${cartDetails.total}` : "—"}
                  </label>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Success show={showSuccess} onHide={() => setShowSuccess(false)} />
      <Failed show={showFailed} onHide={() => setShowFailed(false)} />
    </>
  );
}

export default CheckOut;
