import {
  ADD_ITEM_TO_BAG,
  REMOVE_ITEM_FROM_BAG,
  CHANGE_ITEM_COUNT,
  SYNCHRONIZE_WITH_LOCAL_STORE,
} from "actions/types";

export const synchronizeBagWithLocalStorage = () => async (dispatch) => {
  try {
    const getBagData = JSON.parse(localStorage.getItem("bag_data"));
    if (getBagData && getBagData.length) {
      dispatch({
        type: SYNCHRONIZE_WITH_LOCAL_STORE,
        payload: getBagData,
      });
    } else {
      console.log("synchronizeBagWithLocalStorage - Bag data is empty");
    }
  } catch (error) {
    localStorage.removeItem("bag_data")
  }
};

export const addItemToBag = (item, addtionalData, type) => async (dispatch) => {
  const getBagData = JSON.parse(localStorage.getItem("bag_data"));
  if (getBagData) {
    /*
    if (addtionalData) {
      console.log("addtionalData", addtionalData);
      addToCart(
        addtionalData.product,
        addtionalData.idVariant,
        addtionalData.variantQuantity
      );
    }
    */

    const isOrderExist = getBagData.find((e) => e.id === item.id);
    const filteredList = getBagData.map((e) => {
      if (e.id === item.id) {
        return { ...e, count: e.count + 1 };
      }
      return e;
    });
    if (!isOrderExist) {
      localStorage.setItem("bag_data", JSON.stringify([...filteredList, item]));
    } else {
      if (type !== "update") {
        localStorage.setItem("bag_data", JSON.stringify(filteredList));
      }
    }
  } else {
    localStorage.setItem("bag_data", JSON.stringify([item]));
  }

  dispatch({
    type: ADD_ITEM_TO_BAG,
    payload: item,
  });
};

export const addToCart = async (data) => {
  // const cartData = getCartData();
  let cartData = {
    cart: [],
  };

  data.forEach((cart) => {
    let { product, idVariant, variantQuantity, count } = cart;
    let productData = product;
    let quantity = variantQuantity;
    let vid = idVariant;

    if (vid && !quantity) {
      idVariant = vid;
    } else if (!vid && (product.variants || []).length === 0) {
      idVariant = product.variants[0].id;

      productData = product;
    } else {
      idVariant = vid || productData.variants[0].id;
    }

    let selectedVariant = product.variants.find(
      (variant) => variant.id === idVariant
    );
    // Check for 0 quantity
    if (!selectedVariant.quantity) {
      quantity = 0;
    }

    let groupIndex = cartData.cart.findIndex(
      (item) => item.idGroup === productData.idGroup
    );
    let group = cartData.cart[groupIndex];
    if (!group) {
      group = {
        idGroup: productData.idGroup,
        items: [],
      };
      cartData.cart.push(group);
    }

    let variantIndex = group.items.findIndex(
      (item) =>
        item.idProduct === productData.idProduct && item.idVariant === idVariant
    );
    let variant = variantIndex >= 0 ? group.items[variantIndex] : null;
    if (quantity === 0) {
      if (variant) {
        group.items.splice(variantIndex, 1);
        if (group.items.length === 0) {
          cartData.cart.splice(groupIndex, 1);
        }
      }
    } else if (variant) {
      if (typeof quantity === "number") {
        variant.quantity = quantity;
      } else {
        variant.quantity++;
      }

      // Cannot exceed max quantity
      if (variant.quantity > selectedVariant.quantity) {
        variant.quantity = selectedVariant.quantity;
      }
    } else {
      variant = {
        idProduct: productData.idProduct,
        idVariant: idVariant,
        quantity: count || 1,
        price: selectedVariant.price
      };
      group.items.push(variant);
    }
  });

  // localStorage.setItem("additionalCartData", JSON.stringify(cartData));
  // cartData.count = countCartItems('cartData');
  // console.log(cartData);
  return cartData;
};

const countCartItems = (data) => {
  let count = 0;
  if (data && data.cart && data.cart.length) {
    data.cart.forEach((group) =>
      group.items.forEach((item) => (count = count + item.quantity))
    );
  }
  return count;
};

const getCartData = () => {
  let cartData = {};
  try {
    cartData = JSON.parse(localStorage.getItem("additionalCartData") || "{}");
  } catch (e) { }
  cartData = cartData || {};

  if (!cartData.cart) {
    cartData.cart = [];
  }
  cartData.count = countCartItems(cartData);
  return cartData;
};

export const removeItemFromBag = (itemId) => async (dispatch) => {
  // const getAdditionalData = JSON.parse(localStorage.getItem("additionalCartData"));
  const getBagData = JSON.parse(localStorage.getItem("bag_data"));
  if (getBagData) {
    // const filteredAdditionalData =
    //   getAdditionalData.cart.length && getAdditionalData.cart.map((itemCart) => {
    //       if (itemCart.items && itemCart.items.length) {
    //         return {...itemCart, items: itemCart.items.filter((item) => item.idProduct !== itemId)};
    //       }
    //   })
    //   .filter((itemCart) => itemCart.items.length);

    // getAdditionalData.cart = filteredAdditionalData;

    // localStorage.setItem("additionalCartData", JSON.stringify(getAdditionalData));

    const filteredOrders =
      getBagData.length && getBagData.filter((e) => e.id !== itemId);
    localStorage.setItem("bag_data", JSON.stringify(filteredOrders));
  }
  dispatch({
    type: REMOVE_ITEM_FROM_BAG,
    payload: itemId,
  });
};

export const changeItemsCount =
  (itemId, count, idGroup) => async (dispatch) => {
    const getBagData = JSON.parse(localStorage.getItem("bag_data"));
    // const additionalCartData = JSON.parse(
    //   localStorage.getItem("additionalCartData")
    // );
    // console.log("🚀 ~ additionalCartData", additionalCartData);

    // const updatedAdditionalCartData =
    //   additionalCartData?.cart.length &&
    //   additionalCartData?.cart.map((el) => {
    //     let changed_data;
    //     if (idGroup === el.idGroup) {
    //       changed_data = el.items.map((item) => {
    //         if (itemId === item.idProduct) {
    //           return { ...item, quantity: count };
    //         }
    //         return item;
    //       });
    //     }
    //     return changed_data
    //       ? { ...el, items: changed_data }
    //       : { ...el, items: el.items };
    //   });
    // const res = {
    //   cart: updatedAdditionalCartData,
    //   count: additionalCartData.count,
    // };
    // if (res) {
    //   localStorage.setItem("additionalCartData", JSON.stringify(res));
    // }
    const updatedList =
      getBagData.length &&
      getBagData.map((el) => {
        if (itemId === el.id) {
          return { ...el, count };
        }
        return el;
      });
    if (getBagData) {
      localStorage.setItem("bag_data", JSON.stringify(updatedList));
    }
    dispatch({
      type: CHANGE_ITEM_COUNT,
      payload: { itemId, count },
    });
  };
