import "./css/main.css";
import _ from "lodash";
import GraphicCard from "./GraphicCard";
import Loader from "./Loader";
import "swiper/css";
import React, {
  useCallback,
  useRef,
  useEffect,
  useMemo,
  useState,
} from "react";
import { connect, useDispatch } from "react-redux";
import useOnScreen from "hooks/useOnScreen";
import ConnectButton from "components/common/ConnectButton";
/*
import {
  getProductsListBySpace,
  clearProductsListBySpace,
} from "actions/products";
*/
import ReactLoader from "components/common/loader";
import { useParams } from "react-router-dom";
import {
  getProductsFeed,
  getProductsListForUser,
  getDropCalendar,
} from "actions/firebase";

const mapStateToProps = (state) => ({
  //productsListBySpaceLoading: state.products.productsListBySpaceLoading,
  //productsListBySpace: state.products.productsListBySpace,
  //hasMoreProducts: state.products.hasMoreSpaceProducts,
  //creatorsSpaceData: state.creators.creatorsSpaceData,
  //creatorsSpaceLoading: state.creators.creatorsSpaceLoading,
  dropFilters: state.dropFilters,
});

function CardGroup(props) {
  const {
    //productsListBySpace,
    dropFilters,
    showBuy,
    //creatorsSpaceData,
    //hasMoreProducts,
    //creatorsSpaceLoading,
    //productsListBySpaceLoading,
  } = props;
  const { filter } = dropFilters;
  const ref = useRef();
  const isMoreVisible = useOnScreen(ref);
  const isLoading = useRef(false);
  const [drops, setDrops] = useState([]);
  const [isLoaderVisible, setIsLoaderVisible] = useState(true);
  const [isUserPage, setIsUserPage] = useState(false);
  const [hiddenItem, setHiddenItem] = useState();
  const params = useParams();
  const [hasMoreProducts, setHasMoreProducts] = useState(false);

  const isFiltersEmpty = useMemo(() => {
    if (
      _.isEmpty(filter.price) &&
      _.isEmpty(filter.categories) &&
      _.isEmpty(filter.creators) &&
      _.isEmpty(filter.dropType) &&
      _.isEmpty(filter.seasons)
    ) {
      return true;
    }
    return false;
  }, [filter]);

  const getProducts = async ({ username, isInitial }) => {
    const existingDrops = isInitial ? [] : drops;
    const options = {
      username,
      limit: 24,
      startAfter: existingDrops.length
        ? existingDrops[existingDrops.length - 1].id
        : null,
    };

    if (isInitial) {
      delete options.startAfter;
    }
    isLoading.current = true;
    setIsLoaderVisible(true);
    const result = await (username ? getProductsListForUser : getProductsFeed)(
      options
    );
    setHasMoreProducts(!!result.hasMore);
    if (result.hasMore === undefined || !result.products) {
      return;
    }

    setDrops([...existingDrops, ...result.products]);
    setIsLoaderVisible(false);
    isLoading.current = false;
  };

  /*
  useEffect(() => {
    setLoader(true);
    if (
      isFetching &&
      !_.isEmpty(creatorsSpaceData?.creatorData?.creatorSpaces)
    ) {
      if (productsListBySpace.length >= 8) {
        dispatch(
          getProductsListBySpace(
            creatorsSpaceData?.creatorData.creatorSpaces[0].id,
            9,
            productsListBySpace.length,
            "update"
          )
        );
        setTimeout(() => setLoader(false), 1000);

        isFetching = false;
      }
    }
  }, [isFetching, creatorsSpaceData]);
  */

  useEffect(() => {
    const { creatorName, spaceName, productName } = params;

    console.log({ params });

    if (creatorName && spaceName && productName) {
      // This is a product url (ie. /username/vanity/slug), so retrieve product details
      getDropCalendar({
        vanity: spaceName,
        productSlug: productName,
      }).then((result) => {
        setHiddenItem(result.dropData);
      });
    }

    getProducts({
      username: creatorName,
      isInitial: true,
    });

    setIsUserPage(Boolean(creatorName));
  }, [params]);

  useEffect(() => {
    const { creatorName } = params;

    if (
      isMoreVisible &&
      hasMoreProducts &&
      drops.length &&
      !isLoading.current
    ) {
      getProducts({ username: creatorName });
    }
  }, [isMoreVisible, params, hasMoreProducts]);

  const handleFilterList = useCallback(
    (item) => {
      if (filter.categories.includes(item.category?.toLowerCase())) {
        return true;
      }
      if (filter.creators.includes(item.username?.toLowerCase())) {
        return true;
      }
      if (filter.dropType.includes(item.dropType?.toLowerCase())) {
        return true;
      }
      if (filter.price.includes(item.price)) {
        return true;
      }
      if (filter.seasons.includes(item.season?.toLowerCase())) {
        return true;
      }
      if (
        !filter.seasons.length &&
        !filter.categories.length &&
        !filter.creators.length &&
        !filter.dropType.length &&
        !filter.price.length
      ) {
        // if all filters is Empty, show goods
        return true;
      }
      return false;
    },
    [filter]
  );

  useEffect(() => {
    console.log({ filter });
  }, [filter]);

  /*
  useEffect(() => {
    const result = productsListBySpace.filter((item) => handleFilterList(item));
    console.log({productsListBySpace});
    setDrops(result);
  }, [handleFilterList, productsListBySpace]);
  */

  /*
  const isLoaderVisible = useMemo(() => {
    if (
      (productsListBySpaceLoading && hasMoreProducts && isFiltersEmpty) ||
      !creatorsSpaceData?.creatorData ||
      !creatorsSpaceLoading
    ) {
      return true;
    }
    return false;
  }, [
    productsListBySpaceLoading,
    hasMoreProducts,
    isFiltersEmpty,
    creatorsSpaceData?.creatorData,
    creatorsSpaceLoading,
  ]);
  */

  return (
    <>
      <div className="container-fluid px-0">
        <div className="row">
          {drops.length
            ? drops.map((item) => (
                <div key={item.id} className="col-12 col-md-6 col-xl-4 mb-3">
                  <GraphicCard
                    id={item.id}
                    data={item}
                    cardimg={item.coverImage?.src}
                    date={item.releaseDts}
                    category_name={
                      item?.category
                        ? item?.category[0].toUpperCase() +
                          item?.category.slice(1)
                        : ""
                    }
                    dropType={item.dropType}
                    creator_name={item.username}
                    border_color="#3784AB"
                    background="#40C397"
                    showBuy={showBuy}
                  />
                </div>
              ))
            : !isLoaderVisible && (
                <div className="empty_drops">
                  {isUserPage ? (
                    <div style={{ textAlign: "center" }}>
                      <div style={{ marginBottom: 30 }}>
                        Join our inner circle for
                        <br />
                        exclusive drops and promos!
                      </div>
                      <ConnectButton creatorName={params.creatorName} />
                    </div>
                  ) : (
                    <span className="empty_drops_text">No drops available</span>
                  )}
                </div>
              )}
        </div>
        <div className="row" ref={ref}>
          {(isLoaderVisible || hasMoreProducts) && <Loader count={24} />}
        </div>
      </div>
      {hiddenItem && (
        <div style={{ overflow: "hidden", width: 0, height: 0 }}>
          <GraphicCard
            id={hiddenItem.id}
            data={hiddenItem}
            cardimg={hiddenItem.coverImage?.src}
            date={hiddenItem.releaseDts}
            dropType={hiddenItem.dropType}
            creator_name={hiddenItem.username}
            displayDetails={hiddenItem}
          />
        </div>
      )}
    </>
  );
}

export default connect(mapStateToProps)(CardGroup);
