import { useEffect, useState } from 'react';
import { getStripeTestMode, setStripeTestMode } from 'utils/utils';

export default () => {
  const [ testMode, setTestMode ] = useState(false);
  const updateStripeTestMode = isEnabled => {
    setStripeTestMode(isEnabled);
    setTestMode(isEnabled);
  }

  const clearCart = () => window.localStorage.removeItem('bag_data');

  useEffect(() => {
    setTestMode(Boolean(getStripeTestMode()));
  }, []);

  return <>
    <h1>Development Tools</h1>
    <p>
      <label>Stripe Test Mode is <strong>{testMode ? 'enabled' : 'disabled'}</strong>: &nbsp;
        {testMode ?
        <button onClick={() => updateStripeTestMode(false)}>Disable</button> :
        <button onClick={() => updateStripeTestMode(true)}>Enable</button>}
      </label>
    </p>
    <p>
      <label>
          Shopping Cart: &nbsp;
          <button onClick={clearCart}>Clear Cart</button>
      </label>
    </p>
  </>
}