import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SwiperSlide, Swiper } from "swiper/react";
import { Row, Upload } from "antd";

import profile_avatar from "components/assets/profile_default_dark.png";
import edit from "components/assets/edit.png";
import "./profile.css";
import { logout, uploadFile } from "actions/firebase";
import { useUserData, setUserPreferences } from "actions/firebase";
import useWindowDimensions from "hooks/useWindowDimensions";
import Loader from "components/common/loader";
import { updateUserData } from "utils/utils";

function ProfileMenu() {
  const [profileImage, setProfileImage] = useState(profile_avatar);
  const [isUploading, setIsUploading] = useState(false);
  const windowSize = useWindowDimensions();
  const navigate = useNavigate();
  const userData = useUserData();
  const { pathname } = useLocation();

  const fileAdded = async (info) => {
    setIsUploading(true);
    const imageUrl = await uploadFile(info, "Profile");
    if (imageUrl) {
      await setUserPreferences({ imageUrl });
      updateUserData({ imageUrl });
    }
    setIsUploading(false);
  };

  useEffect(() => {
    const selectedPage = pathname.split("/")[2];
    try {
      let buttons = document.querySelectorAll(".menu_button1");
      buttons.forEach((button) => {
        button.classList.remove("active");
      });
      document.getElementById(selectedPage).classList.add("active");
      document.getElementById(`${selectedPage}_md`).classList.add("active");
    } catch (error) {
      navigate("/404/page404");
    }
  }, [pathname]);

  useEffect(() => {
    if (userData?.imageUrl) {
      setProfileImage(userData.imageUrl);
    }
  }, [userData]);

  const toggleActiveButton = (e) => {
    let buttons = document.querySelectorAll(".menu_button1");
    buttons.forEach((button) => {
      button.classList.remove("active");
    });
    console.log(e.target, e.target.closest);

    if (e.target.classList.contains("active") == false) {
      e.target.classList.add("active");
    }
  };

  return (
    <>
      <div className="profile_menu desktop_version">
        <div style={{ margin: "10px 0", position: "relative" }}>
          <Upload.Dragger
            style={{ border: 0, backgroundColor: "transparent" }}
            accept="image/*"
            multiple={false}
            showUploadList={false}
            customRequest={fileAdded}
          >
            <img
              src={profileImage}
              alt="profile"
              style={{ width: "85%", borderRadius: "50%" }}
            />
            <img
              className="editer"
              src={edit}
              alt=""
              style={{ cursor: "pointer" }}
            />
            {isUploading && (
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                }}
              >
                <Loader />
              </div>
            )}
          </Upload.Dragger>
        </div>

        <div style={{ margin: "25px 0" }}>
          <Link
            to="/profile/personalinfo"
            id="personalinfo"
            className="menu_button1"
            onClick={(e) => {
              toggleActiveButton(e);
            }}
          >
            Me
          </Link>
        </div>
        <div style={{ margin: "25px 0" }}>
          <Link
            to="/profile/connected"
            id="connected"
            className="menu_button1"
            onClick={(e) => {
              toggleActiveButton(e);
            }}
          >
            Links
          </Link>
        </div>
        <div style={{ margin: "25px 0" }}>
          <Link
            to="/profile/orders"
            id="orders"
            className="menu_button1"
            onClick={(e) => {
              toggleActiveButton(e);
            }}
          >
            Purchases
          </Link>
        </div>
        <div style={{ margin: "25px 0" }}>
          <Link
            to="/profile/favorites"
            id="favorites"
            className="menu_button1"
            onClick={(e) => {
              toggleActiveButton(e);
            }}
          >
            Favorites
          </Link>
        </div>
        <div style={{ margin: "25px 0" }}>
          <Link
            to="/profile/passport"
            id="passport"
            className="menu_button1"
            onClick={(e) => {
              toggleActiveButton(e);
            }}
          >
            Passport
          </Link>
        </div>
        <div style={{ margin: "25px 0" }}>
          <span
            className="menu_button1"
            onClick={() => logout().then(() => navigate("/"))}
          >
            Logout
          </span>
        </div>
      </div>
      {/* <div
        className="mobile_version mobile_profile_menu"
        style={{ padding: "0 10px 0 0" }}
      > */}
        {/* <div
          watchSlidesProgress={false}
          //   slidesPerView={windowSize.width < 420 ? 3 : 4}
          //   className="mySwiper"
          style={{
            display: "flex",
            flexDirection: "Row",
            textAlign: "justify",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        > */}
        {/* <div style={{ width: "fit-content !important" }}>
          <div
            style={{ border: 0, backgroundColor: "transparent" }}
            accept="image/*"
            multiple={false}
            showUploadList={false}
            customRequest={fileAdded}
          >
            <div style={{ margin: "10px 0", position: "relative" }}>
              <Upload.Dragger
                style={{ border: 0, backgroundColor: "transparent" }}
                accept="image/*"
                multiple={false}
                showUploadList={false}
                customRequest={fileAdded}
              >
                <img
                  src={profileImage}
                  alt="profile"
                  style={{
                    width: 50,
                    marginLeft: 10,
                    marginRight: 5,
                    borderRadius: "50%",
                  }}
                />
                <img
                  className="editer"
                  src={edit}
                  alt=""
                  style={{ cursor: "pointer" }}
                />
                {isUploading && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                    }}
                  >
                    <Loader />
                  </div>
                )}
              </Upload.Dragger>
            </div> */}
            {/* <div style={{ width: "fit-content", position: "relative" }}>
              <img
                src={profileImage}
                alt="profile"
                style={{ width: 50, marginLeft: 10, borderRadius: "50%" }}
              />
              <img
                className="editer"
                src={edit}
                alt=""
                style={{ cursor: "pointer" }}
              />
            </div>
            {isUploading && (
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                }}
              >
                <Loader />
              </div>
            )} */}
          {/* </div>
        </div>
        <div>
          <Link to="/profile/personalinfo">
            <div
              className="menu_button1"
              id="personalinfo_md"
              onClick={(e) => {
                toggleActiveButton(e);
              }}
            >
              Me
            </div>
          </Link>
        </div>
        <div>
          <Link to="/profile/connected">
            <div
              className="menu_button1"
              id="connected_md"
              onClick={(e) => {
                toggleActiveButton(e);
              }}
            >
              Links
            </div>
          </Link>
        </div>
        <div>
          <Link to="/profile/orders">
            <div
              className="menu_button1"
              id="orders_md"
              onClick={(e) => {
                toggleActiveButton(e);
              }}
            >
              Purchases
            </div>
          </Link>
        </div>
        <div>
          <Link to="/profile/favorites">
            <div
              className="menu_button1"
              id="favorites_md"
              onClick={(e) => {
                toggleActiveButton(e);
              }}
            >
              Favorites
            </div>
          </Link>
        </div>
        <div>
          <Link to="/profile/passport">
            <div
              className="menu_button1"
              id="passport_md"
              onClick={(e) => {
                toggleActiveButton(e);
              }}
            >
              Passport
            </div>
          </Link>
        </div>
        <div>
          <div
            className="menu_button1"
            onClick={() => logout().then(() => navigate("/"))}
          >
            Logout
          </div>
        </div> */}
        {/* </div> */}
      {/* </div> */}
      <div className='mobile_version mobile_profile_menu' style={{ padding: "0 10px 0 0" }}>
                <Swiper watchSlidesProgress={false} slidesPerView={windowSize.width < 420 ? 3 : 4} className="mySwiper" style={{padding:"0 15px", margin:"0px 0"}} >
                    <SwiperSlide style={{ width: 'fit-content !important' }}>
                        <Upload.Dragger
                            style={{ border: 0, backgroundColor: 'transparent' }}
                            accept="image/*"
                            multiple={false}
                            showUploadList={false}
                            customRequest={fileAdded}
                        >
                            <div style={{ width: 'fit-content', position: 'relative' }}>
                                <img src={profileImage} alt="profile" style={{ width: 61, borderRadius: '50%', marginLeft: 13,
                   }}  />
                                <img className='editer' src={edit} alt='' style={{ cursor: "pointer" }} />
                            </div>
                            {isUploading &&
                                <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
                                    <Loader />
                                </div>
                            }
                        </Upload.Dragger>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link to='/profile/personalinfo'><div className='menu_button1' id='personalinfo_md' onClick={(e) => { toggleActiveButton(e) }}>Me</div></Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link to='/profile/connected'><div className='menu_button1' id='connected_md' onClick={(e) => { toggleActiveButton(e) }}>Links</div></Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link to='/profile/orders'><div className='menu_button1' id='orders_md' onClick={(e) => { toggleActiveButton(e) }}>Purchases</div></Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link to='/profile/favorites'><div className='menu_button1' id='favorites_md' onClick={(e) => { toggleActiveButton(e) }}>Favorites</div></Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link to='/profile/passport'><div className='menu_button1' id='passport_md' onClick={(e) => { toggleActiveButton(e) }}>Passport</div></Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='menu_button1' onClick={() => logout().then(() => navigate('/'))}>Logout</div>
                    </SwiperSlide>
                </Swiper>
            </div>
    </>
  );
}
export default ProfileMenu;
