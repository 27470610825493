import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Channels from "components/Author/Channels";
import Main from "components/Homepage/Body";
import FloatingNav from "components/common/FloatingNav";
import NewProductAlert from "components/Notification/NewProductAlert";

function Homepage(props) {
  const [showCookies, setShowCookies] = useState(true);

  useEffect(() => {
    const isCookiesAccepted = localStorage.getItem("cookies");
    if (isCookiesAccepted) {
      setShowCookies(false);
    }
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem("cookies", false);
    setShowCookies(false);
  };

  const [showNewProductAlertModal, setShowNewProductAlertModal] =
    useState(false);

  useEffect(() => {
    try {
      const newProductObj = localStorage.getItem("newproduct");
      const lastShownDate =
        newProductObj && new Date(JSON.parse(newProductObj));

      if (!lastShownDate) {
        setShowNewProductAlertModal(true);
      } else {
        const dayDiff = (new Date() - lastShownDate) / (1000 * 60 * 60 * 24);
        if (dayDiff >= 7) setShowNewProductAlertModal(true);
      }
    } catch (error) {
      console.error("Error checking new product modal:", error);
      setShowNewProductAlertModal(true);
    }
  }, []);

  const handleCloseNewProductModal = () => {
    setShowNewProductAlertModal(false);
    localStorage.setItem(
      "newproduct",
      JSON.stringify(new Date().toISOString())
    );
  };

  return (
    <div className="homepage_body">
      <Channels />
      <Main show={props} />
      <div
        className="cookies"
        style={{ display: showCookies ? "flex" : "none" }}
      >
        <div className="cookies_text">
          <label>This site is using cookies to improve your experience.</label>
          <Link to="legal/cookies">
            <u>Learn more</u>
          </Link>
        </div>
        <div className="cookies_buttons">
          <button className="cookies_btn1" onClick={handleAcceptCookies}>
            Decline
          </button>
          <button className="cookies_btn" onClick={handleAcceptCookies}>
            Accept Cookies
          </button>
        </div>
      </div>
      <FloatingNav />
      <NewProductAlert
        show={showNewProductAlertModal}
        onHide={handleCloseNewProductModal}
      />
    </div>
  );
}
export default Homepage;
