import _ from "lodash";
import {
  GET_PRODUCT_LIST_BY_SPACE_LOADING,
  GET_PRODUCT_LIST_BY_SPACE,
  UPDATE_PRODUCT_LIST_BY_SPACE,
  GET_PRODUCT_LIST_LOADING,
  GET_PRODUCT_LIST,
  GET_OLD_PRODUCT,
  CLEAR_PRODUCT_LIST_BY_SPACE,
} from "actions/types";

const initialState = {
  productsListLoading: true,
  productsList: [],
  productsListBySpaceLoading: true,
  productsListBySpace: [],
  oldProduct: null,
  hasMoreSpaceProducts: true,
  hasMoreProducts: true,
};

function products(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_LIST_BY_SPACE_LOADING:
      return {
        ...state,
        productsListBySpaceLoading: action.payload,
      };
    case CLEAR_PRODUCT_LIST_BY_SPACE:
      return {
        ...state,
        productsListBySpace: [],
      };
    case GET_PRODUCT_LIST_BY_SPACE:
      return {
        ...state,
        productsListBySpace: action.payload,
      };
    case UPDATE_PRODUCT_LIST_BY_SPACE:
      return {
        ...state,
        productsListBySpace: _.unionBy(
          [...state.productsListBySpace, ...action.payload],
          "id"
        ),
        hasMoreSpaceProducts:
          _.isEmpty(action.payload) || action.payload.length < 9 ? false : true,
      };
    case GET_PRODUCT_LIST_LOADING:
      return {
        ...state,
        productsListLoading: action.payload,
      };
    case GET_PRODUCT_LIST:
      return {
        ...state,
        productsList: _.unionBy(
          [...state.productsList, ...action.payload],
          "id"
        ),
        hasMoreProducts:
          _.isEmpty(action.payload) || action.payload.length < 9 ? false : true,
      };
    case GET_OLD_PRODUCT:
      return {
        ...state,
        oldProduct: action.payload,
      };
    default:
      return state;
  }
}

export default products;
