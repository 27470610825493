import { useEffect, useState, useRef } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { estimateCosts, getPaymentIntent } from 'actions/firebase';
import { connect } from "react-redux";
import Loader from "components/common/loader"
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';

const mapStateToProps = (state) => ({
  theme: state.theme,
});

const PaymentButton = ({theme, onSuccess, onLoad, total}) => {
  const [ paymentOptions, setPaymentOptions ] = useState();
  const previousPayment = useRef();
  const stripe = useStripe();

  useEffect(() => {
    if (!stripe) { return }

    (async () => {
      const estimates = await estimateCosts();
      const paymentRequest = previousPayment.current = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Total',
          amount: Math.round(estimates.total * 100),
        },
        shippingOptions: [
          {
            id: 'flatrate',
            label: 'Ground shipping',
            detail: 'Ground shipping via UPS or USPS',
            amount: Math.round(estimates.shippingCost * 100),
          }
        ],
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true,
        requestShipping: true,
      });

      paymentRequest.on('paymentmethod', async (ev) => {
        const { paymentMethod }  = ev;
        const response = await getPaymentIntent(undefined, undefined, paymentMethod);
        const { clientSecret } = response;


        const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
          clientSecret,
          {payment_method: paymentMethod.id},
          {handleActions: false}
        );
      
        if (confirmError) {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          ev.complete('fail');
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete('success');
          // Check if the PaymentIntent requires any actions and if so let Stripe.js
          // handle the flow. If using an API version older than "2019-02-11"
          // instead check for: `paymentIntent.status === "requires_source_action"`.
          if (paymentIntent.status === "requires_action") {
            // Let Stripe.js handle the rest of the payment flow.
            const {error} = await stripe.confirmCardPayment(clientSecret);
            if (error) {
              // The payment failed -- ask your customer for a new payment method.
            } else {
              // The payment has succeeded.
            }
          } else if (onSuccess) {
            // The payment has succeeded.
            onSuccess();
          }
        }
      });

      // Check the availability of the Payment Request API.
      const result = await paymentRequest.canMakePayment();
      // Ignore result.googlePay
      if (result) {
        setPaymentOptions({
          paymentRequest,
          style: {
            paymentRequestButton: {
              type: 'buy', // 'default', 'book', 'buy', or 'donate'
              theme: theme ? 'light' : 'dark', // 'dark', 'light', or 'light-outline'
              height: '40px',
            },
          }
        });
        if (onLoad) {
          onLoad();
        }
      }
      else {
        setPaymentOptions(null);
      }
    })()
  }, [stripe])

  useEffect(() => {
    if (!total || !previousPayment.current) { return }
    (async () => {
      const estimates = await estimateCosts();
      previousPayment.current.update({
        total: {
          label: 'Total',
          amount: Math.round(estimates.total * 100),
        }
      });
    })()
  }, [total])

  return  paymentOptions ?
    <PaymentRequestButtonElement options={paymentOptions} className="payment-request-button" /> :
    paymentOptions === null ? null : <Loader height={20} width={100} />;
}

export default connect(mapStateToProps)(PaymentButton);