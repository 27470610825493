export const GET_DROP_CALENDAR = "GET_DROP_CALENDAR";
export const GET_DROP_CALENDAR_LOADING = "GET_DROP_CALENDAR_LOADING";
export const SET_DROP_FILTERS = "SET_DROP_FILTERS";

export const GET_CREATORS_LIST_LOADING = "GET_CREATORS_LIST_LOADING";
export const GET_CREATORS_LIST = "GET_CREATORS_LIST";
export const GET_CREATORS_SPACE_DATA = "GET_CREATORS_SPACE_DATA";
export const GET_CREATORS_SPACE_LOADING = "GET_CREATORS_SPACE_LOADING";
export const GET_SIMILAR_CREATORS_LOADING = "GET_SIMILAR_CREATORS_LOADING";
export const GET_SIMILAR_CREATORS = "GET_SIMILAR_CREATORS";

export const GET_PRODUCT_LIST_BY_SPACE_LOADING = "GET_PRODUCT_LIST_BY_SPACE_LOADING";
export const GET_PRODUCT_LIST_BY_SPACE = "GET_PRODUCT_LIST_BY_SPACE";
export const CLEAR_PRODUCT_LIST_BY_SPACE = "CLEAR_PRODUCT_LIST_BY_SPACE";
export const UPDATE_PRODUCT_LIST_BY_SPACE = "UPDATE_PRODUCT_LIST_BY_SPACE";
export const GET_PRODUCT_LIST_LOADING = "GET_PRODUCT_LIST_BY_SPACE_LOADING";
export const GET_PRODUCT_LIST = "GET_PRODUCT_LIST";

export const GET_OLD_PRODUCT_LOADING = "GET_OLD_PRODUCT_LOADING";
export const GET_OLD_PRODUCT = "GET_OLD_PRODUCT";

export const ADD_ITEM_TO_BAG = "ADD_ITEM_TO_BAG";
export const REMOVE_ITEM_FROM_BAG = "REMOVE_ITEM_FROM_BAG";
export const CHANGE_ITEM_COUNT = "CHANGE_ITEM_COUNT";
export const SYNCHRONIZE_WITH_LOCAL_STORE = "SYNCHRONIZE_WITH_LOCAL_STORE";

export const SET_SHOW_MENU = "SET_SHOW_MENU";

