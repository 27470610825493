import { Card } from "antd";
import "./css/productcard.css";
//import "./css/card.css";
import "swiper/css";
import moment from "moment";
import Notification from "../Notification";
import VideoPlayer from "react-background-video-player";
import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";
import Video from "../Homepage/Video";
import MapWrapper from "../Darkmap";
import { connect, useDispatch } from "react-redux";
import SkeletonComponent from "components/Skeleton/index";
import LoginedNoti from "../Notification/LoginedNoti";
import { addItemToBag } from "actions/bag";
import { getVariantFromProduct } from "utils/utils";
import ProductModal from "./ProductModal";
import CategoryBadge from "./CategoryBadge";
import TypeBadge from "./TypeBadge";
import space_icon from "components/assets/icons/empty_avatar.svg";
import PhoneModal from "components/Author/PhoneModal";
import heart_favorited from "../assets/heart_red.svg";
// import { SettingsInputSvideo } from "@mui/icons-material";

const mapStateToProps = (state) => ({
  //loading: !state.products.productsListBySpaceLoading,
  theme: state.theme,
});

const isVideo = (src) => /\.(mov|mp4)/i.test(src);

function GraphicCard(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { theme, date, id, data, displayDetails, showBuy, updateProductList } = props;
  const loading = true;
  const [showCard, setShowCard] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [map, setMap] = useState(false);
  const [logined, setLogin] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showLoginedNotification, setShowLoginedNotification] = useState(false);

  const timestamp = useMemo(
    () => new Date(date._seconds * 1000 + date._nanoseconds / 1000000),
    [date._nanoseconds, date._seconds]
  );
  /*
  const handleGetDropCalendar = (productId) => {
    dispatch(getDropCalendarAction(productId));
    dispatch(getOldProduct(productId));
  };
  */

  // const [drawerSize, setDrawerSize] = useState(450);
  let mobileView = false;

  if (window.innerWidth < 680) {
    mobileView = true;
  }

  useEffect(() => {
    const logined1 = JSON.parse(localStorage.getItem("logined"));
    setLogin(logined1);
  }, [logined]);

  useEffect(() => {
    if (displayDetails) {
      setShowCard(true);
    }
  }, [displayDetails]);

  const [drawerClassName, setDrawerClassName] = useState("");
  const [drawerHeight, setDrawerHeight] = useState("100%");
  const [imageIdx, setImageIdx] = useState(0);

  const handleCloseCard = () => {
    setShowCard(false);
    /*
    navigate(`/${getDropCalendar.creatorData.creatorHandle}`, {
      state: { fetching: "stop" },
    });
    */
  };

  const handleShowCard = () => {
    //handleGetDropCalendar(id || "");
    if (data.isAvailable) {
      //window.scrollTo(0, 0);
      setShowCard(true);
      setDrawerHeight("100%");
      setDrawerClassName("");
    }
    /*
    console.log({data});
    navigate(`/${data.username}/${data.groupName}/${data.name}`, {
      state: { fetching: "stop" },
    });
    */
  };

  const [videoError, setVideoError] = useState(false);

  const handleAddToBag = (selectedSize, selectedColor, data) => {
    console.log({ data });
    const variant = getVariantFromProduct(
      data.dropData.variants,
      selectedSize || data.dropData.availableSizes[0],
      data.dropData?.colors?.length ? data.dropData.colors[0] : selectedColor
    );

    const selectedData = {
      id: props.id,
      color: data.dropData?.colors?.length
        ? data.dropData.colors[0]
        : selectedColor,
      size: selectedSize || data.dropData.availableSizes[0],
      name: data.dropData.name,
      price: data.dropData.price,
      picture: data.dropData.picture,
      address: data.dropData.address,
      shippingPrice: data.dropData.shippingPrice || "free",
      count: 1,
      idVariant: variant.id,
      variantQuantity: variant.quantity,
      idGroup: data.dropData.idGroup || "0",
      creatorHandle: data.creatorData.creatorHandle,
      dropType: data.dropData.dropType,
      releaseDts: data.dropData.releaseDts,
      dropId: data.rawProduct.dropId,
      spaceName: data.spaceData.name || data.spaceData.vanity,
      product: data.rawProduct,
    };

    setDrawerHeight(0);
    setDrawerClassName("bag_animation_active");
    setTimeout(() => handleCloseCard(), 1700);
    // dispatch(addItemToBag(selectedData));

    if (variant) {
      dispatch(addItemToBag(selectedData));
    }
  };

  const handleLinkClick = (e) => {
    // Scroll the window to the top
    window.scrollTo(0, 0);
  };

  return (
    data && (
      <>
        <div className="graphic_card" data-productid={id}>
          <video
            style={{ display: "none" }}
            onError={() => setVideoError(true)}
          >
            <source src={props.cardimg} type="video/mp4" />
          </video>
          <Card
            style={{
              width: "100%",
              cursor: "pointer",
            }}
            onClick={handleShowCard}
            cover={
              !loading || props.cardimg === "" ? (
                <SkeletonComponent
                  height={200}
                  width="100%"
                  borderRadius="12px 12px 0 0"
                  style={{ lineHeight: "2" }}
                />
              ) : !videoError && isVideo(props.cardimg) ? (
                <div className="video_background">
                  <VideoPlayer
                    className="video_cover"
                    src={props.cardimg}
                    autoPlay
                    muted
                    playsInline
                    style={{ borderRadius: "10px 10px 0 0" }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    position: "relative",
                    backgroundImage: `url(${props.cardimg})`,
                    backgroundSize: "cover",
                    backgroundPosition: "top",
                    width: "100%",
                    aspectRatio: "16/9",
                    //height: "200px",
                    borderRadius: "12px 12px 0 0",
                  }}
                >
                  {props?.isFavorited && (
                    <img
                      src={heart_favorited}
                      style={{ position: "absolute", bottom: 5, right: 5 }}
                    />
                  )}
                  {/* {!loading || props.cardimg === "" ? (
                <SkeletonComponent
                  height={220}
                  width="100%"
                  borderRadius="12px 12px 0 0"
                  style={{ lineHeight: "2" }}
                />
              ) : (
                <img
                  alt="example"
                  src={props.cardimg}
                  style={{
                    width: "100%",
                    height: "220px",
                    borderRadius: "12px 12px 0 0",
                    maxWidth: "-webkit-fill-available",
                  }}
                />
              )} */}
                </div>
              )
            }
          >
            <div
              className="card_content"
              style={{
                width: "100%",
                aspectRatio: "16/1",
                marginBottom: "8px",
                paddingTop: "3px",
              }}
            >
              <div className="text-left">
                <div className="font10_2">Date released</div>
                <div className="font10">
                  {moment(timestamp).format("MM/DD/YY")}
                </div>
              </div>
              <div className="d-flex">
                {loading ? (
                  <CategoryBadge
                    category_name={props.category_name}
                    theme={theme}
                  />
                ) : (
                  <SkeletonComponent height={29} width={56} />
                )}
                {loading ? (
                  <TypeBadge dropType={props.dropType} releaseDts={date} />
                ) : (
                  <SkeletonComponent
                    height={29}
                    width={86}
                    style={{ marginRight: "10px" }}
                  />
                )}
              </div>
            </div>
            {!showBuy ? (
              <div
                className="space_between"
                style={{
                  padding: "0px 0",
                  margin: "5px 12px",

                  aspectRatio: "16/1",
                }}
              >
                <label className="font12">Price {data.price}</label>
                <Link
                  to={`/${data?.username}`}
                  state={{ creatorId: data?.userId }}
                  onClick={handleLinkClick}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: 10 }}
                  >
                    {loading ? (
                      <label className="text-lowercase">
                        @{props.creator_name || ""}
                      </label>
                    ) : (
                      <SkeletonComponent
                        width={60}
                        style={{ marginRight: "10px" }}
                      />
                    )}
                    {loading ? (
                      <img
                        src={data?.userImageUrl || space_icon}
                        alt=""
                        style={{ width: 30, height: 30, borderRadius: "50%" }}
                      />
                    ) : (
                      <SkeletonComponent width={30} height={30} circle />
                    )}
                  </div>
                </Link>
              </div>
            ) : (
              <div
                className={`buy-now-button ${
                  data.isAvailable ? "" : "disabled"
                }`}
              >
                <div className="buy-now-button__price">
                  <span className="buy-now-button__label">Price</span>
                  <span className="buy-now-button__text">{data.price}</span>
                </div>
                <div className="buy-now-button__text">
                  {data.isAvailable ? "Buy Now" : "Sold Out"}
                </div>
              </div>
            )}
          </Card>

          <ProductModal
            id={id}
            handleCloseCard={handleCloseCard}
            drawerHeight={drawerHeight}
            drawerClassName={drawerClassName}
            handleAddToBag={handleAddToBag}
            dropType={props.dropType}
            category_name={props.category_name}
            showCard={showCard}
            videoError={videoError}
            setShowVideo={(val) => setShowVideo(val)}
            showLoginedNotification={showLoginedNotification}
            setShowLoginedNotification={(not) =>
              setShowLoginedNotification(not)
            }
            showNotification={showNotification}
            setShowNotification={(not) => setShowNotification(not)}
            setImageIdx={(idx) => setImageIdx(idx)}
            isFavorited={props?.isFavorited}
            updateProductList = {updateProductList}
          />
          {/* <Notification
          show={showNotification}
          onHide={() => setShowNotification(false)}
        /> */}

          <LoginedNoti
            {...props}
            show={showLoginedNotification}
            onHide={() => setShowLoginedNotification(false)}
          />

          <PhoneModal
            isModalOpen={showNotification}
            setIsModalOpen={setShowNotification}
            onSuccess={() => {}}
          />

          <Video
            show={showVideo}
            onHide={() => setShowVideo(false)}
            video={data.images || []}
            selectedImageIdx={imageIdx}
          />

          <MapWrapper show={map} onHide={() => setMap(false)} />
        </div>
      </>
    )
  );
}

export default connect(mapStateToProps)(GraphicCard);
