import { useSelector } from 'react-redux';

import dark404 from 'components/assets/bag_404_dark.svg';
import light404 from 'components/assets/bag_404.svg';
import './orders.css'

const oldMessage = `Looks like you haven't placed any orders yet. \nStart shopping and see your order history fill up!`;
const newMessage = `When you start making purchases, they'll show up right here.`;


const OrderEmpty = () => {

    const theme = useSelector(state => state.theme);
    const img_404 = theme ? dark404 : light404;

    return (
        <div className='order_empty_container'>
            <img src={img_404} className='order_empty_image' />
            <div className='font16_6 order_empty_font'>{newMessage}</div>
        </div>
    );
}

export default OrderEmpty;