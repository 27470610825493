import { useRef, useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import VideoPlayer from "react-background-video-player";

import pause from "components/assets/pause.png";
import CloseButton from "components/common/CloseButton";

import "./video.css";

const isVideo = (src) => /\.(mov|mp4)/i.test(src);

function Video(props) {
  const [second, setSecond] = useState(0);
  const [minute, setMinute] = useState(0);
  const [showVideo, setShowVideo] = useState(false);
  const [playpause, setPlayPause] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const [imageIdx, setImageIdx] = useState(props.selectedImageIdx || 0);

  useEffect(() => {
    setImageIdx(props.selectedImageIdx);
  }, [props.selectedImageIdx]);

  const videoEl = useRef(null);

  const closeModal = () => {
    props.onHide(!showVideo);
    setPlayPause(false);
  };

  const video = videoEl.current;
  const handleLoadedMetadata = () => {
    if (!video) return;
    if (video.duration >= 60) {
      setSecond(video.duration % 60);
      if (video.duration / 60 > 9) {
        setMinute(video.duration);
      } else {
        setMinute("0" + video.duration);
      }
    } else {
      setMinute("00");
      setSecond(video.duration);
    }
    // setTime(`${video.duration}`)
  };
  const handlePlayVideo = () => {
    setPlayPause(!playpause);
    if (playpause) {
      video.play();
    } else {
      video.pause();
    }
  };
  const [isMobile, setIsMobile] = useState(false);
  window.addEventListener("resize", function (e) {
    let width = window.innerWidth;
    if (width < 680) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  });
  useEffect(() => {
    if (window.innerWidth < 680) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);
  return (
    <Modal {...props} size="lg" centered>
      <video
        src={props.video?.length ? props.video[imageIdx]?.src : ""}
        onError={() => setVideoError(true)}
        style={{ display: "none" }}
      />
      <div className="video">
        <Modal.Body>
          <CloseButton className="video_close" onClick={closeModal} />
          <div className="player">
            <div className="w-100 d-flex align-items-center image-preview-main-picture">
              {isVideo(props.video[imageIdx]?.src) ? (
                <>
                  <div
                    className="video_background"
                    style={{ height: isMobile ? "400px" : "700px" }}
                  >
                    <VideoPlayer
                      className="player__video viewer"
                      autoPlay
                      muted={false}
                      ref={videoEl}
                      onLoadedMetadata={handleLoadedMetadata}
                      src={
                        props.video && props.video.length
                          ? props.video[imageIdx]?.src
                          : ""
                      }
                      playsInline
                    />
                  </div>
                  <img
                    src={pause}
                    alt=""
                    className="video_pause"
                    onClick={handlePlayVideo}
                    style={{ display: !playpause ? "none" : "block" }}
                  />
                </>
              ) : (
                <img
                  src={props.video?.length ? props.video[imageIdx]?.src : ""}
                  alt=""
                  className="image_preview"
                />
              )}
            </div>
            {props.video && props.video.length > 1 && (
              <div className="pre_images desktop_version">
                {props.video.map((el, idx) =>
                  !isVideo(el.src) ? (
                    <img
                      style={{
                        height: "200px",
                        width: "100px",
                        cursor: "pointer",
                      }}
                      className="pre_image"
                      src={el.src}
                      alt=""
                      onClick={() => setImageIdx(idx)}
                      key={idx}
                    />
                  ) : (
                    <div
                      className="video_background"
                      style={{
                        height: "200px",
                        width: "100px",
                        cursor: "pointer",
                      }}
                      key={idx}
                    >
                      <VideoPlayer
                        className="player__video viewer"
                        src={el.src}
                        onClick={() => setImageIdx(idx)}
                        autoPlay={false}
                      />
                    </div>
                  )
                )}
              </div>
            )}
          </div>
          {props.video && props.video.length > 1 && (
            <div className="mobile_version">
              <div className="mobile_pre_images d-flex">
                {props.video &&
                  props.video.length &&
                  props.video.map((el, idx) =>
                    !isVideo(el.src) ? (
                      <img
                        className="pre_image"
                        src={el.src}
                        alt=""
                        onClick={() => setImageIdx(idx)}
                        style={{
                          height: "150px",
                          width: "100px",
                          cursor: "pointer",
                        }}
                        key={idx}
                      />
                    ) : (
                      <div
                        className="video_background"
                        style={{
                          height: "150px",
                          width: "100px",
                          cursor: "pointer",
                          margin: "10px",
                        }}
                        key={idx}
                      >
                        <VideoPlayer
                          className="player__video viewer"
                          src={el.src}
                          onClick={() => setImageIdx(idx)}
                          autoPlay={false}
                        />
                      </div>
                    )
                  )}
              </div>
            </div>
          )}
        </Modal.Body>
      </div>
    </Modal>
  );
}
export default Video;
