import React from "react";
import Tooltip from "react-bootstrap/Tooltip";
import { FaLink, FaTwitter } from "react-icons/fa";
import { AiTwotoneMail } from "react-icons/ai";
import { FiCopy } from "react-icons/fi";
import { message } from 'antd';

export const renderTooltip = (props) => {
  const { link } = props;

  const copyToClipboard = async () => {
    await window.navigator.clipboard.writeText(link);
    message.open({
      content: <span style={{paddingLeft: 15}}>Link copied to clipboard</span>,
      icon: <FaLink />
    });
  };

  return <Tooltip id="button-tooltip" {...props}>
    <div
      className="space_between"
      onClick={copyToClipboard}
      style={{ cursor: "pointer" }}
    >
      <div className="d-flex" style={{ gap: 20 }}>
        <FaLink />
        <div className="font12 tooltip-share-link">{link}</div>
      </div>
      <div style={{ cursor: "pointer", marginLeft: "5px" }}>
        <FiCopy />
      </div>
    </div>
    <div style={{ display: "flex", alignItems: "center", margin: "12px 0", cursor: "pointer" }}>
      <AiTwotoneMail />
      <div
        className="font12"
        style={{ marginLeft: 20 }}
        onClick={() =>
          window.open(
            `mailto:help@shopaura.app?subject=Share my profile&body=${window.location.href}`
          )
        }
      >
        Email
      </div>
    </div>
    <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
      <FaTwitter />
      <div
        className="font12"
        style={{ marginLeft: 20 }}
        onClick={() =>
          window.open(
            `https://twitter.com/intent/tweet?text=${window.location.href}`,
            "_blank"
          )
        }
      >
        Twitter
      </div>
    </div>
  </Tooltip>
};
