import { Card } from "antd";
import "./css/productcard.css";
//import "./css/card.css";
import "swiper/css";
import moment from "moment";
import Notification from "../Notification";
import VideoPlayer from "react-background-video-player";
import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";
import Video from "../Homepage/Video";
import MapWrapper from "../Darkmap";
import { connect, useDispatch } from "react-redux";
import SkeletonComponent from "components/Skeleton/index";
import LoginedNoti from "../Notification/LoginedNoti";
import { addItemToBag } from "actions/bag";
import { getVariantFromProduct } from "utils/utils";
import ProductModal from "./ProductModal";
import CategoryBadge from "./CategoryBadge";
import TypeBadge from "./TypeBadge";
import space_icon from "components/assets/icons/empty_avatar.svg";
import PhoneModal from "components/Author/PhoneModal";
// import { SettingsInputSvideo } from "@mui/icons-material";

const mapStateToProps = (state) => ({
  //loading: !state.products.productsListBySpaceLoading,
  theme: state.theme,
});

const isVideo = src => /\.(mov|mp4)/i.test(src);

function ProductCardView(props) {
  const { theme, date, id, data, showBuy } = props;
  const loading = true;
  const [map, setMap] = useState(false);
  const [logined, setLogin] = useState(false);

  const timestamp = useMemo(
    () => new Date(date._seconds * 1000 + date._nanoseconds / 1000000),
    [date._nanoseconds, date._seconds]
  );

  let mobileView = false;

  if (window.innerWidth < 680) {
    mobileView = true;
  }

  useEffect(() => {
    const logined1 = JSON.parse(localStorage.getItem("logined"));
    setLogin(logined1);
  }, [logined]);

  const handleShowCard = () => {
  };

  const [videoError, setVideoError] = useState(false);

  const handleLinkClick = (e) => {
    window.scrollTo(0, 0);
  };

  return (
    data && <>
      <div className="graphic_card" data-productid={id}>
        <video style={{ display: "none" }} onError={() => setVideoError(true)}>
          <source src={props.cardimg} type="video/mp4" />
        </video>
        <Card
          style={{
            width: "100%",
            cursor: "pointer",
          }}
          onClick={handleShowCard}
          cover={
            !loading || props.cardimg === "" ? (
              <SkeletonComponent
                height={200}
                width="100%"
                borderRadius="12px 12px 0 0"
                style={{ lineHeight: "2" }}
              />
            ) : (!videoError && isVideo(props.cardimg)) ? (
              <div className="video_background">
                <VideoPlayer
                  className="video_cover"
                  src={props.cardimg}
                  autoPlay
                  muted
                  playsInline
                  style={{ borderRadius: "10px 10px 0 0" }}
                />
              </div>
            ) : (
              <div
                style={{
                  position: "relative",
                  backgroundImage: `url(${props.cardimg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: "200px",
                  borderRadius: "12px 12px 0 0",
                }}
              >
              </div>
            )
          }
        >
          <div className="card_content">
            <div className="text-left">
              <div className="font10_2">Date released</div>
              <div className="font10">{moment(timestamp).format('MM/DD/YY')}</div>
            </div>
            <div className="d-flex">
              {loading ? (
                <CategoryBadge category_name={props.category_name} theme={theme} />
              ) : (
                <SkeletonComponent height={29} width={56} />
              )}
              {loading ? (
                <TypeBadge dropType={props.dropType} releaseDts={date} />
              ) : (
                <SkeletonComponent
                  height={29}
                  width={86}
                  style={{ marginRight: "10px" }}
                />
              )}
            </div>
          </div>
          {!showBuy ?
            <div
              className="space_between"
              style={{
                padding: "6px 0",
                margin: "0 12px",
              }}
            >
              <label className="font12">Price {data.price}</label>
              <Link
                to={`/${data?.username}`}
                state={{ creatorId: data?.userId }}
                onClick={handleLinkClick}
              >
                <div className="d-flex align-items-center" style={{ gap: 10 }}>
                  {loading ? (
                    <label className="text-lowercase">
                      @{props.creator_name || ""}
                    </label>
                  ) : (
                    <SkeletonComponent
                      width={60}
                      style={{ marginRight: "10px" }}
                    />
                  )}
                  {loading ? (
                    <img
                      src={
                        data?.userImageUrl ||
                        space_icon
                      }
                      alt=""
                      style={{ width: 30, height: 30, borderRadius: "50%" }}
                    />
                  ) : (
                    <SkeletonComponent width={30} height={30} circle />
                  )}
                </div>
              </Link>
            </div>
            :
            <div className={`buy-now-button ${data.isAvailable ? '' : 'disabled'}`}>
              <div className="buy-now-button__price">
                <span className="buy-now-button__label">Price</span>
                <span className="buy-now-button__text">{data.price}</span>
              </div>
              <div className="buy-now-button__text">
                {data.isAvailable ? 'Buy Now' : 'Sold Out'}
              </div>
            </div>
          }

        </Card>
        <MapWrapper show={map} onHide={() => setMap(false)} />
      </div>
    </>
  );
}

export default connect(mapStateToProps)(ProductCardView);
