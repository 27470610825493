import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { message } from "antd";

import PhoneModal from "components/Author/PhoneModal";
import EditSpaceModal from "components/Profile/EditSpace/EditSpaceModal";
import { useUserData } from "actions/firebase";
import { addConnection, removeConnection } from "actions/firebase";
import { useConnectionStatus, updateConnectionStatus } from "utils/connection";
import './css/main.css'

function ConnectButton(params) {
  const { creatorName, className } = params;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSignedUser, setIsSignedUser] = useState(false);
  const userData = useUserData();
  const isConnected = useConnectionStatus(creatorName);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    setIsSignedUser(userData?.username === creatorName);
  }, [userData]);

  const handleConnect = async () => {
    if (!userData) {
      setIsModalOpen(true);
    }
    else {
      const origIsConnected = isConnected;
      const username = creatorName;
      updateConnectionStatus(username, !isConnected);
      const result = await (
        isConnected
          ? removeConnection({ username })
          : addConnection({ username })
      )
      if (!result.success) {
        updateConnectionStatus(username, origIsConnected);
      }
      else {
        message.open({
          content: isConnected ?
            `You have split from ${username}.` :
            `You are linked with ${username}.`
        });
      }
    }
  }

  const addConnectionAction = () => {
    addConnection({ username: creatorName })
    updateConnectionStatus(creatorName, true);
  }

  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => { setShowModal(true) }
  const handleCloseModal = () => { setShowModal(false) }
  const [drawerHeight, setDrawerHeight] = useState("100%");

  return <>
    <>
      {isSignedUser &&
        <button
          className={`connect-button ${className || ''}`}
          onClick={handleShowModal}
        >
          {"Edit Space"}
        </button>}
      {!isSignedUser &&
        <button
          className={`connect-button ${isConnected ? 'connect-button--connected' : ''} ${className || ''}`}
          onClick={handleConnect}
        >
          {isConnected ? "Connected" : "Let's link"}
        </button>}
    </>

    <PhoneModal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      onSuccess={addConnectionAction}
    />
    <EditSpaceModal
      showModal={showModal}
      handleCloseModal={handleCloseModal}
      drawerHeight={drawerHeight}
    />
  </>;
}

export default ConnectButton;
