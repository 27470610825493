import { API } from "./api";
import axios from "axios";
import {
  GET_CREATORS_LIST_LOADING,
  GET_CREATORS_LIST,
  GET_CREATORS_SPACE_LOADING,
  GET_CREATORS_SPACE_DATA,
  GET_SIMILAR_CREATORS_LOADING,
  GET_SIMILAR_CREATORS,
  GET_PRODUCT_LIST_BY_SPACE_LOADING
} from "actions/types";

export const getCreatorsList = () => async (dispatch) => {
  dispatch({
    type: GET_CREATORS_LIST_LOADING,
    payload: false,
  });
  try {
    const res = await axios({
      url: `${API}/getCreatorsList`,
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      data: JSON.stringify({
        data: {},
      }),
    });
    dispatch({
      type: GET_CREATORS_LIST,
      payload: res.data?.result,
    });
    dispatch({
      type: GET_CREATORS_LIST_LOADING,
      payload: true,
    });
  } catch (error) {
    console.log("getCreatorsList error:", error);
  }
};

export const getCreatorSpaceData = (userId) => async (dispatch) => {
  dispatch({
    type: GET_CREATORS_SPACE_LOADING,
    payload: false,
  });
  try {
    const res = await axios({
      url: `${API}/getCreatorSpaceData`,
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      data: JSON.stringify({
        data: { userId },
      }),
    });
    dispatch({
      type: GET_CREATORS_SPACE_DATA,
      payload: res.data?.result,
    });
    dispatch({
      type: GET_CREATORS_SPACE_LOADING,
      payload: true,
    });
  } catch (error) {
    console.log("getCreatorSpaceData error:", error);
  }
};

export const getCreatorSpaceDataByUsername = (username, navigate) => async (dispatch) => {
  dispatch({
    type: GET_CREATORS_SPACE_LOADING,
    payload: false,
  });
  try {
    const res = await axios({
      url: `${API}/getCreatorSpaceDataByUsername`,
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      data: JSON.stringify({
        data: { username },
      }),
    });
    const userId = res.data?.result?.creatorData?.creatorId;
    dispatch({
      type: GET_CREATORS_SPACE_DATA,
      payload: res.data?.result,
    });
    dispatch({
      type: GET_CREATORS_SPACE_LOADING,
      payload: true,
    });
    // if(!userId) navigate('/404/page404');
  } catch (error) {
    console.log("getCreatorSpaceDataByUsername error:", error);
  }
};

export const getSimilarCreators = (creatorId) => async (dispatch) => {
  dispatch({
    type: GET_SIMILAR_CREATORS_LOADING,
    payload: false,
  });
  try {
    const res = await axios({
      url: `${API}/getSimilarCreators`,
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      data: JSON.stringify({
        data: { creatorId },
      }),
    });
    dispatch({
      type: GET_SIMILAR_CREATORS,
      payload: res.data?.result,
    });
    dispatch({
      type: GET_SIMILAR_CREATORS_LOADING,
      payload: true,
    });
  } catch (error) {
    console.log("getSimilarCreators error:", error);
  }
};

export const clearCreatorSpaceData = () => async (dispatch) => {
  dispatch({
    type: GET_CREATORS_SPACE_DATA,
    payload: undefined,
  });
};