import { useEffect, useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons"
import { Button, Tooltip } from "antd";
import moment from "moment";
import order_icon from "components/assets/order_icon.png";
import decrease from "components/assets/decrease.svg";
import increase from "components/assets/increase.svg";
import decrease2 from "components/assets/decrease2.png";
import increase2 from "components/assets/increase2.png";
import { MdClose } from "react-icons/md";
import { connect, useDispatch } from "react-redux";
import SkeletonComponent from "components/Skeleton/index";
import { changeItemsCount, removeItemFromBag } from "actions/bag";
import TypeBadge from "../Product/TypeBadge";
import { Collapse } from 'antd';
const { Panel } = Collapse;
const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

function OrderedProduct(props) {
  const { theme, data } = { ...props };
  const [badgeInfo, setBadgeInfo] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (/presale/i.test(data?.dropType)) {
      const { releaseDts } = data;
      if (releaseDts) {
        const release = new Date(releaseDts._seconds * 1000 + releaseDts._nanoseconds / 1000000)
        setBadgeInfo(`Your drop will begin shipping the week of ${moment(release).format('MM/DD/YY')}. Secure your drop now before it's too late!`)
      }
    }
    console.log({ data })
  }, [data])

  return <>
    <div className="cart-product">
      <div className="cart-product__close">
        <MdClose
          style={{ cursor: "pointer", minWidth: "14px", maxWidth: "14px" }}
          onClick={() => dispatch(removeItemFromBag(data.id))}
        />
      </div>
      <div className="cart-product__thumbnail">
        <div className="cart-product__image" style={{ backgroundImage: `url(${data.picture})` }} />
      </div>
      <div className="cart-product__summary">
        <div className="cart-product__badge">
          <span className="cart-product__badge-space font12_6"></span>
          <span className="cart-product__badge-button">
            <TypeBadge dropType={data.dropType} releaseDts={data.releaseDts} />
          </span>
          {/* <Tooltip placement="bottomRight" title={badgeInfo} overlayInnerStyle={{border: "1px solid rgba(255, 255, 255, 0.2)"}}>
            <div className="cart-product__badge-info">
              <InfoCircleOutlined/>
            </div>
          </Tooltip> */}
        </div>

        <label style={{ fontSize: "12px", color: 'var(--font_color)' }} className="font14_w4 p-1" >{data.name}</label>
        <div className="cart-product__quantity">
          <img
            src={theme ? decrease : decrease2}
            width={23}
            alt="Decrement quantity"
            onClick={() =>
              dispatch(
                changeItemsCount(
                  data.id,
                  data.count - 1 > 0 ? data.count - 1 : 0, data.idGroup
                )
              )
            }
            style={{ cursor: "pointer" }}
          />
          <label>{data.count}</label>
          <img
            src={theme ? increase : increase2}
            width={23}
            alt="Increment quantity"
            onClick={() => dispatch(changeItemsCount(data.id, data.count + 1, data.idGroup))}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
      <div className="cart-product__price">
        <span className="font16">${data.price}</span>
      </div>
    </div>
    <Collapse ghost>
      <Panel header={<span className="font12_6">Drop Details</span>} key="1">
        <div className="cart-product__details">
          <div className="cart-product__row">
            <div className="font14_w4">@{data.creatorHandle}</div>
            <div className="font14_w4">ID: {data.dropId}</div>
          </div>
          <div className="cart-product__row">
            <div className="cart-product__color">
              <div className="font12_6">Color:</div>
              <div
                className="order_color"
                style={{ backgroundColor: `#${data.color ? data.color.hex : ""}` }}
              />
            </div>
            <div className="font14_w4">Size:&nbsp;{data.size}</div>
          </div>
        </div>
      </Panel>
    </Collapse>
  </>;
}
const mapStateToProps = (state) => {
  return {
    theme: state.theme,
  };
};
export default connect(mapStateToProps)(OrderedProduct);
