import { ComposableMap, ZoomableGlobe, Geographies, Geography } from "react-simple-maps";
import { Motion, spring } from "react-motion";

const GlobeMap = ({ center }) => {

    return (
        <div className="globe_map_container">
            <Motion
                defaultStyle={{
                    x: center[0],
                    y: center[1]
                }}
                style={{
                    x: spring(center[0]),
                    y: spring(center[1])
                }}
            >
                {({ x, y }) => (
                    <ComposableMap
                        width={500}
                        height={500}
                        projection="orthographic"
                        projectionConfig={{ scale: 250 }}
                        style={{ backcolor: 'red' }}
                    >
                        <ZoomableGlobe center={[x, y]}>

                            <linearGradient id="grad1" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                                <stop offset="0%" style={{ stopColor: '#A49C95', stopOpacity: '0.6' }} />
                                <stop offset="100%" style={{ stopColor: '#59767C', stopOpacity: '0.438' }} />
                            </linearGradient>
                            <linearGradient id="Gradient2" x1="0" x2="0" y1="0" y2="1">
                                <stop offset="0%" stopColor="red" />
                                <stop offset="50%" stopColor="black" stopOpacity="0" />
                                <stop offset="100%" stopColor="blue" />
                            </linearGradient>
                            <circle
                                cx={250}
                                cy={250}
                                r={250}
                                fill="url(#grad1)"
                                stroke="#C4B181"
                            />
                            <Geographies
                                disableOptimization
                                geography="https://unpkg.com/world-atlas@1.1.4/world/110m.json"
                            >
                                {(geos, proj) =>
                                    geos.map((geo, i) => (
                                        <Geography
                                            key={geo.id + i}
                                            geography={geo}
                                            projection={proj}
                                            style={{
                                                default: { fill: "#35373F" }
                                            }}
                                        />
                                    ))
                                }
                            </Geographies>
                        </ZoomableGlobe>
                    </ComposableMap>
                )}
            </Motion>
        </div>
    )
}

export default GlobeMap;
